import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 16,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 16 16"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 1.77778C0 1.34822 0.346616 1 0.774188 1H3.40173C3.82931 1 4.17592 1.34822 4.17592 1.77778C4.17592 2.20733 3.82931 2.55556 3.40173 2.55556H1.54838V4.26667C1.54838 4.69622 1.20176 5.04444 0.774188 5.04444C0.346616 5.04444 0 4.69622 0 4.26667V1.77778Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.8242 1.77778C11.8242 1.34822 12.1708 1 12.5984 1H15.226C15.6535 1 16.0001 1.34822 16.0001 1.77778V4.26667C16.0001 4.69622 15.6535 5.04444 15.226 5.04444C14.7984 5.04444 14.4518 4.69622 14.4518 4.26667V2.55556H12.5984C12.1708 2.55556 11.8242 2.20733 11.8242 1.77778Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.91211 1.77778C5.91211 1.34822 6.25872 1 6.6863 1H9.31384C9.74141 1 10.088 1.34822 10.088 1.77778C10.088 2.20733 9.74141 2.55556 9.31384 2.55556H6.6863C6.25872 2.55556 5.91211 2.20733 5.91211 1.77778Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.774188 10.9551C1.20176 10.9551 1.54838 11.3033 1.54838 11.7329V13.444H3.40173C3.82931 13.444 4.17592 13.7922 4.17592 14.2217C4.17592 14.6513 3.82931 14.9995 3.40173 14.9995H0.774188C0.346616 14.9995 0 14.6513 0 14.2217V11.7329C0 11.3033 0.346616 10.9551 0.774188 10.9551Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.226 10.9551C15.6535 10.9551 16.0001 11.3033 16.0001 11.7329V14.2217C16.0001 14.6513 15.6535 14.9995 15.226 14.9995H12.5984C12.1708 14.9995 11.8242 14.6513 11.8242 14.2217C11.8242 13.7922 12.1708 13.444 12.5984 13.444H14.4518V11.7329C14.4518 11.3033 14.7984 10.9551 15.226 10.9551Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.91211 14.2231C5.91211 13.7935 6.25872 13.4453 6.6863 13.4453H9.31384C9.74141 13.4453 10.088 13.7935 10.088 14.2231C10.088 14.6526 9.74141 15.0009 9.31384 15.0009H6.6863C6.25872 15.0009 5.91211 14.6526 5.91211 14.2231Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.774188 6.59961C1.20176 6.59961 1.54838 6.94783 1.54838 7.37739V8.62183C1.54838 9.05139 1.20176 9.39961 0.774188 9.39961C0.346616 9.39961 0 9.05139 0 8.62183V7.37739C0 6.94783 0.346616 6.59961 0.774188 6.59961Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.2254 6.59961C15.6529 6.59961 15.9995 6.94783 15.9995 7.37739V8.62183C15.9995 9.05139 15.6529 9.39961 15.2254 9.39961C14.7978 9.39961 14.4512 9.05139 14.4512 8.62183V7.37739C14.4512 6.94783 14.7978 6.59961 15.2254 6.59961Z"
        fill={color}
      />
    </svg>
  );
}

import { useContext, useMemo } from 'react';
import { MarkerType, useReactFlow } from '@xyflow/react';
import { upperFirst } from 'lodash';
import clsx from 'clsx';

import { Icon16 } from '@spaceduck/icons';

import { BoardStoreContext } from '../context/boardContext';
import { useBoardStore } from '../hooks/useBoardStore';
import { edgeWidthAsNumber, type EdgeWidth } from '../types/board';
import {
  type ConnectionLineColor,
  connectionLineColors,
  connectionLineColorsAsHex,
} from '../types/colors';
import HorizontalMenu, { type MenuItemProps } from './HorizontalMenu';
import Swatch from './Swatch';
import styles from './EdgeStyleMenu.module.scss';
import { useShallow } from 'zustand/shallow';

const {
  BorderStyle,
  ConnectorSimple,
  ConnectorArrowLeft,
  ConnectorArrowRight,
  ConnectorCircleLeft,
  ConnectorCircleRight,
  ConnectorCurved,
  ConnectorDiamondLeft,
  ConnectorDiamondRight,
  ConnectorElbowed,
  ConnectorStraight,
  DashedLine,
  SolidLine,
  TrashDelete,
} = Icon16;

export const EdgeStyleMenu = ({
  selectedEdges,
}: {
  selectedEdges: string[];
}) => {
  const boardContext = useContext(BoardStoreContext);
  const { getEdges, setEdges } = useReactFlow();

  const {
    defaultConnectionLineType,
    setDefaultConnectionLineType,
    setDefaultConnectionLineStyle,
    defaultConnectionLineColor,
    setDefaultConnectionLineColor,
    setDefaultConnectionLineWidth,
    defaultMarkerStartType,
    setDefaultMarkerStartType,
    defaultMarkerEndType,
    setDefaultMarkerEndType,
    setSelectedEdges,
  } = useBoardStore(
    useShallow((state) => ({
      defaultConnectionLineType: state.defaultConnectionLineType,
      setDefaultConnectionLineType: state.setDefaultConnectionLineType,
      setDefaultConnectionLineStyle: state.setDefaultConnectionLineStyle,
      defaultConnectionLineColor: state.defaultConnectionLineColor,
      setDefaultConnectionLineColor: state.setDefaultConnectionLineColor,
      setDefaultConnectionLineWidth: state.setDefaultConnectionLineWidth,
      defaultMarkerStartType: state.defaultMarkerStartType,
      setDefaultMarkerStartType: state.setDefaultMarkerStartType,
      defaultMarkerEndType: state.defaultMarkerEndType,
      setDefaultMarkerEndType: state.setDefaultMarkerEndType,
      setSelectedEdges: state.setSelectedEdges,
    }))
  );

  const setEdgeMarker = (
    side: 'start' | 'end' | null,
    style?: 'arrow' | 'circle' | 'diamond'
  ) => {
    if (side === 'end') {
      const svgId =
        style === 'circle'
          ? 'markerTypeCircle'
          : style === 'diamond'
            ? 'markerTypeDiamond'
            : undefined;

      const getMarkerEnd = (color: ConnectionLineColor, width: EdgeWidth) =>
        style === 'arrow'
          ? {
              type: MarkerType.Arrow,
              color: color ? connectionLineColorsAsHex[color] : undefined,
              strokeWidth: width ? edgeWidthAsNumber[width] : undefined,
            }
          : svgId
            ? `${svgId}${upperFirst(color)}`
            : undefined;

      setEdges((edges) => {
        return edges.map((edge) => {
          if (selectedEdges.includes(edge.id)) {
            return {
              ...edge,
              markerStart: undefined,
              markerEnd: getMarkerEnd(
                edge.data?.color as ConnectionLineColor,
                edge.data?.width as EdgeWidth
              ),
            };
          }

          return edge;
        });
      });

      setDefaultMarkerStartType(null);
      setDefaultMarkerEndType(style ?? null);
      return;
    }

    if (side === 'start') {
      const svgId =
        style === 'circle'
          ? 'markerTypeCircle'
          : style === 'diamond'
            ? 'markerTypeDiamond'
            : undefined;

      const getMarkerStart = (color: ConnectionLineColor, width: EdgeWidth) =>
        style === 'arrow'
          ? {
              type: MarkerType.Arrow,
              color: color ? connectionLineColorsAsHex[color] : undefined,
              strokeWidth: width ? edgeWidthAsNumber[width] : undefined,
            }
          : svgId
            ? `${svgId}${upperFirst(color)}`
            : undefined;

      setEdges((edges) => {
        return edges.map((edge) => {
          if (selectedEdges.includes(edge.id)) {
            return {
              ...edge,
              markerStart: getMarkerStart(
                edge.data?.color as ConnectionLineColor,
                edge.data?.width as EdgeWidth
              ),
              markerEnd: undefined,
            };
          }

          return edge;
        });
      });

      setDefaultMarkerStartType(style ?? null);
      setDefaultMarkerEndType(null);
      return;
    }

    setEdges((edges) =>
      edges.map((edge) => {
        if (selectedEdges.includes(edge.id)) {
          return { ...edge, markerStart: undefined, markerEnd: undefined };
        }
        return edge;
      })
    );

    setDefaultMarkerStartType(null);
    setDefaultMarkerEndType(null);
  };

  const edge =
    selectedEdges.length === 1
      ? (getEdges().find((edge) => edge.id === selectedEdges[0]) ?? null)
      : null;

  const strokeColor = edge?.data?.color
    ? (edge.data.color as ConnectionLineColor)
    : defaultConnectionLineColor;

  const menu: MenuItemProps[] = useMemo(
    () => [
      {
        title: 'Connection type',
        buttonIcon:
          edge?.data?.type === 'straight' ? (
            <ConnectorStraight size={16} />
          ) : edge?.data?.type === 'step' ? (
            <ConnectorElbowed size={16} />
          ) : (
            <ConnectorCurved size={16} />
          ),
        subMenu: [
          {
            title: 'Connection type straight',
            buttonIcon: <ConnectorStraight size={16} />,
            isActive: (edge?.data?.type ?? defaultConnectionLineType) === 'straight',
            onClick: () => {
              setEdges((edges) =>
                edges.map((edge) => {
                  if (selectedEdges.includes(edge.id)) {
                    return { ...edge, data: { ...edge.data, type: 'straight' } };
                  }

                  return edge;
                })
              );

              setDefaultConnectionLineType('straight');
            },
          },
          {
            title: 'Connection type curved',
            buttonIcon: <ConnectorCurved size={16} />,
            isActive: (edge?.data?.type ?? defaultConnectionLineType) === 'default',
            onClick: () => {
              setEdges((edges) =>
                edges.map((edge) => {
                  if (selectedEdges.includes(edge.id)) {
                    return { ...edge, data: { ...edge.data, type: 'default' } };
                  }

                  return edge;
                })
              );

              setDefaultConnectionLineType('default');
            },
          },
          {
            title: 'Connection type elbowed',
            buttonIcon: <ConnectorElbowed size={16} />,
            isActive: (edge?.data?.type ?? defaultConnectionLineType) === 'step',
            onClick: () => {
              setEdges((edges) =>
                edges.map((edge) => {
                  if (selectedEdges.includes(edge.id)) {
                    return { ...edge, data: { ...edge.data, type: 'step' } };
                  }

                  return edge;
                })
              );

              setDefaultConnectionLineType('step');
            },
          },
        ],
      },
      {
        title: 'Edge marker style',
        buttonIcon: <ConnectorSimple />,
        subMenu: [
          {
            title: 'Simple edge marker',
            buttonIcon: <ConnectorSimple />,
            isActive: edge
              ? !(edge.markerStart || edge.markerEnd)
              : !(defaultMarkerStartType || defaultMarkerEndType),
            onClick: () => setEdgeMarker(null),
          },
          {
            title: 'Arrow start edge marker',
            buttonIcon: <ConnectorArrowRight />,
            isActive: edge
              ? !!edge.markerStart && typeof edge.markerStart === 'object'
              : !!defaultMarkerStartType && typeof defaultMarkerStartType === 'object',
            onClick: () => setEdgeMarker('start', 'arrow'),
          },
          {
            title: 'Circle start edge marker',
            buttonIcon: <ConnectorCircleRight />,
            isActive: edge
              ? typeof edge.markerStart === 'string' && /circle/.test(edge.markerStart)
              : typeof defaultMarkerStartType === 'string' &&
                /circle/i.test(defaultMarkerStartType),
            onClick: () => setEdgeMarker('start', 'circle'),
          },
          {
            title: 'Diamond start edge marker',
            buttonIcon: <ConnectorDiamondRight />,
            isActive: edge
              ? typeof edge.markerStart === 'string' && /diamond/.test(edge.markerStart)
              : typeof defaultMarkerStartType === 'string' &&
                /diamond/i.test(defaultMarkerStartType),
            onClick: () => setEdgeMarker('start', 'diamond'),
          },
          {
            title: 'Arrow end edge marker',
            buttonIcon: <ConnectorArrowLeft />,
            isActive: edge
              ? !!edge.markerEnd && typeof edge.markerEnd === 'object'
              : !!defaultMarkerEndType && typeof defaultMarkerEndType === 'object',
            onClick: () => setEdgeMarker('end', 'arrow'),
          },
          {
            title: 'Circle end edge marker',
            buttonIcon: <ConnectorCircleLeft />,
            isActive: edge
              ? typeof edge.markerEnd === 'string' && /circle/.test(edge.markerEnd)
              : typeof defaultMarkerEndType === 'string' &&
                /circle/i.test(defaultMarkerEndType),
            onClick: () => setEdgeMarker('end', 'circle'),
          },
          {
            title: 'Diamond end edge marker',
            buttonIcon: <ConnectorDiamondLeft />,
            isActive: edge
              ? typeof edge.markerEnd === 'string' && /diamond/.test(edge.markerEnd)
              : typeof defaultMarkerEndType === 'string' &&
                /diamond/i.test(defaultMarkerEndType),
            onClick: () => setEdgeMarker('end', 'diamond'),
          },
        ],
        showSeparatorAfter: true,
      },
      {
        title: 'Connection color',
        buttonIcon: <Swatch color={strokeColor} />,
        className: styles.colors,
        subMenu: connectionLineColors.map((color) => {
          return {
            title: upperFirst(color),
            buttonIcon: <Swatch color={color} />,
            isActive: strokeColor === color,
            onClick: () => {
              setEdges((edges) =>
                edges.map((edge) => {
                  if (!selectedEdges.includes(edge.id)) return edge;

                  const newEdge = { ...edge, data: { ...edge.data, color } };

                  if (typeof newEdge.markerStart === 'object') {
                    newEdge.markerStart = {
                      ...newEdge.markerStart,
                      color: connectionLineColorsAsHex[color],
                    };
                  } else if (newEdge.markerStart) {
                    if (/markerTypeCircle/.test(newEdge.markerStart)) {
                      newEdge.markerStart = `markerTypeCircle${upperFirst(color)}`;
                    } else if (/markerTypeDiamond/.test(newEdge.markerStart)) {
                      newEdge.markerStart = `markerTypeDiamond${upperFirst(color)}`;
                    }
                  }

                  if (typeof newEdge.markerEnd === 'object') {
                    newEdge.markerEnd = {
                      ...newEdge.markerEnd,
                      color: connectionLineColorsAsHex[color],
                    };
                  } else if (newEdge.markerEnd) {
                    if (/markerTypeCircle/.test(newEdge.markerEnd)) {
                      newEdge.markerEnd = `markerTypeCircle${upperFirst(color)}`;
                    } else if (/markerTypeDiamond/.test(newEdge.markerEnd)) {
                      newEdge.markerEnd = `markerTypeDiamond${upperFirst(color)}`;
                    }
                  }

                  setDefaultConnectionLineColor(color);
                  return newEdge;
                })
              );
            },
          };
        }),
      },
      {
        title: 'Connection style',
        buttonIcon: <BorderStyle />,
        subMenu: [
          {
            title: 'Solid',
            buttonIcon: <SolidLine />,
            isActive: edge?.data?.style === 'solid',
            subMenu: [
              {
                title: 'Solid small',
                buttonIcon: <SolidLine className={styles.small} />,
                isActive: edge?.data?.style === 'solid' && edge.data?.width === 'small',
                onClick: () => {
                  setEdges((edges) =>
                    edges.map((edge) => {
                      if (selectedEdges.includes(edge.id)) {
                        return {
                          ...edge,
                          data: { ...edge.data, style: 'solid', width: 'small' },
                        };
                      }

                      setDefaultConnectionLineStyle('solid');
                      setDefaultConnectionLineWidth('small');
                      return edge;
                    })
                  );
                },
              },
              {
                title: 'Solid medium',
                buttonIcon: <SolidLine className={styles.medium} />,
                isActive:
                  edge?.data?.style === 'solid' && edge.data?.width === 'medium',
                onClick: () => {
                  setEdges((edges) =>
                    edges.map((edge) => {
                      if (selectedEdges.includes(edge.id)) {
                        return {
                          ...edge,
                          data: { ...edge.data, style: 'solid', width: 'medium' },
                        };
                      }

                      setDefaultConnectionLineStyle('solid');
                      setDefaultConnectionLineWidth('medium');
                      return edge;
                    })
                  );
                },
              },
              {
                title: 'Solid large',
                buttonIcon: <SolidLine className={styles.large} />,
                isActive: edge?.data?.style === 'solid' && edge.data?.width === 'large',
                onClick: () => {
                  setEdges((edges) =>
                    edges.map((edge) => {
                      if (selectedEdges.includes(edge.id)) {
                        return {
                          ...edge,
                          data: { ...edge.data, style: 'solid', width: 'large' },
                        };
                      }

                      setDefaultConnectionLineStyle('solid');
                      setDefaultConnectionLineWidth('large');
                      return edge;
                    })
                  );
                },
              },
            ],
          },
          {
            title: 'Dashed',
            buttonIcon: <DashedLine />,
            isActive: edge?.data?.style === 'dashed',
            subMenu: [
              {
                title: 'Dashed small',
                buttonIcon: <DashedLine className={styles.small} />,
                isActive:
                  edge?.data?.style === 'dashed' && edge?.data?.width === 'small',
                onClick: () => {
                  setEdges((edges) =>
                    edges.map((edge) => {
                      if (selectedEdges.includes(edge.id)) {
                        return {
                          ...edge,
                          data: { ...edge.data, style: 'dashed', width: 'small' },
                        };
                      }

                      setDefaultConnectionLineStyle('dashed');
                      setDefaultConnectionLineWidth('small');
                      return edge;
                    })
                  );
                },
              },
              {
                title: 'Dashed medium',
                buttonIcon: <DashedLine className={styles.medium} />,
                isActive:
                  edge?.data?.style === 'dashed' && edge.data?.width === 'medium',
                onClick: () => {
                  setEdges((edges) =>
                    edges.map((edge) => {
                      if (selectedEdges.includes(edge.id)) {
                        return {
                          ...edge,
                          data: { ...edge.data, style: 'dashed', width: 'medium' },
                        };
                      }

                      setDefaultConnectionLineStyle('dashed');
                      setDefaultConnectionLineWidth('medium');
                      return edge;
                    })
                  );
                },
              },
              {
                title: 'Dashed large',
                buttonIcon: <DashedLine className={styles.large} />,
                isActive:
                  edge?.data?.style === 'dashed' && edge.data?.width === 'large',
                onClick: () => {
                  setEdges((edges) =>
                    edges.map((edge) => {
                      if (selectedEdges.includes(edge.id)) {
                        return {
                          ...edge,
                          data: { ...edge.data, style: 'dashed', width: 'large' },
                        };
                      }

                      setDefaultConnectionLineStyle('dashed');
                      setDefaultConnectionLineWidth('large');
                      return edge;
                    })
                  );
                },
              },
            ],
          },
        ],
        showSeparatorAfter: true,
      },
      {
        title: 'Delete',
        buttonIcon: <TrashDelete className={styles.small} />,
        onClick: () => {
          setEdges((edges) => edges.filter((edge) => !selectedEdges.includes(edge.id)));
          setSelectedEdges([]);
        },
      },
    ],
    [
      edge,
      strokeColor,
      setEdges,
      selectedEdges,
      setSelectedEdges,
      setDefaultConnectionLineType,
      defaultMarkerStartType,
      defaultMarkerEndType,
      setEdgeMarker,
      setDefaultConnectionLineColor,
      setDefaultConnectionLineStyle,
      setDefaultConnectionLineWidth,
    ]
  );

  if (!boardContext?.floatingUIEdgeMenu || !selectedEdges.length) {
    boardContext?.floatingUIEdgeMenu.update();
    return null;
  }

  const { floatingStyles, refs } = boardContext.floatingUIEdgeMenu;

  // translate(0px, 0px) is fallback
  const positioned =
    floatingStyles.transform && floatingStyles.transform !== 'translate(0px, 0px)';

  return (
    <div
      className={clsx(styles.menu, positioned && styles.visible)}
      ref={refs.setFloating}
      style={floatingStyles}
    >
      <HorizontalMenu menu={menu} />
    </div>
  );
};

import type { MediaGroupCategory } from '@spaceduck/api';
import { Icon16, Icon24, Icon64 } from '@spaceduck/icons';
import { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useParams } from 'react-router';

import { urlFor } from '@/urls';
import {
  useCreateMediaGroupCategory,
  useDeleteMediaGroupCategory,
  usePatchMediaGroupCategory,
} from '@api/mediaGroupCategory';
import NoEntries from '@components/NoEntries';
import { useModalManager } from '@context/ModalManagerContext';
import { useDebouncedSearch } from '@hooks/useDebouncedSearch';
import { EditMenu, Search, SubNav } from '@pages/common';
import Button from '@ui/Button';
import { useConfirmModal } from '@ui/ConfirmModal';
import Dialog from '@ui/Dialog';
import DropdownMenu, { MenuItem } from '@ui/DropdownMenu';
import createToast from '@utils/createToast';
import styles from './Categories.module.scss';
import ProjectTabs from './common/ProjectTabs';
import Head from '@/components/Head';
import PageHeader from './common/PageHeader';
import { useListMediaGroupCategories, useProject } from '@/api/project';
import ProjectMenu from './common/ProjectMenu';
import TwoColumnContent from './common/TwoColumnContent';
import { stringContains } from '@/utils/string';

const { Sort } = Icon16;
const { Add, Down, ProjectCategory } = Icon24;
const { ProjectCategory: ProjectCategory64 } = Icon64;

const sortByOptions = ['Newest', 'Oldest', 'Name'] as const;
type SortByOption = (typeof sortByOptions)[number];

function alphabeticalSort(a: MediaGroupCategory, b: MediaGroupCategory) {
  if (a.label > b.label) return 1;
  if (a.label < b.label) return -1;
  return 0;
}

function oldestFirstSort(a: MediaGroupCategory, b: MediaGroupCategory) {
  return a.lastUpdated > b.lastUpdated ? 1 : -1;
}

function newestFirstSort(a: MediaGroupCategory, b: MediaGroupCategory) {
  return a.lastUpdated <= b.lastUpdated ? 1 : -1;
}

function getSortFunction(sortBy: SortByOption) {
  switch (sortBy) {
    case 'Oldest':
      return oldestFirstSort;
    case 'Name':
      return alphabeticalSort;
    default:
      return newestFirstSort;
  }
}

export default function CategoriesPage() {
  const [searchQuery, setSearchQuery] = useState('');
  const { debouncedSetSearchQuery } = useDebouncedSearch(setSearchQuery);
  const [sortBy, setSortBy] = useState<SortByOption>('Newest');
  const projectId = useParams<{ projectId?: string }>().projectId;
  const { data: projectData } = useProject(projectId || null);
  const { data: categoriesData } = useListMediaGroupCategories(projectId || null);
  const categories = categoriesData?.pages.flatMap((page) => page.categories);

  const sortedCategories = useMemo(() => {
    if (!categories) return [];
    const sortFn = getSortFunction(sortBy);

    if (searchQuery) {
      const filteredCategories = [...categories].filter((category) =>
        stringContains(category.label, searchQuery, { ignoreCase: true })
      );
      return filteredCategories.sort(sortFn);
    }

    return [...categories].sort(sortFn);
  }, [categories, searchQuery, sortBy]);

  const { open: openCreateModal } = useCreateCategoryModal();

  if (!projectData) {
    return;
  }

  return (
    <>
      <Head title={`${projectData.project?.label || 'Space'} Categories`} />
      <PageHeader />
      <ProjectTabs
        cta={
          <Button
            onClick={() => openCreateModal(projectData.project.id)}
            size="sm"
            variant="outlined"
            iconBefore={<Add size={16} />}
          >
            New Category
          </Button>
        }
      />
      <TwoColumnContent
        contentToolbar={null}
        sidebar={<ProjectMenu project={projectData.project} />}
      >
        {!categories || categories.length === 0 ? (
          <NoCategories onClick={() => openCreateModal(projectData.project.id)} />
        ) : (
          <>
            <SubNav>
              <div className={styles.utilities}>
                <DropdownMenu
                  className={styles.sortDropdown}
                  isPadded
                  triggerContent={
                    <Button
                      className={styles.sortButton}
                      iconAfter={<Down size={16} />}
                      iconBefore={<Sort size={15} />}
                      variant="outlined"
                    >
                      Sort by: {sortBy}
                    </Button>
                  }
                >
                  {sortByOptions.map((sortByOption, idx) => (
                    <MenuItem
                      key={idx}
                      onSelect={() => {
                        if (sortBy !== sortByOption) {
                          setSortBy(sortByOption);
                        }
                      }}
                    >
                      {sortByOption}
                    </MenuItem>
                  ))}
                </DropdownMenu>
                {/* TODO: Hook search up with API */}
                <Search
                  className={styles.searchBox}
                  collapsible
                  defaultExpanded={false}
                  defaultValue={searchQuery}
                  onInput={(ev) => debouncedSetSearchQuery(ev.currentTarget.value)}
                  placeholder="Find a category..."
                  size="sm"
                  status="success"
                />
              </div>
            </SubNav>
            <CategoriesTable categories={sortedCategories} />
          </>
        )}
      </TwoColumnContent>
    </>
  );
}

const CategoriesTable = ({
  categories,
}: {
  categories?: MediaGroupCategory[];
}) => {
  if (!categories || categories.length === 0) return null;

  return (
    <div className={styles.tableWrapper}>
      <table className={styles.table}>
        <thead>
          <tr>
            <th>Category Name</th>
            <th>Number of items</th>
            <th>Number of properties</th>
            <th title="Actions" />
          </tr>
        </thead>
        <tbody>
          {categories.map((category) => (
            <CategoriesTableRow category={category} key={category.id} />
          ))}
        </tbody>
      </table>
    </div>
  );
};

const CategoriesTableRow = ({ category }: { category: MediaGroupCategory }) => {
  const { open: openEditModal } = useEditCategoryModal();
  const { mutateAsync: deleteMediaGroupCategory } = useDeleteMediaGroupCategory();

  const { open: openDeleteModal } = useConfirmModal<{ id: string }>({
    title: 'Delete category',
    subtitle:
      'This action cannot be undone. Deleting this category and its properties will permanently remove them from all associated content.',
    confirmVariant: 'danger',
    confirmText: 'Yes, delete category',
    onConfirm: async (vars) => {
      if (!vars) return;

      const { id } = vars;

      const response = await deleteMediaGroupCategory(id);
      createToast({
        titleText: 'Category deleted successfully',
        bodyText: `Category name "${response.category.label}" was deleted.`,
        iconVariant: 'success',
      });
    },
  });

  return (
    <tr>
      <td>
        <Link
          className={styles.tableLabel}
          to={urlFor('workspaceCategory', {
            categoryId: category.id,
          })}
        >
          <ProjectCategory size={20} />
          {category.label}
        </Link>
      </td>
      <td>{category.mediaGroupCount || '-'}</td>
      <td>{category.propertiesCount || '-'}</td>
      <td>
        <EditMenu>
          <MenuItem
            disabled={
              !category.capabilities?.find(
                ({ capability, capable }) => capability === 'edit' && capable
              )
            }
            onSelect={() => openEditModal(category)}
          >
            Edit
          </MenuItem>
          <MenuItem
            disabled={
              !category.capabilities?.find(
                ({ capability, capable }) => capability === 'delete' && capable
              )
            }
            onSelect={() => openDeleteModal({ id: category.id })}
          >
            Delete
          </MenuItem>
        </EditMenu>
      </td>
    </tr>
  );
};

const NoCategories = ({ onClick }: { onClick?: () => void }) => {
  return (
    <NoEntries className={styles.noEntries} icon={<ProjectCategory64 />}>
      <h1>Categories</h1>
      <p>
        Categories enable you to define items by assigning custom properties and
        grouping similar ones together.
      </p>
      {!!onClick && (
        <Button iconBefore={<Add />} onClick={onClick} size="sm" variant="primary">
          New category
        </Button>
      )}
    </NoEntries>
  );
};

type FormData = {
  label: string;
};

const CreateCategoryModal = ({
  closeModal,
  projectId,
}: { closeModal: () => void; projectId: string }) => {
  const { mutateAsync: createCategory } = useCreateMediaGroupCategory();

  const onSubmit = async (data: FormData) => {
    if (!projectId) {
      console.error('Failed to create media group category due to missing projectId');
      createToast({
        titleText: 'Error creating category',
        bodyText: 'Please try again later.',
        iconVariant: 'warning',
      });
      return;
    }

    if (!data.label) {
      createToast({
        titleText: 'Error creating category',
        bodyText: 'A category name is required.',
        iconVariant: 'warning',
      });
      return;
    }

    // TODO: backend fix 'MediaGroupCategory' object has no attribute 'fields_count'
    const { created, category } = await createCategory({
      projectId: projectId,
      label: data.label,
    });

    closeModal();

    if (created) {
      createToast({
        titleText: 'Category added successfully',
        bodyText: `Added "${category.label}" as a category.`,
        iconVariant: 'success',
      });
    } else {
      createToast({
        titleText: 'Category already exists',
        bodyText: `The category name "${category.label}" already exists and was not added; categories must be unique.`,
        iconVariant: 'warning',
      });
    }
  };

  return (
    <CreateOrEditForm
      closeModal={closeModal}
      heading="New category"
      onSubmit={onSubmit}
      saveText="Create"
    />
  );
};

const EditCategoryModel = ({
  category,
  closeModal,
}: {
  category: MediaGroupCategory;
  closeModal: () => void;
}) => {
  const { mutateAsync: patchMediaGroupCategory } = usePatchMediaGroupCategory();

  const onSubmit = async (data: FormData) => {
    const response = await patchMediaGroupCategory({
      id: category.id,
      patch: data,
    });

    closeModal();
    createToast({
      titleText: 'Category edited successfully',
      bodyText: `Category name was changed to "${response.category.label}".`,
      iconVariant: 'success',
    });
  };

  return (
    <CreateOrEditForm
      closeModal={closeModal}
      existingCategory={category}
      onSubmit={onSubmit}
    />
  );
};

const CreateOrEditForm = ({
  closeModal,
  existingCategory,
  heading = 'Edit category',
  onSubmit,
  saveText = 'Save',
}: {
  closeModal: () => void;
  existingCategory?: MediaGroupCategory;
  heading?: string;
  onSubmit: (data: FormData) => void;
  saveText?: string;
}) => {
  const {
    formState: { errors },
    handleSubmit,
    register,
    reset,
    setFocus,
  } = useForm<FormData>();

  const onOpenAutoFocus = (ev: Event) => {
    ev.preventDefault();
    setFocus('label');
  };

  return (
    <Dialog
      className={styles.categoryModal}
      closeModal={closeModal}
      headerPadding={0}
      isOpen={true}
      maxWidth="32.5rem"
      modalHeading={heading}
      onOpenAutoFocus={onOpenAutoFocus}
      padding="lg"
    >
      <form
        className={styles.categoryForm}
        onSubmit={handleSubmit(async (data) => {
          await onSubmit(data);
          reset();
        })}
      >
        <div className="formGroup">
          <label htmlFor="categoryName">Category name</label>
          <input
            defaultValue={existingCategory?.label}
            id="categoryName"
            placeholder="e.g. Meetings"
            type="text"
            {...register('label', {
              required: 'Category name is required',
              setValueAs: (value: string) => value.trim(),
            })}
          />
        </div>
        {errors?.label?.message && (
          <p className="errorMessage fieldError">{errors.label.message}</p>
        )}
        <div className={styles.categoryFormFooter}>
          <Button size="sm" type="button" variant="secondary" onClick={closeModal}>
            Cancel
          </Button>
          <Button size="sm" type="submit" variant="primary">
            {saveText}
          </Button>
        </div>
      </form>
    </Dialog>
  );
};

export function useCreateCategoryModal() {
  const { openModal, closeModal } = useModalManager();
  return {
    open: (projectId: string) => {
      openModal({
        component: (
          <CreateCategoryModal closeModal={closeModal} projectId={projectId} />
        ),
      });
    },
    close: closeModal,
  };
}

export function useEditCategoryModal() {
  const { openModal, closeModal } = useModalManager();
  return {
    open: (category: MediaGroupCategory) => {
      openModal({
        component: <EditCategoryModel category={category} closeModal={closeModal} />,
      });
    },
    close: closeModal,
  };
}

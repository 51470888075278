import { Icon24, Icon32 } from '@spaceduck/icons';
import clsx from 'clsx';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';

import LoadingPlaceholder from '@components/LoadingPlaceholder';
import { PdfDocument, PdfPage } from '@components/PdfDocument';
import Spinner from '@components/Spinner';
import { css } from '@lib/css';
import Button from '@ui/Button';
import ScrollArea from '@ui/ScrollArea';
import Select from '@ui/Select';
import styles from './PdfView.module.scss';
import { DetailsModalTooltip } from '../comments/DetailsModalTooltip';

const { Add, Down, Download, DrawerLeft, Minus, Up } = Icon24;
const { PdfFile } = Icon32;

const scaleValuesAsPercentage = [50, 75, 100, 125, 150, 200, 300, 400];

export default function PdfView({ name, url }: { name: string; url: string }) {
  const [numPages, setNumPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [showThumbnails, setShowThumbnails] = useState(true);
  const handleLoadSuccess = useCallback(({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
  }, []);
  const [placeholderHeight, setPlaceholderHeight] = useState<number | undefined>();
  const handlePageLoadSuccess = useCallback(({ height }: { height: number }) => {
    setPlaceholderHeight((current) =>
      current === undefined ? height : Math.max(current, height)
    );
  }, []);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const observePage = (entries: IntersectionObserverEntry[]) => {
    const sortedEntries = entries
      .filter((entry) => entry.isIntersecting)
      .sort((a, b) => b.intersectionRatio - a.intersectionRatio);
    if (sortedEntries.length) {
      const visibleEntry = sortedEntries[0];
      if (visibleEntry) {
        const index = visibleEntry.target.getAttribute('data-index');
        if (index) {
          setCurrentPage(Number(index));
        }
      }
    }
  };
  const watcher = useRef<IntersectionObserver | null>(null);
  const [scale, setScale] = useState<(typeof scaleValuesAsPercentage)[number]>(100);
  const scrollTo = (pageIndex: number) => {
    if (containerRef.current) {
      const page = containerRef.current.querySelector(`[data-index='${pageIndex}']`);
      page?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  const toggleThumbnails = () => {
    setShowThumbnails((state) => !state);
  };

  const getClosestScaleIndex = () => {
    const closestScale = scaleValuesAsPercentage.reduce((prev, curr) => {
      if (scale >= prev && scale < curr) {
        return prev;
      }

      return curr;
    }, 10);

    return scaleValuesAsPercentage.indexOf(closestScale);
  };

  const zoomOut = () => {
    const indexOfClosestScale = getClosestScaleIndex();
    if (indexOfClosestScale > 0) {
      const prevZoom = scaleValuesAsPercentage[indexOfClosestScale - 1];
      if (prevZoom) {
        setScale(prevZoom);
      }
    }
  };

  const zoomIn = () => {
    const indexOfClosestScale = getClosestScaleIndex();
    if (indexOfClosestScale < scaleValuesAsPercentage.length - 1) {
      const nextZoom = scaleValuesAsPercentage[indexOfClosestScale + 1];
      if (nextZoom) {
        setScale(nextZoom);
      }
    }
  };

  useHotkeys('meta + =', (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    zoomIn();
  });

  useHotkeys('meta + -', (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    zoomOut();
  });

  useHotkeys('meta + 0', (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    setScale(100);
  });

  useEffect(() => {
    if (containerRef.current && numPages > 0) {
      watcher.current = new IntersectionObserver(observePage, {
        root: containerRef.current,
        rootMargin: '-49.5% 0px -50% 0px',
        threshold: 0,
      });
      const pages = Array.from(containerRef.current?.querySelectorAll('[data-index]'));
      pages.forEach((page) => watcher.current?.observe(page));
    }
  }, [containerRef.current, numPages, scale]);

  useEffect(() => {
    return () => {
      watcher.current?.disconnect();
    };
  }, []);

  return (
    <>
      {numPages > 0 && (
        <Toolbar
          currentPage={currentPage}
          numPages={numPages}
          scale={scale}
          scrollTo={scrollTo}
          setScale={setScale}
          showThumbnails={showThumbnails}
          toggleThumbnails={toggleThumbnails}
          zoomIn={zoomIn}
          zoomOut={zoomOut}
        />
      )}
      <div
        className={clsx(
          styles.pdfPreview,
          numPages > 0 && showThumbnails && styles.withThumbnails,
          numPages === 0 && styles.noToolbar
        )}
      >
        {numPages > 0 && (
          <Pagination
            handleLoadSuccess={() => {}}
            numPages={numPages}
            scrollTo={scrollTo}
            url={url}
          />
        )}
        <div className={styles.scaleContainer} ref={containerRef}>
          <ScrollArea
            className={clsx(styles.scrollAreaMain, numPages === 0 && styles.noScroll)}
            style={css({
              '--width': '100%',
              '--maxHeight': '100%',
            })}
          >
            <div className={styles.pdfDocument}>
              <PdfDocument
                file={url}
                onLoadSuccess={handleLoadSuccess}
                loading={<LoadingPlaceholder />}
              >
                {[...Array(numPages).keys()].map((pageIndex) => {
                  return (
                    <div
                      key={pageIndex}
                      className={styles.pdfPage}
                      data-index={pageIndex + 1}
                    >
                      <PdfPage
                        pageIndex={pageIndex}
                        scale={scale / 100}
                        onLoadSuccess={handlePageLoadSuccess}
                        placeholderHeight={placeholderHeight}
                      />
                    </div>
                  );
                })}
                {numPages === 0 && <PdfPlaceholder name={name} url={url} />}
              </PdfDocument>
            </div>
          </ScrollArea>
        </div>
      </div>
    </>
  );
}

const Toolbar = ({
  currentPage,
  numPages,
  scale,
  scrollTo,
  setScale,
  showThumbnails,
  toggleThumbnails,
  zoomIn,
  zoomOut,
}: {
  currentPage: number;
  numPages: number;
  scale: number;
  scrollTo: (index: number) => void;
  setScale: React.Dispatch<React.SetStateAction<number>>;
  showThumbnails: boolean;
  toggleThumbnails: () => void;
  zoomIn: () => void;
  zoomOut: () => void;
}) => {
  const goToPrevPage = () => {
    if (currentPage > 1) {
      scrollTo(currentPage - 1);
    } else {
      scrollTo(1);
    }
  };
  const goToNextPage = () => {
    if (currentPage < numPages) {
      scrollTo(currentPage + 1);
    } else {
      scrollTo(numPages);
    }
  };

  return (
    <div className={styles.pdfToolbar}>
      <div className={styles.nav}>
        <DetailsModalTooltip content={`${showThumbnails ? 'Hide' : 'Show'} thumbnails`}>
          <Button onClick={toggleThumbnails} size="sm" variant="icon">
            <DrawerLeft size={20} />
          </Button>
        </DetailsModalTooltip>
        <span className={styles.separator} />
        <div className={styles.navByButtons}>
          <DetailsModalTooltip content="Go to previous page">
            <Button onClick={goToPrevPage} size="sm" variant="icon">
              <Up size={20} />
            </Button>
          </DetailsModalTooltip>
          <DetailsModalTooltip content="Go to next page">
            <Button onClick={goToNextPage} size="sm" variant="icon">
              <Down size={20} />
            </Button>
          </DetailsModalTooltip>
        </div>
        <div className={styles.navByInput}>
          <input
            disabled={numPages === 0}
            max={numPages}
            min={0}
            onChange={(ev) => scrollTo(Number(ev.currentTarget.value))}
            step={1}
            type="number"
            value={numPages === 0 ? 0 : currentPage}
          />
          <span>of</span>
          <input type="number" value={numPages} disabled />
        </div>
      </div>
      <div className={styles.zoom}>
        <DetailsModalTooltip content="Zoom out">
          <Button onClick={zoomIn} size="sm" variant="icon">
            <Add size={20} />
          </Button>
        </DetailsModalTooltip>
        <DetailsModalTooltip content="Zoom in">
          <Button onClick={zoomOut} size="sm" variant="icon">
            <Minus size={20} />
          </Button>
        </DetailsModalTooltip>
        <Select
          className={styles.dropdown}
          contentClassName={styles.dropdownContent}
          selectContentProps={{
            align: 'end',
          }}
          defaultValue="100"
          onValueChange={(value) => setScale(Number(value))}
          placeholder="Actual size"
          selectGroups={[
            {
              options: scaleValuesAsPercentage.map((scale) => ({
                label: `${scale}%`,
                value: String(scale),
              })),
            },
          ]}
          value={String(scale)}
        />
      </div>
    </div>
  );
};

const Pagination = ({
  handleLoadSuccess,
  numPages,
  scrollTo,
  url,
}: {
  handleLoadSuccess: ({ numPages }: { numPages: number }) => void;
  numPages: number;
  scrollTo: (index: number) => void;
  url: string;
}) => {
  const [placeholderHeight, setPlaceholderHeight] = useState<number | undefined>();
  const handlePageLoadSuccess = useCallback(({ height }: { height: number }) => {
    setPlaceholderHeight((current) =>
      current === undefined ? height : Math.max(current, height)
    );
  }, []);

  return (
    <div className={styles.pagination}>
      <ScrollArea
        className={styles.scrollAreaSecondary}
        style={css({
          '--width': '100%',
          '--maxHeight': '100%',
        })}
      >
        <PdfDocument file={url} onLoadSuccess={handleLoadSuccess} loading={<Spinner />}>
          {[...Array(numPages).keys()].map((pageIndex) => {
            return (
              <div
                key={pageIndex}
                className={styles.pdfPage}
                onClick={(ev) => {
                  ev.preventDefault();
                  ev.stopPropagation();
                  scrollTo(pageIndex + 1);
                }}
              >
                <PdfPage
                  pageIndex={pageIndex}
                  width={100}
                  onLoadSuccess={handlePageLoadSuccess}
                  placeholderHeight={placeholderHeight}
                />
              </div>
            );
          })}
        </PdfDocument>
      </ScrollArea>
    </div>
  );
};

export const PdfPlaceholder = ({
  name,
  url,
}: {
  name: string;
  url: string;
}) => {
  return (
    <div className={styles.noPreview}>
      <PdfFile className={styles.fileIcon} />
      <div className={styles.fileName}>{name}</div>
      <Button
        iconBefore={<Download size={18} />}
        onClick={() => window.open(url)}
        size="md"
        variant="outlined"
      >
        Download
      </Button>
    </div>
  );
};

import { Icon16 } from '@spaceduck/icons';

import { urlFor } from '@/urls';
import { isCapable } from '@api/util';
import { useWorkspaceProjects } from '@api/workspace';
import { useCreateProjectModal } from '@components/admin/CreateProjectsModal';
import { ProjectMode as ProjectModeIcon } from '@components/icons';
import { ProjectFeature } from '@components/icons';
import useWorkspaceId from '@hooks/useWorkspaceId';
import Button from '@ui/Button';
import Collapsible from '@ui/Collapsible';
import MenuLink from '../MenuLink';
import styles from './MenuProjectsSection.module.scss';
import { isProject } from '@spaceduck/api';

const { Add } = Icon16;

export default function MenuProjectsSection() {
  const workspaceId = useWorkspaceId();
  const { data: workspaceProjects } = useWorkspaceProjects(workspaceId, {
    isStarred: true,
    flattenStacks: true,
  });
  const createProjectCapable = isCapable('create', workspaceProjects?.capabilities);
  const canRoleCreateProject =
    createProjectCapable.capable || createProjectCapable.reason === 'plan';
  const { open: openCreateProjectModal } = useCreateProjectModal({
    canCreateProject: createProjectCapable,
    redirectOnCreate: true,
    isStarred: true,
  });

  return (
    <nav className={styles.projects}>
      <div className={styles.menuHeading}>
        Favorites
        {canRoleCreateProject && (
          <Button
            onClick={openCreateProjectModal}
            variant="icon"
            title="Create a new space"
          >
            <Add />
          </Button>
        )}
      </div>
      {workspaceProjects?.projects.length === 0 && (
        <div className={styles.emptyMessage}>No spaces have been favorited</div>
      )}
      {workspaceProjects?.projects.filter(isProject).map((project) => {
        return (
          <Collapsible
            className={styles.menu}
            key={project.id}
            triggerContent={
              <>
                <ProjectModeIcon mode={project.mode} set={16} />
                <span title={project.label}>{project.label}</span>
              </>
            }
          >
            <div className={styles.menuContent}>
              <MenuLink
                className={styles.projectLink}
                icon={<ProjectFeature feature="researchAssistant" />}
                url={urlFor('spaceResearchAssistant', { projectId: project.id })}
              >
                Research Assistant
              </MenuLink>
              <MenuLink
                className={styles.projectLink}
                icon={<ProjectFeature feature="library" />}
                url={urlFor('spaceLibrary', { projectId: project.id })}
              >
                Library
              </MenuLink>
              <MenuLink
                className={styles.projectLink}
                icon={<ProjectFeature feature="category" />}
                url={urlFor('spaceCategories', { projectId: project.id })}
              >
                Categories
              </MenuLink>
              <MenuLink
                className={styles.projectLink}
                icon={<ProjectFeature feature="progress" />}
                url={urlFor('spaceProgress', { projectId: project.id })}
              >
                Progress
              </MenuLink>
            </div>
          </Collapsible>
        );
      })}
    </nav>
  );
}

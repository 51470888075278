import createToast, { type CreateToastProps } from '@/utils/createToast';
import {
  ApiError,
  type CapabilityReason,
  type CapabilitySchema,
  type MinimalApiErrorPayload,
} from '@spaceduck/api';

export { safeUrl } from '@spaceduck/api';

const toastApiError = <T extends MinimalApiErrorPayload>(error: ApiError<T>) => {
  const { title, body } = error.payload;
  createToast({ titleText: title, bodyText: body, iconVariant: 'warning' });
};

export const toastApiErrorOr = (
  error: any,
  logMessage: string,
  toastOptions: CreateToastProps
): void => {
  console.error(logMessage, error);
  isApiError(error) ? toastApiError(error) : createToast(toastOptions);
};

export const isApiError = (error: any): error is ApiError => error instanceof ApiError;

export type IsCapable =
  | { capable: true; limitLeft?: number }
  | { capable: false; reason: CapabilityReason | null };

export const isCapable = <T>(
  wantedCapability: T,
  capabilities?: CapabilitySchema<T>[] | null
): IsCapable => {
  if (!capabilities) {
    return {
      capable: false,
      reason: null,
    };
  }

  const capable = capabilities?.find(
    (capability) => capability.capability === wantedCapability
  );

  if (capable?.capable) {
    return {
      capable: true,
      limitLeft: capable.limitLeft ?? undefined,
    };
  }

  return {
    capable: false,
    reason: capable?.reason ?? 'role',
  };
};

import * as RadixContextMenu from '@radix-ui/react-context-menu';
import clsx from 'clsx';
import { useCallback, useState } from 'react';
import { z } from 'zod';

import { css } from '@/lib/css';
import { Icon24 } from '@spaceduck/icons';
import styles from './ContextMenu.module.scss';
import dropdownMenuStyles from './DropdownMenu.module.scss';
const { Right } = Icon24;

export type ContextMenuItemProps = {
  className?: string;
  content: React.ReactNode;
  disabled?: boolean;
  icon?: React.ReactNode;
  isMenuItem?: boolean;
  isPadded?: boolean;
  isSeparator?: boolean;
  onClick?: (event: React.MouseEvent) => void;
  onSelect?: (event: Event) => void;
  shortcut?: string;
  subMenu?: ContextMenuItemProps[];
  width?: number;
};

function ContextMenuItem({
  className,
  content,
  disabled,
  icon,
  isMenuItem = true,
  isPadded = true,
  isSeparator,
  onClick,
  onSelect,
  shortcut,
  subMenu,
  width,
}: ContextMenuItemProps) {
  if (!content && isSeparator) {
    return <div className={styles.separator} />;
  }

  const itemContent = (
    <>
      {content}
      {shortcut && (
        <div className={clsx(styles.contextMenuShortcut, 'body6')}>{shortcut}</div>
      )}
    </>
  );

  if (subMenu) {
    return (
      <RadixContextMenu.Sub>
        <RadixContextMenu.SubTrigger className={dropdownMenuStyles.dropdownMenuItem}>
          {itemContent}
          <Right className={dropdownMenuStyles.subTriggerIcon} />
        </RadixContextMenu.SubTrigger>
        <RadixContextMenu.Portal>
          <RadixContextMenu.SubContent
            alignOffset={-8}
            sideOffset={14}
            className={clsx(isPadded && 'menu', dropdownMenuStyles.dropdownMenuContent)}
            style={width ? css({ '--menu-width': `${width}px` }) : undefined}
          >
            {subMenu.map((item, idx) => (
              <ContextMenuItem key={idx} {...item} />
            ))}
          </RadixContextMenu.SubContent>
        </RadixContextMenu.Portal>
      </RadixContextMenu.Sub>
    );
  }

  if (!isMenuItem) {
    // Basic workaround for non-menu items: https://github.com/radix-ui/primitives/discussions/1009
    // TODO: Explore possible combo-box implementation to handle focus management and user interactions - https://github.com/radix-ui/primitives/issues/1342
    return (
      <div className={className} onClick={onClick}>
        {content}
      </div>
    );
  }

  return (
    <RadixContextMenu.Item
      className={clsx(dropdownMenuStyles.dropdownMenuItem, className)}
      disabled={disabled}
      onClick={onClick}
      onSelect={onSelect}
    >
      {icon ? (
        <div className={styles.itemWithIcon}>
          {icon}
          {itemContent}
        </div>
      ) : (
        itemContent
      )}
    </RadixContextMenu.Item>
  );
}

const pxSchema = z.custom<`${number}px`>((val) => {
  return typeof val === 'string' ? /^\d+px$/.test(val) : false;
});

export default function ContextMenu({
  children,
  items,
  disabled = false,
  className,
  contentClassName,
}: {
  children: React.ReactNode;
  items?: ContextMenuItemProps[];
  disabled?: boolean;
  className?: string;
  contentClassName?: string;
}) {
  const [safeAreaInsetBottom, setSafeAreaInsetBottom] = useState(0);

  // for collision padding on mobile
  const initSafeArea = useCallback(() => {
    const _safeAreaInsetBottom = getComputedStyle(
      document.documentElement
    ).getPropertyValue('--safe-area-inset-bottom');

    const parsedPx = pxSchema.safeParse(_safeAreaInsetBottom);

    if (!parsedPx.success) return;
    const value = Number.parseInt(parsedPx.data.split('px')[0] || '0', 10);
    setSafeAreaInsetBottom(value);
  }, []);

  return (
    <RadixContextMenu.Root
      onOpenChange={() => {
        if (safeAreaInsetBottom) return;

        initSafeArea();
      }}
    >
      <RadixContextMenu.Trigger disabled={disabled} className={className}>
        {children}
      </RadixContextMenu.Trigger>
      <RadixContextMenu.Portal>
        <RadixContextMenu.Content
          className={clsx(
            dropdownMenuStyles.dropdownMenuContent,
            styles.contextMenuContent,
            contentClassName
          )}
          collisionPadding={{
            bottom: safeAreaInsetBottom,
          }}
        >
          {(items ?? []).map((item, idx) => (
            <ContextMenuItem key={idx} {...item} />
          ))}
        </RadixContextMenu.Content>
      </RadixContextMenu.Portal>
    </RadixContextMenu.Root>
  );
}

export const addClass = (newClassName: string, existingClassName?: string | null) => {
  if (!existingClassName) return newClassName;

  const classNameArr = existingClassName.split(' ').map((name) => name.trim());
  if (!existingClassName.includes(newClassName)) {
    classNameArr.push(newClassName);
  }

  return classNameArr.join(' ');
};

export type StringContainsOptions = {
  ignoreCase?: boolean;
};

const containsTransform = (
  text: string,
  options: StringContainsOptions | undefined
) => {
  if (options?.ignoreCase ?? false) {
    return text.toLowerCase();
  }
  return text;
};

export const stringContains = (
  haystack: string,
  needle: string,
  options?: StringContainsOptions
) => {
  return (
    containsTransform(haystack, options).indexOf(containsTransform(needle, options)) >=
    0
  );
};

import { Icon16, Icon24 } from '@spaceduck/icons';
import { upperFirst } from 'lodash';
import { useState } from 'react';
import { useParams } from 'react-router';

import { type MediaGroupSortOption, mediaGroupSortOptions } from '@spaceduck/api';

import { useMediaGroupCategory, useProject } from '@api/project';
import CategoryTable from '@components/category/Category';
import Head from '@components/Head';
import LoadingPlaceholder from '@components/LoadingPlaceholder';
import { NewMediaGroupDropdown } from '@components/NewMediaGroupDropdown';
import { useDebouncedSearch } from '@hooks/useDebouncedSearch';
import { Search, SubNav } from '@pages/common';
import Button from '@ui/Button';
import DropdownMenu, { MenuItem } from '@ui/DropdownMenu';
import PageHeader from './common/PageHeader';
import ProjectMenu from './common/ProjectMenu';
import ProjectTabs from './common/ProjectTabs';
import TwoColumnContent from './common/TwoColumnContent';
import sharedStyles from './Shared.module.scss';
import styles from './Category.module.scss';

const { Sort } = Icon16;
const { Down } = Icon24;

export default function CategoryPage() {
  const [searchQuery, setSearchQuery] = useState('');
  const { debouncedSetSearchQuery } = useDebouncedSearch(setSearchQuery);
  const [sortBy, setSortBy] = useState<MediaGroupSortOption>('name');

  const { categoryId } = useParams();
  // TODO: Use URL params
  const { data: categoryData } = useMediaGroupCategory(categoryId || null);

  const { data: projectData } = useProject(categoryData?.category.project.id || null);

  const category = categoryData?.category;
  if (!projectData) {
    return;
  }

  return (
    <>
      <Head title={`${projectData?.project.label || 'Space'} Categories`} />
      <PageHeader projectId={projectData?.project.id} />
      <ProjectTabs
        cta={
          <NewMediaGroupDropdown
            mediaGroupAttributes={{
              categoryId: category?.id,
              projectId: projectData?.project.id,
            }}
          />
        }
        projectId={projectData?.project.id}
      />
      <TwoColumnContent
        contentToolbar={null}
        sidebar={<ProjectMenu project={projectData.project} />}
      >
        <SubNav className={styles.subNav}>
          <div className={styles.utilities}>
            <DropdownMenu
              className={styles.sortDropdown}
              isPadded
              triggerContent={
                <Button
                  className={styles.sortButton}
                  iconAfter={<Down size={16} />}
                  iconBefore={<Sort size={15} />}
                  variant="outlined"
                >
                  Sort by: {sortBy}
                </Button>
              }
            >
              {mediaGroupSortOptions.map((sortByOption, idx) => (
                <MenuItem
                  key={idx}
                  onSelect={() => {
                    setSortBy(sortByOption);
                  }}
                >
                  {upperFirst(sortByOption)}
                </MenuItem>
              ))}
            </DropdownMenu>
            <Search
              className={styles.searchBox}
              collapsible
              defaultExpanded={false}
              defaultValue={searchQuery}
              onInput={(ev) => debouncedSetSearchQuery(ev.currentTarget.value)}
              placeholder="Find an entry..."
              size="sm"
              status="success"
            />
          </div>
        </SubNav>
        {category ? (
          <CategoryTable
            category={category}
            searchQuery={searchQuery}
            sortBy={sortBy}
            loadingPlaceholder={
              <div className={sharedStyles.spinnerContainer}>
                <LoadingPlaceholder />
              </div>
            }
          />
        ) : (
          <NoCategory />
        )}
      </TwoColumnContent>
    </>
  );
}

const NoCategory = () => {
  return <div />;
};

import { clsx } from 'clsx';
import { type ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useLocation } from 'react-router';
import type { z } from 'zod';

import {
  type MediaGroupContentType,
  type MediaGroupFiltersSchema,
  type SearchSuggestionDTO,
  mediaGroupSearchFilterLabels,
  mediaGroupSearchFilterProperties,
  mediaGroupSearchFilterPropertySchema,
  type tagDTO,
} from '@spaceduck/api';
import { Icon24 } from '@spaceduck/icons';

import { ContentType } from '@components/icons';
import { SearchDatePickerPopover } from '@components/SearchDatePickerPopover';
import SearchFilters, { type SearchFilterProperties } from '@components/SearchFilters';
import {
  type SearchQuery,
  isDateSearchQuery,
  isFromSuggestionSearchQuery,
  useSearch,
} from '@hooks/useSearch';
import { FilterIcon } from '@icons/FilterIcon';
import MediaGroupStatusIcon from '@icons/MediaGroupStatus';
import Tag from '@ui/Tag';
import { getDisplayDate } from '@utils/date';
import SearchSuggestions from './SearchSuggestions';
import styles from './Searchbar.module.scss';

const { Loading, Search } = Icon24;

type SearchProps = {
  clearSearch: () => void;
  excludeProjectLibraries: boolean;
  handleBlur: () => void;
  handleFocus: () => void;
  handleInputUpdate: (value: string) => Promise<void>;
  handleKeyDown: (ev: KeyboardEvent) => void;
  isLoading: boolean;
  mediaGroupFilters: MediaGroupFiltersSchema;
  removeSearchQuery: (idx: number) => void;
  searchInputValue: string;
  searchQueries: SearchQuery[];
  setSearchElementRef:
    | ((value: {
        current: HTMLInputElement | null;
      }) => void)
    | ((searchElementRef: React.RefObject<HTMLInputElement>) => void);
  setSearchInputValue:
    | React.Dispatch<React.SetStateAction<string>>
    | ((searchInputValue: string) => void);
  setSearchQueries: (searchQueries: SearchQuery[]) => void;
  showAltPlaceholder: boolean;
};

type SearchbarBaseProps = {
  className?: string;
  placeholder?: string;
  availableFilters?: SearchFilterProperties[];
  defaultFilters?: MediaGroupFiltersSchema;
  focusOnLoad?: boolean;
  onEmptyEscape?: (ev?: KeyboardEvent) => void;
};

type GlobalSearchbarProps = {
  isLocalSearch?: false;
  localSearchProps?: null;
};

type LocalSearchbarProps = {
  isLocalSearch: true;
  localSearchProps: SearchProps;
};

type SearchbarProps = SearchbarBaseProps & (GlobalSearchbarProps | LocalSearchbarProps);

export default function Searchbar(props: SearchbarProps) {
  if (props.isLocalSearch && props.localSearchProps) {
    return <LocalSearchBar {...props} />;
  }

  return <GlobalSearchBar {...props} />;
}

const LocalSearchBar = (props: SearchbarBaseProps & LocalSearchbarProps) => {
  const {
    clearSearch,
    excludeProjectLibraries,
    handleBlur,
    handleFocus,
    handleInputUpdate,
    handleKeyDown,
    isLoading,
    mediaGroupFilters,
    removeSearchQuery,
    searchInputValue,
    searchQueries,
    setSearchElementRef,
    setSearchInputValue,
    setSearchQueries,
    showAltPlaceholder,
  } = props.localSearchProps;

  const searchProps = {
    clearSearch,
    excludeProjectLibraries,
    handleBlur,
    handleFocus,
    handleInputUpdate,
    handleKeyDown,
    isLoading,
    mediaGroupFilters,
    removeSearchQuery,
    searchInputValue,
    searchQueries,
    setSearchElementRef,
    setSearchInputValue,
    setSearchQueries,
    showAltPlaceholder,
  };

  return <SearchbarComponent {...props} {...searchProps} />;
};

const GlobalSearchBar = (props: SearchbarBaseProps & GlobalSearchbarProps) => {
  const {
    clearSearch,
    excludeProjectLibraries,
    handleBlur,
    handleFocus,
    handleInputUpdate,
    handleKeyDown,
    isLoading,
    mediaGroupFilters,
    removeSearchQuery,
    searchInputValue,
    searchQueries,
    setSearchElementRef,
    setSearchInputValue,
    setSearchQueries,
    showAltPlaceholder,
  } = useSearch();

  const searchProps = {
    clearSearch,
    excludeProjectLibraries,
    handleBlur,
    handleFocus,
    handleInputUpdate,
    handleKeyDown,
    isLoading,
    mediaGroupFilters,
    removeSearchQuery,
    searchInputValue,
    searchQueries,
    setSearchElementRef,
    setSearchInputValue,
    setSearchQueries,
    showAltPlaceholder,
  };

  return <SearchbarComponent {...props} {...searchProps} />;
};

const SearchbarComponent = ({
  availableFilters = [...mediaGroupSearchFilterPropertySchema.options, 'date'],
  clearSearch,
  className,
  defaultFilters,
  excludeProjectLibraries,
  focusOnLoad,
  handleBlur,
  handleFocus,
  handleInputUpdate,
  handleKeyDown,
  isLoading,
  mediaGroupFilters,
  onEmptyEscape,
  placeholder,
  removeSearchQuery,
  searchInputValue,
  searchQueries,
  setSearchElementRef,
  setSearchInputValue,
  setSearchQueries,
  showAltPlaceholder,
}: SearchbarProps & SearchProps) => {
  const [appliedFilter, setAppliedFilter] = useState<{
    filter: SearchFilterProperties;
    exclude: boolean;
  }>();
  const [suggestionsQuery, setSuggestionsQuery] = useState('');
  const [filterQuery, setFilterQuery] = useState('');
  const [datePickerOpen, setDatePickerOpen] = useState(false);
  const [dateRange, setDateRange] = useState<{ start?: Date; end?: Date }>({});
  const inputField = useRef<HTMLInputElement>(null);
  const location = useLocation();

  useEffect(() => {
    if (focusOnLoad) {
      const focusTimeout = setTimeout(() => {
        inputField.current?.focus();
      }, 100);

      return () => clearTimeout(focusTimeout);
    }
  }, [focusOnLoad]);

  const searchQueryToString = (searchQuery: SearchQuery): string => {
    if (typeof searchQuery === 'string') return searchQuery;
    if (isDateSearchQuery(searchQuery)) {
      const minDisplayDate = getDisplayDate(searchQuery.minDatetime);
      const maxDisplayDate = getDisplayDate(searchQuery.maxDatetime);
      if (minDisplayDate === maxDisplayDate) return minDisplayDate;
      return `${minDisplayDate} - ${maxDisplayDate}`;
    }
    return `${searchQuery.exclude ? '-' : ''}${mediaGroupSearchFilterLabels[searchQuery.fromSuggestion.filter]}: ${
      searchQuery.fromSuggestion.label || '(Empty)'
    }`;
  };

  useHotkeys(
    '/',
    (ev) => {
      ev.preventDefault();
      inputField.current?.focus();
    },
    {},
    [inputField?.current]
  );

  useEffect(() => {
    if (inputField.current) {
      handleInputUpdate(inputField.current?.value ?? '');
    }
  }, [searchQueries, inputField.current]);

  const onEscKey = useCallback(
    (ev: KeyboardEvent) => {
      if (ev.key === 'Escape') {
        ev.preventDefault();

        const valueBeforeClear = (ev.currentTarget as HTMLInputElement).value;
        clearSearch();

        if (!valueBeforeClear) {
          onEmptyEscape?.(ev);
        }
      }
    },
    [onEmptyEscape]
  );

  const onAcceptTextQuery = (query: string) => (ev: KeyboardEvent) => {
    if (ev.key !== 'Enter') {
      return;
    }
    ev.preventDefault();
    onSuggestionSelect({
      filter: 'text',
      id: query,
      label: query,
    });
  };

  useEffect(() => {
    const parsed = extractFilterAndSuggestionQuery(searchInputValue);
    inputField.current?.addEventListener('keydown', onEscKey);
    if (parsed.context === undefined) {
      inputField.current?.addEventListener('keydown', handleKeyDown);
      return () => {
        inputField.current?.removeEventListener('keydown', onEscKey);
        inputField.current?.removeEventListener('keydown', handleKeyDown);
      };
    }

    if (parsed.context === 'suggestion' && parsed.filter === 'text') {
      const selectTextSuggestionHandler = onAcceptTextQuery(parsed.suggestionQuery);
      inputField.current?.addEventListener('keydown', selectTextSuggestionHandler);

      return () => {
        inputField.current?.removeEventListener('keydown', onEscKey);
        inputField.current?.removeEventListener('keydown', selectTextSuggestionHandler);
      };
    }
    return () => inputField.current?.removeEventListener('keydown', onEscKey);
  }, [searchInputValue]);

  const extractFilterAndSuggestionQuery = (
    value: string
  ):
    | {
        exclude: boolean;
        filter: SearchFilterProperties;
        suggestionQuery: string;
        context: 'suggestion';
      }
    | {
        exclude: boolean;
        filterQuery: string;
        context: 'filter';
      }
    | {
        context: undefined;
      } => {
    let context: 'filter' | 'suggestion' | undefined;
    if (value.includes(':')) {
      context = 'suggestion';
    } else if (value.startsWith('@')) {
      context = 'filter';
    }

    const [filter, suggestionQuery] = value
      .replace('@', '')
      .split(':')
      .map((f) => f.trim());
    const filterParsed = (
      [...mediaGroupSearchFilterProperties, 'date'] as const
    ).filter(
      (property) => mediaGroupSearchFilterLabels[property] === filter?.replace('-', '')
    );

    if (filterParsed.length && filterParsed[0] && context === 'suggestion') {
      return {
        exclude: filter?.startsWith('-') ?? false,
        filter: filterParsed[0],
        suggestionQuery: suggestionQuery ?? '',
        context,
      };
    }
    if (context === 'filter') {
      return {
        exclude: filter?.startsWith('-') ?? false,
        filterQuery: filter?.replace('-', '') ?? '',
        context,
      };
    }

    return {
      context: undefined,
    };
  };

  const updateFilters = useCallback(
    (value: string) => {
      const filterData = extractFilterAndSuggestionQuery(value);

      if (filterData.context === 'suggestion') {
        setAppliedFilter({
          exclude: filterData.exclude,
          filter: filterData.filter,
        });
        setSuggestionsQuery(filterData.suggestionQuery);
      } else if (filterData.context === 'filter') {
        setAppliedFilter(undefined);
        setFilterQuery(filterData.filterQuery!);
      } else {
        setAppliedFilter(undefined);
        setSuggestionsQuery('');
        setFilterQuery('');
      }
    },
    [setAppliedFilter, setFilterQuery, setSuggestionsQuery]
  );

  const handleChange = (ev: ChangeEvent<HTMLInputElement>) => {
    const value = ev.target.value;
    updateFilters(value);
    setSearchInputValue(value);
    const parsedFilters = extractFilterAndSuggestionQuery(value);
    if (parsedFilters.context === undefined) {
      handleInputUpdate(value);
    }
  };

  const onFilterSelect = (filter: SearchFilterProperties) => {
    let newSearchInputValue = `${mediaGroupSearchFilterLabels[filter]}:`;
    const prefix = searchInputValue.match(/^@[-]?/);
    if (prefix) {
      newSearchInputValue = `${prefix[0]}${mediaGroupSearchFilterLabels[filter]}:`;
    }

    setSearchInputValue(newSearchInputValue);
    updateFilters(newSearchInputValue);
  };

  const onSuggestionSelect = (suggestion: SearchSuggestionDTO) => {
    if (appliedFilter === undefined || appliedFilter.filter === 'date') {
      return;
    }
    setSearchQueries([
      ...searchQueries,
      {
        exclude: appliedFilter.exclude,
        fromSuggestion: suggestion,
      },
    ]);
    setSearchInputValue('');
  };

  const onSuggestionPreview = useCallback(
    (value: string) => {
      const inputValue = inputField.current?.value || '';
      const parsed = extractFilterAndSuggestionQuery(inputValue);
      if (parsed.context === 'suggestion') {
        const firstPart = inputValue.split(':')[0];
        setSearchInputValue(`${firstPart}:${value}`);
      }
    },
    [inputField.current?.value]
  );

  const onFilterPreview = useCallback(
    (value: SearchFilterProperties) => {
      const inputValue = inputField.current?.value || '';
      const parsed = extractFilterAndSuggestionQuery(inputValue);
      if (parsed.context === 'filter') {
        const firstPart = inputValue.includes('@-')
          ? '@-'
          : inputValue.includes('@')
            ? '@'
            : '';
        setSearchInputValue(`${firstPart}${mediaGroupSearchFilterLabels[value]}`);
      }
    },
    [inputField.current?.value]
  );

  useEffect(() => {
    if (inputField?.current) {
      setSearchElementRef(inputField);
    }

    return () => setSearchElementRef({ current: null });
  }, [setSearchElementRef, inputField?.current]);

  useEffect(() => {
    const tag: z.infer<typeof tagDTO> = location?.state?.tag;
    if (tag) {
      setSearchQueries([
        {
          exclude: false,
          fromSuggestion: {
            filter: 'tag',
            label: tag.label,
            id: tag.id,
          },
        },
      ]);
      location.state.tag = undefined;
    }
  }, [location?.state?.tag]);

  const filterData = extractFilterAndSuggestionQuery(searchInputValue);

  useEffect(() => {
    if (
      appliedFilter?.filter === 'date' &&
      filterData.context === 'suggestion' &&
      (document.activeElement === inputField.current || datePickerOpen)
    ) {
      setDatePickerOpen(true);
    } else {
      setDatePickerOpen(false);
      setDateRange({});
    }
  }, [appliedFilter?.filter, filterData.context, datePickerOpen]);

  const placeholderText =
    placeholder ??
    'Search for apps, design patterns, components, features and flows...';
  const placeholderTextAlt =
    "Press `@` for filters or simply type what you're looking for ...";

  return (
    <div
      className={clsx(styles.container, className)}
      onClick={() => inputField.current?.focus()}
    >
      <div className={styles.statusIcon}>
        {isLoading ? (
          <div className={styles.loading}>
            <Loading />
          </div>
        ) : (
          <Search />
        )}
      </div>

      <div className={clsx('title5', styles.inputWrapper)}>
        {searchQueries.length > 0 && (
          <ul className={styles.searchItemList}>
            {searchQueries.map((searchQuery, idx) => (
              <li className={styles.searchItem} key={idx}>
                <Tag
                  className={styles.tag}
                  removeIconIsHidden
                  onClick={() => removeSearchQuery(idx)}
                  onRemoveClick={() => removeSearchQuery(idx)}
                  size="lg"
                  variant="quaternary"
                >
                  {isFromSuggestionSearchQuery(searchQuery) &&
                    searchQuery.fromSuggestion.filter !== 'status' &&
                    searchQuery.fromSuggestion.filter !== 'contentType' &&
                    searchQuery.fromSuggestion.filter !== 'project' && (
                      <FilterIcon
                        filter={searchQuery.fromSuggestion.filter}
                        variant="filter"
                      />
                    )}
                  {isFromSuggestionSearchQuery(searchQuery) &&
                    searchQuery.fromSuggestion.filter === 'project' && (
                      <FilterIcon
                        filter={'project'}
                        variant="filter"
                        isPrivate={searchQuery.fromSuggestion.isPrivate}
                      />
                    )}
                  {isFromSuggestionSearchQuery(searchQuery) &&
                    searchQuery.fromSuggestion.filter === 'status' && (
                      <MediaGroupStatusIcon
                        status={{
                          id: searchQuery.fromSuggestion.id,
                          color: searchQuery.fromSuggestion.color,
                          label: searchQuery.fromSuggestion.label,
                        }}
                        size={16}
                      />
                    )}
                  {isFromSuggestionSearchQuery(searchQuery) &&
                    searchQuery.fromSuggestion.filter === 'contentType' && (
                      <ContentType
                        contentType={
                          searchQuery.fromSuggestion.id as MediaGroupContentType
                        }
                        size={16}
                      />
                    )}
                  {searchQueryToString(searchQuery)}
                </Tag>
              </li>
            ))}
          </ul>
        )}
        <div className={styles.inputAndFilters}>
          <input
            type="search"
            onChange={handleChange}
            onBlur={handleBlur}
            onFocus={handleFocus}
            value={searchInputValue}
            ref={inputField}
            placeholder={showAltPlaceholder ? placeholderTextAlt : placeholderText}
            className={
              filterData.context === 'suggestion' && filterData.filter === 'date'
                ? styles.dateInput
                : undefined
            }
          />
          {document.activeElement === inputField.current &&
            filterData.context === 'filter' && (
              <SearchFilters
                active={filterData.context === 'filter'}
                onSelect={onFilterSelect}
                availableFilters={availableFilters}
                inputRef={inputField}
                query={filterQuery}
                onPreview={onFilterPreview}
              />
            )}
          {document.activeElement === inputField.current &&
            appliedFilter &&
            appliedFilter.filter !== 'text' &&
            appliedFilter.filter !== 'date' &&
            availableFilters.includes(appliedFilter.filter) &&
            filterData.context === 'suggestion' && (
              <SearchSuggestions
                onPreview={onSuggestionPreview}
                active={filterData.context === 'suggestion'}
                defaultFilters={defaultFilters}
                inputRef={inputField}
                filter={appliedFilter.filter}
                query={suggestionsQuery}
                onSelect={onSuggestionSelect}
                isLocalSearch={true}
                localSearchProps={{
                  excludeProjectLibraries,
                  mediaGroupFilters,
                }}
              />
            )}
          {filterData.context === 'suggestion' && filterData.filter === 'date' && (
            <SearchDatePickerPopover
              searchInputValue={searchInputValue}
              datePickerOpen={datePickerOpen}
              setDatePickerOpen={setDatePickerOpen}
              dateRange={dateRange}
              setDateRange={setDateRange}
              defaultFilters={defaultFilters}
              isLocalSearch={true}
              localSearchProps={{
                excludeProjectLibraries,
                mediaGroupFilters,
                searchQueries,
                setSearchInputValue,
                setSearchQueries,
              }}
            />
          )}
        </div>
        <span className={styles.hoverHint}>
          <span className={styles.key}>@</span>
          <span className={styles.text}>Filters</span>
        </span>
      </div>
    </div>
  );
};

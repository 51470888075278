import {
  addProjectToProjectStack,
  addWebLinkToProject,
  createProject,
  createProjectStack,
  deleteProject,
  getMediaGroupCategory,
  deleteProjectStack,
  getProject,
  joinProject,
  listMediaGroupCategories,
  patchProject,
  patchProjectMembership,
  patchProjectStack,
  removeUserFromProject,
  restoreProject,
  getProjectStack,
} from '@spaceduck/api';
import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { QueryDisabledError } from './errors';
import { userKeys } from './users';
import { workspaceKeys } from './workspace';

export const projectKeys = {
  all: ['project'] as const,
  one: (projectId: string | null) => [...projectKeys.all, projectId] as const,
  create: () => [...projectKeys.all, 'create'] as const,
  stack: (projectStackId: string | null) => [
    ...projectKeys.all,
    'stack',
    projectStackId,
  ],
  mediaGroupCategory: (categoryId: string | null) => [
    ['mediaGroupCategory', categoryId] as const,
  ],
  listMediaGroupCategories: (projectId: string | null, filters: { query: string }) =>
    [...projectKeys.one(projectId), 'mediaGroupCategories', filters] as const,
};

export const usePatchProject = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: patchProject,
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({ queryKey: projectKeys.all });
      if (data.workspaceId) {
        queryClient.invalidateQueries({
          queryKey: workspaceKeys.one(data.workspaceId),
        });
        queryClient.invalidateQueries({
          queryKey: projectKeys.one(variables.id),
        });
      }
    },
  });
  return mutation;
};

export const useProject = (projectId: string | null, refetchInterval?: number) => {
  const enabled = !!projectId;
  const query = useQuery({
    enabled,
    queryKey: projectKeys.one(projectId),
    queryFn: () => {
      if (!enabled) {
        throw new QueryDisabledError();
      }
      return getProject(projectId);
    },
    refetchInterval,
  });
  return query;
};

export const useCreateProject = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationKey: projectKeys.create(),
    mutationFn: createProject,
    onSuccess: (_data, variables) => {
      queryClient.invalidateQueries({ queryKey: projectKeys.all });
      queryClient.invalidateQueries({
        queryKey: workspaceKeys.one(variables.workspaceId),
      });
    },
  });

  return mutation;
};

export const useDeleteProject = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: deleteProject,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: projectKeys.all });
      queryClient.invalidateQueries({ queryKey: workspaceKeys.all });
      queryClient.invalidateQueries({ queryKey: userKeys.all });
    },
  });
  return mutation;
};

export const useRestoreProject = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: restoreProject,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: projectKeys.all });
      queryClient.invalidateQueries({ queryKey: workspaceKeys.all });
      queryClient.invalidateQueries({ queryKey: userKeys.all });
    },
  });
  return mutation;
};

export const useAddWebLinkToProject = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: addWebLinkToProject,
    onSuccess: (_data, variables) => {
      queryClient.invalidateQueries({
        queryKey: projectKeys.one(variables.projectId),
      });
    },
  });
  return mutation;
};

export const usePatchProjectMembership = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: patchProjectMembership,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: projectKeys.all });
      queryClient.invalidateQueries({ queryKey: workspaceKeys.all });
      queryClient.invalidateQueries({ queryKey: userKeys.all });
    },
  });
  return mutation;
};

export const useRemoveUserFromProject = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: removeUserFromProject,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: projectKeys.all });
      queryClient.invalidateQueries({ queryKey: workspaceKeys.all });
      queryClient.invalidateQueries({ queryKey: userKeys.all });
    },
  });
  return mutation;
};

export const useJoinProject = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: joinProject,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: projectKeys.all });
      queryClient.invalidateQueries({ queryKey: workspaceKeys.all });
      queryClient.invalidateQueries({ queryKey: userKeys.all });
    },
  });
  return mutation;
};

export const useListMediaGroupCategories = (
  projectId: string | null,
  query?: string
) => {
  const enabled = !!projectId;
  const infiniteQuery = useInfiniteQuery({
    enabled,
    queryKey: projectKeys.listMediaGroupCategories(projectId, {
      query: query ?? '',
    }),
    initialPageParam: undefined as string | undefined,
    queryFn: async ({ pageParam }) => {
      if (!enabled) {
        throw new QueryDisabledError();
      }
      const response = await listMediaGroupCategories({
        projectId,
        query: query ?? '',
        cursor: pageParam,
      });
      return response;
    },
    getNextPageParam: (lastPage) => lastPage?.nextCursor ?? undefined,
  });
  return infiniteQuery;
};

export const useMediaGroupCategory = (categoryId: string | null) => {
  const enabled = !!categoryId;
  const query = useQuery({
    enabled,
    queryKey: projectKeys.mediaGroupCategory(categoryId),
    queryFn: async () => {
      if (!enabled) {
        throw new QueryDisabledError();
      }
      const response = await getMediaGroupCategory({ categoryId });
      const { kind, ...payload } = response;
      return payload;
    },
  });
  return query;
};

export const useProjectStackDetail = (projectStackId: string | null) => {
  const enabled = !!projectStackId;
  const query = useQuery({
    enabled,
    queryKey: projectKeys.stack(projectStackId),
    queryFn: async () => {
      if (!enabled) {
        throw new QueryDisabledError();
      }
      const response = await getProjectStack(projectStackId);
      const { kind, ...payload } = response;
      return payload;
    },
  });
  return query;
};

export const usePatchProjectStack = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: patchProjectStack,
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({ queryKey: projectKeys.all });
      if (data.projectStack.workspaceId) {
        queryClient.invalidateQueries({
          queryKey: workspaceKeys.one(data.projectStack.workspaceId),
        });
        queryClient.invalidateQueries({
          queryKey: projectKeys.one(variables.id),
        });
      }
    },
  });
  return mutation;
};

export const useCreateProjectStack = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: createProjectStack,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: projectKeys.all });
      queryClient.invalidateQueries({ queryKey: workspaceKeys.all });
    },
  });
  return mutation;
};

export const useDeleteProjectStack = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: (id: string) => deleteProjectStack(id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: projectKeys.all });
      queryClient.invalidateQueries({ queryKey: workspaceKeys.all });
    },
  });
  return mutation;
};

export const useAddProjectToStack = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: ({
      projectId,
      projectStackId,
    }: { projectId: string; projectStackId: string }) =>
      addProjectToProjectStack(projectStackId, projectId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: projectKeys.all });
      queryClient.invalidateQueries({ queryKey: workspaceKeys.all });
    },
  });
  return mutation;
};

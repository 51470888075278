import { clsx } from 'clsx';
import { NavLink } from 'react-router';

import styles from './MenuLink.module.scss';

type ButtonOption = {
  asButton: true;
  onClick?: () => void;
};

type LinkOption = {
  asButton?: false;
  url: string;
};

type MenuLinkProps = {
  className?: string;
  children: React.ReactNode;
  count?: number;
  icon?: React.ReactNode;
  disabled?: boolean;
} & (LinkOption | ButtonOption);

export default function MenuLink(props: MenuLinkProps) {
  const { asButton, children, className, count, icon, disabled = false } = props;
  const contentProps = { children, count, icon };

  if (asButton) {
    return (
      <button
        className={clsx(styles.button, disabled && styles.disabled, className)}
        onClick={() => props.onClick?.()}
        disabled={disabled}
      >
        <Content {...contentProps}>{children}</Content>
      </button>
    );
  }

  return (
    <NavLink
      className={({ isActive }) =>
        clsx(
          styles.link,
          isActive && styles.active,
          disabled && styles.disabled,
          className
        )
      }
      to={props.url}
    >
      <Content {...contentProps}>{children}</Content>
    </NavLink>
  );
}

const Content = ({
  children,
  count,
  icon,
}: Omit<MenuLinkProps, 'asButton' | 'className' | 'onClick' | 'url'>) => {
  return (
    <>
      {icon && <span className={styles.icon}>{icon}</span>}
      <span className={styles.content}>{children}</span>
      {count !== undefined && <span className={styles.count}>{count}</span>}
    </>
  );
};

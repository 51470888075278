import {
  Content as DialogContent,
  Description as DialogDescription,
  Title as DialogTitle,
  Root as DialogRoot,
  Portal,
} from '@radix-ui/react-dialog';
import { VisuallyHidden } from '@radix-ui/react-visually-hidden';
import { useInView } from 'react-intersection-observer';
import { useCallback, useEffect, useRef } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useNavigate } from 'react-router';

import { mediaGroupSearchFilterPropertySchema } from '@spaceduck/api';
import { Icon24, Icon64 } from '@spaceduck/icons';

import {
  useBulkDeleteMediaGroups,
  useBulkTagMediaGroups,
  useCopyMediaGroup,
  useListMediaGroups,
} from '@api/mediaGroup';
import { useWorkspace } from '@api/workspace';
import { BulkSelectionToolbar } from '@components/BulkSelectionToolbar';
import MediaGroupGrid from '@components/MediaGroupGrid';
import { NewMediaGroupDropdown } from '@components/NewMediaGroupDropdown';
import NoEntries from '@components/NoEntries';
import Searchbar from '@components/Searchbar';
import { useModalManager } from '@context/ModalManagerContext';
import { useBatchUpdate } from '@hooks/useBatchUpdate';
import { useSearch } from '@hooks/useSearch';
import { useLastBackgroundLocation } from '@hooks/useBackgroundLocation';
import useDeleteMediaGroupConfirmModal from '@hooks/useDeleteMediaGroupConformModal';
import useMoveMediaGroupConfirmModal from '@hooks/useMoveMediaGroupConfirmModal';
import { useSelectedMediaGroups } from '@hooks/useSelectedMediaGroups';
import useWorkspaceId from '@hooks/useWorkspaceId';
import { css } from '@lib/css';
import { useDetailsModalStore } from '@stores/useDetailsModalStore';
import Button from '@ui/Button';
import { useForceUpgradeModal } from '@ui/ForceUpgradeModal';
import ScrollArea from '@ui/ScrollArea';
import Tooltip from '@ui/Tooltip';
import { BROWSER_EXT_URL, knownErrors } from '@/const';
import { urlFor } from '@/urls';
import styles from './GlobalSearchModal.module.scss';

const { Close } = Icon24;
const { Library } = Icon64;

export default function GlobalSearchModal({ closeModal }: { closeModal?: () => void }) {
  useForceUpgradeModal();
  const workspaceId = useWorkspaceId();
  const { selectedMediaGroupIds, selectedMediaGroups, onSelected, clearSelected } =
    useSelectedMediaGroups();
  const setCurrentMediaGroupPageCount = useDetailsModalStore(
    (store) => store.setCurrentMediaGroupPageCount
  );
  const escapeButtonRef = useRef<HTMLButtonElement | null>(null);
  const navigate = useNavigate();
  const lastBackgroundLocation = useLastBackgroundLocation();

  const handleClose = useCallback(() => {
    if (closeModal) {
      closeModal();
      return;
    }

    if (lastBackgroundLocation) {
      navigate(lastBackgroundLocation);
      return;
    }

    const fallbackExitUrl = workspaceId
      ? urlFor('workspaceSpaces', { workspaceId })
      : null;

    if (fallbackExitUrl) {
      navigate(fallbackExitUrl);
      return;
    }
  }, [workspaceId, navigate]);

  const { error: workspaceError } = useWorkspace(workspaceId);
  const isDetailsModalView = useDetailsModalStore(
    ({ isDetailsModalView }) => isDetailsModalView
  );

  const {
    debouncedSearchValue,
    excludeProjectLibraries,
    handleSearchClick,
    mediaGroupFilters,
    searchInputValue,
    searchQueries,
    setIsLoading,
  } = useSearch();

  const { handleCategoryChange, handleStatusChange } = useBatchUpdate();

  const {
    data,
    isLoading,
    isError,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    enabled,
  } = useListMediaGroups(workspaceId, {
    query: debouncedSearchValue,
    excludeProjectLibraries,
    ...mediaGroupFilters,
  });

  const { mutateAsync: bulkCopyMediaGroups } = useCopyMediaGroup();
  const { mutateAsync: bulkDeleteMediaGroups } = useBulkDeleteMediaGroups();
  const { mutateAsync: bulkTagMediaGroups } = useBulkTagMediaGroups();

  const openDeleteMediaGroupConfirmModal = useDeleteMediaGroupConfirmModal({
    onConfirm: async (ids: Set<string>) => {
      bulkDeleteMediaGroups(Array.from(ids));
    },
    onSuccess: () => {
      clearSelected();
    },
  });

  const moveMediaGroupsConfirmModal = useMoveMediaGroupConfirmModal({
    onConfirm: clearSelected,
  });

  const { ref, inView } = useInView();
  const mediaGroups = data?.pages.flatMap((page) => page.mediaGroups) || [];

  useEffect(() => {
    if (enabled && inView && hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [enabled, inView, isFetchingNextPage, hasNextPage]);

  useEffect(() => {
    if (data?.pages?.length) {
      setCurrentMediaGroupPageCount(data.pages.length);
    }
  }, [data?.pages]);

  useEffect(() => {
    setIsLoading(isLoading);
  }, [isLoading]);

  useEffect(() => {
    document.querySelector('body')?.classList.add('globalSearchActive');

    return () => {
      document.querySelector('body')?.classList.remove('globalSearchActive');
    };
  }, []);

  useHotkeys('Escape', handleClose, {
    preventDefault: true,
    enabled: !isDetailsModalView,
  });

  const onEmptyEscape = () => {
    escapeButtonRef.current?.focus();
  };

  const noEntries =
    mediaGroups.length === 0 &&
    searchQueries.length === 0 &&
    !searchInputValue &&
    !debouncedSearchValue &&
    !excludeProjectLibraries;

  if (workspaceError) {
    throw new Error(knownErrors.workspaceError, { cause: workspaceError });
  }

  return (
    <DialogRoot open={true}>
      <Portal>
        <div className={styles.overlay} />
        <DialogContent className={styles.dialogContent}>
          <VisuallyHidden asChild>
            <DialogTitle>Search</DialogTitle>
          </VisuallyHidden>
          <VisuallyHidden asChild>
            <DialogDescription>Seek, and ye shall find</DialogDescription>
          </VisuallyHidden>
          <div className={styles.layoutContainer}>
            <header className={styles.header}>
              <Tooltip
                content="Exit search"
                shortKeys={['ESC']}
                size="medium"
                variant="secondary"
              >
                <Button
                  className={styles.escapeButton}
                  onClick={handleClose}
                  ref={escapeButtonRef}
                  shortKeys={['ESC']}
                  type="button"
                  variant="ghost"
                >
                  <Close />
                </Button>
              </Tooltip>
              <div>
                {!noEntries && selectedMediaGroupIds.size > 0 && (
                  <BulkSelectionToolbar
                    count={selectedMediaGroupIds.size}
                    onCopyMediaGroup={(projectId) => {
                      bulkCopyMediaGroups({
                        mediaGroupIds: Array.from(selectedMediaGroupIds),
                        projectId,
                        mode: 'copy',
                      });
                      clearSelected();
                    }}
                    onMoveMediaGroup={(projectId) => {
                      moveMediaGroupsConfirmModal.open({
                        mediaGroupIds: Array.from(selectedMediaGroupIds),
                        projectId,
                      });
                    }}
                    onAddTag={(tag) => {
                      bulkTagMediaGroups({
                        mediaGroupIds: Array.from(selectedMediaGroupIds),
                        tags: [tag],
                      });
                    }}
                    onDelete={() =>
                      openDeleteMediaGroupConfirmModal(selectedMediaGroupIds)
                    }
                    onCancel={clearSelected}
                    selectedMediaGroups={selectedMediaGroups}
                    onUpdateStatus={(status) =>
                      handleStatusChange(selectedMediaGroups, status)
                    }
                    onUpdateCategory={(category) =>
                      handleCategoryChange(selectedMediaGroups, category)
                    }
                  />
                )}
                {!noEntries && !selectedMediaGroupIds.size && (
                  <Searchbar
                    availableFilters={(
                      [...mediaGroupSearchFilterPropertySchema.options, 'date'] as const
                    ).filter((f) => f !== 'status' && f !== 'category')}
                    className={styles.searchBar}
                    focusOnLoad
                    onEmptyEscape={onEmptyEscape}
                    placeholder="Search your repository or type '@' to filter..."
                  />
                )}
              </div>
            </header>
            <div className={styles.content}>
              <ScrollArea
                style={css({
                  '--width': '100%',
                  '--maxHeight': 'var(--radix-popper-available-height, 100%)',
                })}
              >
                <MediaGroupGrid
                  handleSearchClick={handleSearchClick}
                  isError={isError}
                  isFetchingNextPage={isFetchingNextPage}
                  isLoading={isLoading}
                  mediaGroups={mediaGroups}
                  noResults={<NoResults />}
                  selected={selectedMediaGroupIds}
                  showCTACard={true}
                  onSelected={onSelected}
                />
                <div ref={ref} style={{ width: '100%', height: '10px' }}>
                  {/* Triggers page fetch when in view */}
                </div>
              </ScrollArea>
            </div>
          </div>
        </DialogContent>
      </Portal>
    </DialogRoot>
  );
}

export const useGlobalSearchModal = () => {
  const { openModal, closeModal } = useModalManager();

  return {
    open: () =>
      openModal({
        component: <GlobalSearchModal closeModal={closeModal} />,
      }),
    close: closeModal,
  };
};

const NoResults = () => {
  return (
    <div className={styles.noResultsContainer}>
      <NoEntries className={styles.noResults} icon={<Library />}>
        <h1>Hello? Anyone there?</h1>
        <p>
          Bring more content or try a different search. You can also download our
          browser extension to capture content from anywhere on the web.
        </p>
        <div className={styles.buttons}>
          <NewMediaGroupDropdown
            align="start"
            triggerText="New item"
            variant="primary"
          />
          <Button
            onClick={() => window.open(BROWSER_EXT_URL)}
            size="sm"
            variant="secondary"
          >
            Get the browser extension
          </Button>
        </div>
      </NoEntries>
    </div>
  );
};

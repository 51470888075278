import { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { EditorContent, Extension, useEditor } from '@tiptap/react';
import type { Editor } from '@tiptap/core';
import Document from '@tiptap/extension-document';
import HardBreak from '@tiptap/extension-hard-break';
import Paragraph from '@tiptap/extension-paragraph';
import Placeholder from '@tiptap/extension-placeholder';
import Text from '@tiptap/extension-text';

import type { NamedPrompt } from '@spaceduck/api';
import { Icon16, Icon24 } from '@spaceduck/icons';

import { useWritingAssistance } from '@api/ai';
import { extensions as defaultExtensions } from '@components/detailsModal/tiptap/extensions';
import Button from '@ui/Button';
import ScrollArea from '@ui/ScrollArea';
import { css } from '@/lib/css';
import AIMenuInitial from './AIMenuInitial';
import AIMenuFollowUp from './AIMenuFollowUp';
import Typewriter from '@ui/Typewriter';
import { markdownToHtml } from '@utils/markdown';
import styles from './AIChatBox.module.scss';
import { toastApiErrorOr } from '@/api/util';

const { Send, StatusWarning } = Icon16;
const { Stop } = Icon24;

const handleWritingAssistanceError = (error: unknown) => {
  toastApiErrorOr(error, 'Error using AI writing assistant', {
    iconVariant: 'warning',
    titleText: 'Writing Assistance Error',
    bodyText:
      'An unknown error occurred while generating writing content. Please try again later',
  });
};

export default function AIChatBox({
  editor,
  width,
  mediaGroupId,
}: {
  editor: Editor;
  width?: number;
  mediaGroupId: string;
}) {
  const { doc, selection } = editor.state;
  const { from, to } = selection;
  const query = doc.textBetween(from, to);
  const prev = doc.textBetween(0, from);
  const {
    mutateAsync: writingAssistance,
    isPending: aiIsWriting,
    variables: writingAssistanceVariables,
  } = useWritingAssistance(mediaGroupId, { onError: handleWritingAssistanceError });
  const abortController = useRef<AbortController>();
  const containerRef = useRef<HTMLDivElement | null>(null);
  const sendButtonRef = useRef<HTMLButtonElement | null>(null);
  const inputField = useRef<HTMLInputElement | null>(null);
  const [hasPrompt, setHasPrompt] = useState(false);
  const [hasResult, setHasResult] = useState(false);
  const namedPromptRef = useRef<NamedPrompt>();
  const focusRef = useRef<ReturnType<typeof setTimeout> | undefined>(undefined);
  const resizeRef = useRef<ReturnType<typeof setTimeout> | undefined>(undefined);

  const resultsEditor = useEditor({
    extensions: defaultExtensions,
    editable: false,
  });

  const queryEditor = useResearchAssistantEditor({
    onSend: () => {
      sendButtonRef.current?.click();
      return true;
    },
    placeholder: hasResult
      ? 'Tell AI what to do next...'
      : 'Ask AI to edit or generate...',
  });

  const handleSend = async (ev?: React.MouseEvent) => {
    if (ev) {
      ev.preventDefault();
      ev.stopPropagation();
    }

    if (!resultsEditor || !queryEditor) return;

    abortController.current = new AbortController();
    const response = await writingAssistance({
      currentText: query,
      previousText: prev,
      prompt: queryEditor.getText(),
      signal: abortController.current.signal,
    });

    const html = await markdownToHtml(response.response);
    resultsEditor?.commands.setContent(
      html.replace(/\[\^([a-z0-9-]+)\]/gi, "<reference id='$1'/>")
    );
    setHasResult(true);
    queryEditor?.commands.setContent(null);
    queryEditor.setOptions();
  };

  const handleChoose = async (namedPrompt: NamedPrompt) => {
    namedPromptRef.current = namedPrompt;
    if (!(resultsEditor && queryEditor)) return;

    abortController.current = new AbortController();
    const response = await writingAssistance({
      currentText: query,
      previousText: prev,
      signal: abortController.current.signal,
      namedPrompt,
    });
    const html = await markdownToHtml(response.response);

    resultsEditor?.commands.setContent(
      html.replace(/\[\^([a-z0-9-]+)\]/gi, "<reference id='$1'/>")
    );

    resizeRef.current = setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 100);

    setHasResult(true);
    queryEditor?.commands.setContent(null);
    queryEditor.setOptions();
  };

  const abortGeneration = () => {
    abortController.current?.abort();
  };

  const retry = async () => {
    setHasResult(false);
    if (writingAssistanceVariables) {
      await writingAssistance(writingAssistanceVariables);
      setHasResult(true);
    }
  };

  const discardResult = () => {
    // TODO Discard results
    setHasResult(false);
  };

  useEffect(() => {
    return () => {
      abortGeneration();

      if (focusRef.current) clearTimeout(focusRef.current);
      if (resizeRef.current) clearTimeout(resizeRef.current);
    };
  }, []);

  useEffect(() => {
    setHasPrompt(!!queryEditor?.getText().trim());
  }, [queryEditor?.state.doc]);

  return (
    <div
      className={styles.container}
      style={width ? css({ '--width': `${width}px` }) : undefined}
    >
      <div className={styles.results}>
        <ScrollArea
          orientation="vertical"
          style={css({
            '--width': '100%',
            '--maxHeight': 'calc(50dvh - 70px)',
          })}
        >
          <EditorContent
            editor={resultsEditor}
            className={clsx(styles.output, !hasResult && styles.hidden)}
          />
        </ScrollArea>
      </div>
      <div className={styles.toolbar}>
        <div
          className={clsx(styles.menu, aiIsWriting && styles.isLoading)}
          ref={containerRef}
        >
          {hasResult ? (
            <AIMenuFollowUp
              container={containerRef.current}
              editor={editor}
              discard={discardResult}
              isLoading={aiIsWriting}
              resultsEditor={resultsEditor}
              retry={retry}
            />
          ) : (
            <AIMenuInitial
              container={containerRef.current}
              isLoading={aiIsWriting}
              handleChoose={handleChoose}
              setFocus={() => {
                focusRef.current = setTimeout(() => {
                  queryEditor?.view.dom.focus();
                }, 100);
              }}
            />
          )}
        </div>
        <div className={styles.prompt}>
          <EditorContent
            editor={queryEditor}
            className={clsx(
              styles.input,
              aiIsWriting && styles.hidden,
              hasResult && styles.hasResult
            )}
            innerRef={inputField}
            onKeyDown={(ev) => {
              if (ev.key === 'Enter') {
                handleSend();
              }
              queryEditor?.commands.scrollIntoView();
            }}
          />
          {aiIsWriting && (
            <div className={styles.status}>
              <Typewriter isLoadingState text="AI is writing..." timeout={25} />
            </div>
          )}
        </div>
        <div className={styles.actions}>
          {aiIsWriting ? (
            <Button
              className={styles.stopButton}
              onClick={abortGeneration}
              size="xs"
              type="button"
              variant="outlined"
            >
              <Stop size={16} />
            </Button>
          ) : (
            <Button
              className={styles.submitButton}
              disabled={!hasPrompt}
              onClick={handleSend}
              size="xs"
              type="button"
              variant="outlined"
            >
              <Send />
            </Button>
          )}
        </div>
      </div>
      <div className={clsx(styles.footer, !hasResult && styles.hidden)}>
        <StatusWarning size={12} />
        <p>AI can make mistakes. Check important info.</p>
      </div>
    </div>
  );
}

const promptExtensions = (props: ResearchAssistantTiptapProps) => {
  const extensions = [
    Document,
    Text,
    HardBreak,
    Placeholder.configure({
      placeholder: props.placeholder ?? '',
      showOnlyCurrent: false,
    }),
    Extension.create({
      name: 'OverrideEscape',
      addKeyboardShortcuts() {
        return {
          Escape: (props) => {
            props.editor.commands.clearContent();
            return false;
          },
          Enter: () => {
            (() => props.onSend())();
            return true;
          },
        };
      },
    }),
    Paragraph,
  ];
  return extensions;
};

type ResearchAssistantTiptapProps = {
  onSend: () => boolean;
  placeholder?: string;
};

export const useResearchAssistantEditor = (props: ResearchAssistantTiptapProps) => {
  const extensions = promptExtensions(props);
  const editor = useEditor({
    extensions,
    content: {
      type: 'doc',
      content: [
        {
          type: 'paragraph',
          content: [],
        },
      ],
    },
  });
  useEffect(() => {
    editor?.commands.focus('end');
  }, [editor]);
  return editor;
};

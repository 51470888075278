import { projectStatusSchema } from '@spaceduck/api';
import {
  isProject,
  type ListWorkspaceProjectsResponse,
  type ProjectDTO,
  type ProjectStackDTO,
  type ProjectStatus,
} from '@spaceduck/api';
import { Icon16, Icon24 } from '@spaceduck/icons';
import clsx from 'clsx';
import { Suspense, useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router';

import {
  activeProjectKeys,
  archivedProjectKeys,
  knownErrors,
  projectStatusColor,
  projectStatusOptions,
} from '@/const';
import { type IsCapable, isCapable } from '@api/util';
import { useWorkspaceProjects } from '@api/workspace';
import GhostTown from '@components/GhostTown';
import Head from '@components/Head';
import ProjectListItem, { ProjectStackListItem } from '@components/ProjectListItem';
import SidebarMenuToggle from '@components/SidebarMenuToggle';
import { useCreateProjectModal } from '@components/admin/CreateProjectsModal';
import EmptySpaces from '@components/projects/EmptySpaces';
import { useDebouncedValue } from '@hooks/useDebouncedValue';
import { useFindHotkey } from '@hooks/useShortcuts';
import useWorkspaceId from '@hooks/useWorkspaceId';
import sharedProjectStyles from '@pages/projects/Shared.module.scss';
import Breadcrumb from '@ui/Breadcrumb';
import breadcrumbStyles from '@ui/Breadcrumb.module.scss';
import Button from '@ui/Button';
import DropdownMenu, { MenuItem, Separator } from '@ui/DropdownMenu';
import { useForceUpgradeModal } from '@ui/ForceUpgradeModal';
import Spinner from '@ui/Spinner';
import styles from './Spaces.module.scss';
import { z } from 'zod';

const { Project: ProjectIcon } = Icon16;
const { Add, Close, Down, Empty, Search, Stack } = Icon24;

const CLOSED_STATUS_SET = new Set(['completed', 'canceled']);

function ProjectList({
  objects,
  canCreateProject,
}: {
  objects: Array<ProjectDTO | ProjectStackDTO>;
  canCreateProject: IsCapable;
}) {
  if (!objects.length) {
    return <NoProjects canCreateProject={canCreateProject} />;
  }
  return (
    <div className={styles.container}>
      <div className={styles.grid}>
        {objects.map((obj) => {
          if (isProject(obj)) {
            return <ProjectListItem key={obj.id} project={obj} stackMode="add" />;
          }
          return <ProjectStackListItem key={obj.id} stack={obj} />;
        })}
      </div>
    </div>
  );
}

export default function SpacesPage() {
  useForceUpgradeModal();
  const [searchParams, setSearchParams] = useSearchParams();
  const [showSearch, setShowSearch] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const debouncedSearchQuery = useDebouncedValue(searchQuery, 400);

  const statusFilterParseResult = projectStatusSchema
    .nullable()
    .safeParse(searchParams.get('status'));

  const onlyStacksParsed = z.literal('true').safeParse(searchParams.get('onlyStacks'));
  const onlyStacks = onlyStacksParsed.success;
  const statusFilter = statusFilterParseResult.success
    ? statusFilterParseResult.data
    : null;

  const toggleOnlyStacksFilter = (value: boolean) => {
    setSearchParams((params) => {
      if (!value) {
        params.delete('onlyStacks');
      } else {
        params.set('onlyStacks', 'true');
      }
      return params;
    });
  };

  const setStatusFilter = (value: ProjectStatus | null) => {
    setSearchParams((params) => {
      if (value === null) {
        params.delete('status');
      } else {
        params.set('status', value);
      }
      return params;
    });
  };

  const searchInputRef = useRef<HTMLInputElement>(null);
  const workspaceId = useWorkspaceId();
  const {
    data: projectListing,
    status: projectListingStatus,
    error: projectListingError,
    isLoading,
    isFetching,
    isRefetching,
  } = useWorkspaceProjects(workspaceId, {
    query: debouncedSearchQuery,
    status: statusFilter ? [statusFilter] : undefined,
    sort: 'open',
    onlyStacks,
  });

  const { open: openCreateProjectModal } = useCreateProjectModal({
    canCreateProject: isCapable('create', projectListing?.capabilities),
    redirectOnCreate: true,
  });

  const handleSearchClick = () => {
    if (!searchQuery) {
      setShowSearch((state) => !state);
      return;
    }
  };

  useFindHotkey((ev) => {
    ev.preventDefault();
    setShowSearch(true);
  });

  useEffect(() => {
    if (showSearch === true) {
      searchInputRef.current?.focus();
    }
  }, [showSearch]);

  if (projectListingStatus === 'error') {
    throw new Error(knownErrors.projectError, { cause: projectListingError });
  }

  const handleKeyDown = async (ev: React.KeyboardEvent<HTMLInputElement>) => {
    if (ev.key === 'Escape' && searchQuery.trim() === '') {
      setSearchQuery('');
      setShowSearch(false);
    }
  };

  const noResults =
    projectListing?.projects.length === 0 &&
    !searchQuery &&
    !debouncedSearchQuery &&
    !isLoading &&
    !isFetching &&
    !isRefetching &&
    !statusFilter;

  return (
    <>
      <Head title="Spaces" />
      <header className={breadcrumbStyles.headerBreadcrumbs}>
        <div className={sharedProjectStyles.sidebarMenuToggleWrapper}>
          <SidebarMenuToggle />
          <div className={sharedProjectStyles.divider} />
          <Breadcrumb
            breadcrumb={[
              {
                icon: <ProjectIcon />,
                text: 'Spaces',
              },
            ]}
            truncate={true}
          />
        </div>
        <Button
          className={sharedProjectStyles.createButton}
          iconBefore={<Add />}
          onClick={openCreateProjectModal}
          size="sm"
          variant="outlined"
        >
          New space
        </Button>
      </header>
      {!noResults && (
        <div className={styles.filters}>
          <div className={styles.status}>
            <DropdownMenu
              className={styles.statusMenu}
              isPadded
              triggerContent={
                <Button
                  className={styles.statusMenuTrigger}
                  iconAfter={<Down color="#F0F1F4" size={16} />}
                  iconBefore={
                    onlyStacks ? (
                      <Stack size={20} />
                    ) : (
                      <Empty
                        color={
                          statusFilter
                            ? projectStatusColor[statusFilter]?.color
                            : projectStatusColor['none' as ProjectStatus]?.color
                        }
                        fill={
                          statusFilter && projectStatusColor[statusFilter]?.isFilled
                            ? projectStatusColor[statusFilter]?.color
                            : undefined
                        }
                        size={20}
                      />
                    )
                  }
                  variant="secondary"
                >
                  {onlyStacks && 'Stacks'}
                  {!onlyStacks &&
                    (statusFilter ? projectStatusOptions[statusFilter] : 'Status')}
                </Button>
              }
            >
              {[...activeProjectKeys, ...archivedProjectKeys].map((status) => {
                const color = projectStatusColor[status]?.color;
                const fill = projectStatusColor[status]?.isFilled ? color : undefined;

                return (
                  <MenuItem
                    className="noHoverIcon"
                    iconBefore={<Empty color={color} fill={fill} size={20} />}
                    onClick={() => {
                      const result = projectStatusSchema.safeParse(status);
                      const newValue = result.success ? result.data : null;
                      if (!result.success) {
                        console.error('Unknown space status', { status });
                      }
                      setStatusFilter(newValue);
                      toggleOnlyStacksFilter(false);
                    }}
                    key={status}
                  >
                    {projectStatusOptions[status]}
                  </MenuItem>
                );
              })}
              <MenuItem
                onClick={() => {
                  setStatusFilter(null);
                  toggleOnlyStacksFilter(true);
                }}
                iconBefore={<Stack size={20} />}
              >
                Stacks
              </MenuItem>
              <Separator color={2} orientation="vertical" />
              <MenuItem
                onClick={() => {
                  setStatusFilter(null);
                  toggleOnlyStacksFilter(false);
                }}
              >
                Remove filter
              </MenuItem>
            </DropdownMenu>
          </div>
          <div className={styles.search}>
            <div className={clsx(styles.searchBox, showSearch && styles.expanded)}>
              <Button
                className={styles.filterTrigger}
                isSquare
                onClick={handleSearchClick}
                size="sm"
                type="button"
                variant="outlined"
              >
                {!searchQuery && showSearch ? (
                  <Close size={18} />
                ) : (
                  <Search size={16} />
                )}
              </Button>
              <div className={styles.searchField}>
                <input
                  onChange={(ev) => setSearchQuery(ev.target.value)}
                  onKeyDown={handleKeyDown}
                  placeholder="Search spaces..."
                  ref={searchInputRef}
                  type="search"
                  value={searchQuery}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      <div className={styles.results}>
        {projectListingStatus === 'pending' && <Loading />}
        {projectListingStatus === 'success' && !noResults && (
          <Content listing={projectListing} />
        )}
        {projectListingStatus === 'success' && noResults && (
          <Suspense fallback={<Spinner />}>
            <EmptySpaces openCreateProjectModal={openCreateProjectModal} />
          </Suspense>
        )}
      </div>
    </>
  );
}

const Loading = () => <div className={styles.container}>Loading...</div>;

type ContentProps = {
  listing: ListWorkspaceProjectsResponse;
};

const Content = ({ listing }: ContentProps) => {
  const canCreateProject = isCapable('create', listing.capabilities);
  const openProjects = listing.projects.filter((ps) =>
    isProject(ps) ? !CLOSED_STATUS_SET.has(ps.status) : ps.isOpen
  );
  const closedProjects = listing.projects.filter((ps) =>
    isProject(ps) ? CLOSED_STATUS_SET.has(ps.status) : !ps.isOpen
  );
  if (listing.projects.length === 0) {
    return <NoProjects canCreateProject={canCreateProject} />;
  }

  return (
    <>
      {openProjects.length > 0 && (
        <ProjectList objects={openProjects} canCreateProject={canCreateProject} />
      )}
      {closedProjects.length > 0 && (
        <>
          {closedProjects.length > 0 && (
            <div className={styles.closedHeader}>
              <span>Closed</span>
            </div>
          )}
          <ProjectList objects={closedProjects} canCreateProject={canCreateProject} />
        </>
      )}
    </>
  );
};

const NoProjects = ({ canCreateProject }: { canCreateProject: IsCapable }) => {
  const { open: openCreateProjectModal } = useCreateProjectModal({
    canCreateProject: canCreateProject,
    redirectOnCreate: true,
  });
  return (
    <GhostTown
      description="Jump-start this blank slate with a brand spanking new space and
      collaborate with your team."
      ctaText="New space"
      onCtaClick={openCreateProjectModal}
    />
  );
};

import { useShallow } from 'zustand/shallow';

import { getUrl } from '@detailsModal/DetailsModalSidebar';
import { useNavigateWithState } from '@hooks/useNavigateWithState';
import { useDetailsModalStore } from '@stores/useDetailsModalStore';
import { useCallback } from 'react';

export const useCardUtils = () => {
  const navigateWithState = useNavigateWithState();
  const { openDrawer, setQuickViewMediaGroupId } = useDetailsModalStore(
    useShallow((store) => ({
      openDrawer: store.openDrawer,
      setQuickViewMediaGroupId: store.setQuickViewMediaGroupId,
    }))
  );

  const openCardInSidebar = useCallback(
    ({
      cardMediaGroupId,
      boardMediaGroupId,
    }: {
      cardMediaGroupId?: string;
      boardMediaGroupId?: string;
    }) => {
      if (!(cardMediaGroupId && boardMediaGroupId)) return;

      const url = getUrl('browseRepository', boardMediaGroupId);
      navigateWithState(url);
      openDrawer();
      setQuickViewMediaGroupId(cardMediaGroupId);
    },
    [navigateWithState, openDrawer, setQuickViewMediaGroupId]
  );

  return {
    openCardInSidebar,
  };
};

import { type RefObject, useEffect, useState } from 'react';
import * as Popover from '@radix-ui/react-popover';

import type { MediaGroupDTO } from '@spaceduck/api';

import { useProject } from '@api/project';
import MediaGroupPreviewList from '@components/MediaGroupPreviewList';

type MediaGroupSourcePopupProps = {
  inputRef: RefObject<HTMLInputElement>;
  searchTitle: string;
  handleSelectItem: (item: MediaGroupDTO) => void;
  onEscapeKeyDown?: () => void;
  excludeMediaGroupIds: string[];
  projectId: string;
};

export default function MediaGroupSourcePopup(props: MediaGroupSourcePopupProps) {
  const [onKeyDown, setOnKeyDown] = useState<(event: KeyboardEvent) => boolean>();
  const { inputRef, searchTitle, handleSelectItem, onEscapeKeyDown, projectId } = props;
  const { data: projectData } = useProject(projectId);
  const [open, setOpen] = useState(true);

  useEffect(() => {
    if (onKeyDown) {
      inputRef.current?.addEventListener('keydown', onKeyDown);
      return () => inputRef.current?.removeEventListener('keydown', onKeyDown);
    }
  }, [onKeyDown, inputRef.current]);

  return (
    <Popover.Root open={open}>
      <Popover.Anchor />
      <Popover.Portal>
        <Popover.Content
          onEscapeKeyDown={onEscapeKeyDown}
          align="start"
          onOpenAutoFocus={(e) => {
            e.preventDefault();
            inputRef?.current?.focus();
          }}
        >
          <MediaGroupPreviewList
            searchTitle={searchTitle}
            handleSelectItem={handleSelectItem}
            setOnKeyDown={setOnKeyDown}
            defaultFilters={{
              contentTypeNot: ['video', 'audio', 'board'], // TODO: Support videos
              project: [projectId],
            }}
            excludeMediaGroupIds={props.excludeMediaGroupIds}
            spaceLabel={projectData?.project.label}
            closeParentPopup={() => setOpen(false)}
          />
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
}

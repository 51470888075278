import { Handle as FlowHandle, type HandleProps } from '@xyflow/react';
import clsx from 'clsx';
import { useShallow } from 'zustand/shallow';

import { useBoardStore } from '../hooks/useBoardStore';
import styles from './Handle.module.scss';

export default function Handle(props: HandleProps) {
  const { className, ...handleProps } = props;
  const { isDraggingHandle } = useBoardStore(
    useShallow((state) => ({
      isDraggingHandle: state.isDraggingHandle,
    }))
  );

  return (
    <FlowHandle
      {...handleProps}
      className={clsx(styles.handle, isDraggingHandle && styles.enlarge, className)}
    />
  );
}

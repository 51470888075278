import { Icon24 } from '@spaceduck/icons';
import clsx from 'clsx';
import { Outlet } from 'react-router';
import { Link } from 'react-router';

import { urlFor } from '@/urls';
import stars from '@assets/img/stars.png';
import AuthWrapper from '@components/AuthWrapper';
import styles from './Stars.module.scss';

const { Left } = Icon24;

export default function StarsLayout({
  includeBacklink,
  withAuth,
}: {
  includeBacklink?: boolean;
  withAuth?: boolean;
}) {
  if (withAuth) {
    return (
      <AuthWrapper>
        <OuterSpace includeBacklink={includeBacklink}>
          <Outlet />
        </OuterSpace>
      </AuthWrapper>
    );
  }

  return (
    <OuterSpace includeBacklink={includeBacklink}>
      <Outlet />
    </OuterSpace>
  );
}

export const OuterSpace = ({
  includeBacklink,
  children,
  className,
  light = true,
}: {
  includeBacklink?: boolean;
  children: React.ReactNode;
  className?: string;
  light?: boolean;
}) => {
  return (
    <div className={clsx(styles.world, className)}>
      {includeBacklink && (
        <div className={styles.backLink}>
          <Link to={urlFor('home')}>
            <Left />
            Back to Spaceduck
          </Link>
        </div>
      )}
      {light && (
        <div className={styles.lightWrapper}>
          <div className={styles.light} />
        </div>
      )}
      <div className={styles.starsWrapper}>
        <img src={stars} className={styles.stars} width={946} height={586} />
      </div>
      <main>{children}</main>
    </div>
  );
};

import { useEffect } from 'react';
import { useReactFlow, type NodeProps } from '@xyflow/react';

import { useMediaGroupDetail } from '@api/mediaGroup';
import Spinner from '@ui/Spinner';
import type { UnknownNodeType } from '../types/board';
import { useHistory } from '../hooks/useHistory';
import { useNodes } from '../hooks/useNodes';

export const UnknownNode = (props: NodeProps<UnknownNodeType>) => {
  const { getNode } = useReactFlow();
  const node = getNode(props.id);
  const { data, isLoading, isError } = useMediaGroupDetail(props.data.mediaGroupId);
  const contentType = data?.mediaGroup.contentType;
  const mediaGroupKind = data?.mediaGroup.kind;
  const {
    addArticleNode,
    addAudioNode,
    addImageNode,
    addVideoNode,
    addPdfNode,
    addFileNode,
  } = useNodes();
  const { flowToScreenPosition, setNodes } = useReactFlow();
  const { replaceHistoryOfUnknownNode } = useHistory();

  useEffect(() => {
    if (contentType && node) {
      const id = props.data.mediaGroupId;
      const { x, y } = flowToScreenPosition(node.position);

      let newNode = null;

      if (
        contentType === 'article' ||
        contentType === 'bookmark' ||
        contentType === 'social' ||
        contentType === 'wiki'
      ) {
        newNode = addArticleNode(id, x, y, {
          appendNode: false,
          parentId: node.parentId,
        });
      }

      if (contentType === 'image') {
        newNode = addImageNode(id, x, y, {
          appendNode: false,
          parentId: node.parentId,
        });
      }

      if (contentType === 'video') {
        newNode = addVideoNode(id, x, y, {
          appendNode: false,
          parentId: node.parentId,
        });
      }

      if (contentType === 'pdf') {
        newNode = addPdfNode(id, x, y, { appendNode: false, parentId: node.parentId });
      }

      if (contentType === 'file') {
        newNode = addFileNode(id, x, y, { appendNode: false, parentId: node.parentId });
      }

      if (contentType === 'audio') {
        if (mediaGroupKind === 'bookmark') {
          newNode = addArticleNode(id, x, y, { appendNode: false });
        } else {
          newNode = addAudioNode(id, x, y, { appendNode: false });
        }
      }
      if (!newNode) return;

      const replacementNode = {
        ...newNode,
        data: { ...newNode.data, expanded: true },
      };

      setNodes((nodes) =>
        nodes.map((existingNode) =>
          existingNode.id === node.id ? replacementNode : existingNode
        )
      );

      Promise.resolve().then(() => {
        replaceHistoryOfUnknownNode(replacementNode);
      });
    }
  }, [contentType, node, mediaGroupKind]);

  if (isLoading || isError) {
    return (
      <div>
        <Spinner />
      </div>
    );
  }

  return <div>{data?.mediaGroup.id}</div>;
};

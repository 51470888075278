import { useCallback, useEffect, useRef } from 'react';
import Spinner from '../ui/Spinner';
import sourceStyles from './InlineSource.module.scss';
import { useSourcesStore, type SelectedSourceType } from './useSourcesStore';
import useWorkspaceId from '@/hooks/useWorkspaceId';
import { type ProcessingResult, useProcessAssets } from '@/hooks/useProcessAssets';
import { useCreateTemporaryFile } from '@/api/ai';
import { toastApiErrorOr } from '@/api/util';
import Tag from '../ui/Tag';
import { useShallow } from 'zustand/shallow';

export const FileUpload = ({
  onTempFileUploaded,
  onDelete,
  fileKey,
  fileName,
}: {
  onTempFileUploaded?: (source: SelectedSourceType, fileKey?: string) => void;
  onDelete?: (fileKey?: string) => void;
  fileKey: string;
  fileName: string;
}) => {
  const { files, takeFile } = useSourcesStore(
    useShallow((state) => ({ files: state.files, takeFile: state.takeFile }))
  );
  const workspaceId = useWorkspaceId();
  const { mutateAsync: uploadFile } = useCreateTemporaryFile();
  const lastRun = useRef<string | null>(null);

  const onSuccess = useCallback(
    async (item: ProcessingResult) => {
      if (!item.request.result?.id) {
        return;
      }
      let tempFile: Awaited<ReturnType<typeof uploadFile>>;

      try {
        tempFile = await uploadFile({
          assetId: item.request.result.id,
        });
      } catch (error) {
        toastApiErrorOr(error, 'Failed to handle file upload', {
          iconVariant: 'warning',
          titleText: 'File upload Failed',
          bodyText:
            'An unknown error occurred while uploading file. Please try again later',
        });
        return onDelete?.(fileKey);
      }

      try {
        onTempFileUploaded?.(
          {
            label: tempFile.file.filename,
            type: 'file',
            id: tempFile.file.id,
          },
          fileKey
        );
      } catch (e) {
        return onDelete?.(fileKey);
      }
    },
    [onTempFileUploaded, onDelete]
  );

  const { insert } = useProcessAssets({ onSuccess, pollInterval: 1000 });

  useEffect(() => {
    const file = takeFile(fileKey);
    if (file && workspaceId) {
      // Prevent firing twice
      const { webkitRelativePath, name } = file;
      if (lastRun.current === `${webkitRelativePath}${name}`) return;

      insert({
        files: [file],
        workspaceId,
      });
      lastRun.current = `${webkitRelativePath}${name}`;
    }
  }, [files]);

  return (
    <Tag
      className={sourceStyles.selectedSource}
      onRemoveClick={() => onDelete?.(fileKey)}
      removeIconIsHidden
      size="lg"
      variant="quaternary"
    >
      {' '}
      <Spinner size={20} />
      <span className={sourceStyles.label}>{fileName}</span>
    </Tag>
  );
};

import {
  type MediaGroupContentType,
  type MediaGroupFiltersSchema,
  type MediaGroupSearchFilterProperties,
  type MediaGroupSuggestionFilterProperties,
  type SearchSuggestionDTO,
  contentTypeSchema,
} from '@spaceduck/api';
import clsx from 'clsx';
import { capitalize, debounce } from 'lodash';
import { type ReactNode, type RefObject, useCallback, useState } from 'react';

import { useGetSearchSuggestions } from '@api/mediaGroup';
import { ContentType } from '@components/icons';
import { useSearch } from '@hooks/useSearch';
import useWorkspaceId from '@hooks/useWorkspaceId';
import { FilterIcon } from '@icons/FilterIcon';
import MediaGroupStatusIcon from '@icons/MediaGroupStatus';
import InputDropDownPopover, { InputPopoverMenuItem } from '@ui/InputDropdownMenu';
import styles from './SearchSuggestions.module.scss';
import UserAvatar from './ui/UserAvatar';

type SearchProps = {
  excludeProjectLibraries: boolean;
  mediaGroupFilters: MediaGroupFiltersSchema;
};

type SearchSuggestionsBaseProps = {
  onSelect: (suggestion: SearchSuggestionDTO) => void;
  inputRef: RefObject<HTMLElement>;
  filter: MediaGroupSuggestionFilterProperties;
  query: string;
  active: boolean;
  defaultFilters?: MediaGroupFiltersSchema;
  onPreview: (value: string) => void;
  onEscapeKeyDown?: () => void;
};

type GlobalSearchSuggestionProps = {
  isLocalSearch?: false;
  localSearchProps?: null;
};

type LocalSearchSuggestionProps = {
  isLocalSearch: true;
  localSearchProps: SearchProps;
};

type SearchSuggestionsProps = SearchSuggestionsBaseProps &
  (GlobalSearchSuggestionProps | LocalSearchSuggestionProps);

const SearchSuggestionItemComponent = (
  props: {
    label: string;
    active: boolean;
  } & (
    | {
        filter: Exclude<
          MediaGroupSearchFilterProperties,
          'project' | 'author' | 'status'
        >;
      }
    | {
        filter: 'project';
        isPrivate: boolean;
      }
    | {
        filter: 'author';
        avatarUrl: string | null;
      }
    | { filter: 'status'; color: string | undefined }
  )
) => {
  let label = props.label;
  let icon: ReactNode;
  if (props.filter === 'project') {
    icon = (
      <FilterIcon
        filter={props.filter}
        isPrivate={props.isPrivate}
        size={20}
        variant="suggestion"
      />
    );
  } else if (props.filter === 'status') {
    icon = (
      <MediaGroupStatusIcon
        status={{ color: props.color, label: props.label, id: props.label }}
        size={20}
      />
    );
    label = props.label;
  } else if (props.filter === 'contentType') {
    const contentType = contentTypeSchema.safeParse(props.label);
    label = capitalize(props.label);
    icon = (
      <ContentType
        contentType={
          contentType.success ? contentType.data : ('mixed' as MediaGroupContentType)
        }
        size={20}
      />
    );
  } else if (props.filter === 'author') {
    icon = <UserAvatar name={props.label} size="xs" imageUrl={props.avatarUrl} />;
  } else {
    icon = <FilterIcon filter={props.filter} size={20} variant="suggestion" />;
  }
  return (
    <div className={clsx(styles.suggestionItem, props.active ? styles.active : '')}>
      <div className={styles.suggestionIcon}>{icon}</div>
      {label || '(Empty)'}
    </div>
  );
};

const SearchSuggestions = (props: SearchSuggestionsProps) => {
  if (props.isLocalSearch && props.localSearchProps) {
    return <LocalSearchSuggestions {...props} />;
  }

  return <GlobalSearchSuggestions {...props} />;
};

const LocalSearchSuggestions = (
  props: SearchSuggestionsBaseProps & LocalSearchSuggestionProps
) => {
  const { mediaGroupFilters, excludeProjectLibraries } = props.localSearchProps;
  const searchProps = { mediaGroupFilters, excludeProjectLibraries };

  return <SearchSuggestionsComponent {...props} {...searchProps} />;
};

const GlobalSearchSuggestions = (
  props: SearchSuggestionsProps & GlobalSearchSuggestionProps
) => {
  const { mediaGroupFilters, excludeProjectLibraries } = useSearch();
  const searchProps = { mediaGroupFilters, excludeProjectLibraries };

  return <SearchSuggestionsComponent {...props} {...searchProps} />;
};

const SearchSuggestionsComponent = ({
  active,
  defaultFilters,
  filter,
  inputRef,
  onEscapeKeyDown,
  onPreview,
  onSelect,
  query,
  mediaGroupFilters,
  excludeProjectLibraries,
}: SearchSuggestionsProps & SearchProps) => {
  const workspaceId = useWorkspaceId();
  const [highlightedIndex, setHighlightedIndex] = useState<number>();
  const [debouncedQuery, setDebouncedQuery] = useState('');
  const debounceSetQuery = useCallback(
    debounce(async (value: string) => {
      setDebouncedQuery(value);
    }, 500),
    []
  );
  const { data: suggestionsData, isLoading } = useGetSearchSuggestions(filter, {
    ...mediaGroupFilters,
    ...(defaultFilters || {}),
    workspace: workspaceId,
    query: debouncedQuery,
    excludeProjectLibraries,
  });

  debounceSetQuery(query);
  return (
    <InputDropDownPopover
      className={styles.suggestions}
      numberOfItems={suggestionsData?.suggestions?.length || 0}
      open={active}
      inputRef={inputRef}
      onSelectIndex={(index) => {
        const suggestion = suggestionsData?.suggestions?.[index];
        if (suggestion !== undefined) {
          onSelect(suggestion);
        }
      }}
      isLoading={isLoading}
      onHighlightedIndexChange={setHighlightedIndex}
      onIndexPreview={(index: number) => {
        const suggestion = suggestionsData?.suggestions?.[index];
        if (suggestion !== undefined) onPreview(suggestion.label);
      }}
      onMouseDown={(e) => {
        e.preventDefault();
      }}
      onEscapeKeyDown={onEscapeKeyDown}
    >
      {!isLoading &&
        suggestionsData?.suggestions.map((item, index) => {
          let suggestionItem: ReactNode;
          if (item.filter === 'author') {
            suggestionItem = (
              <SearchSuggestionItemComponent
                label={item.label}
                active={highlightedIndex === index}
                avatarUrl={item.avatarUrl}
                filter={item.filter}
              />
            );
          } else if (item.filter === 'project') {
            suggestionItem = (
              <SearchSuggestionItemComponent
                label={item.label}
                active={highlightedIndex === index}
                isPrivate={item.isPrivate}
                filter={item.filter}
              />
            );
          } else if (item.filter === 'status') {
            suggestionItem = (
              <SearchSuggestionItemComponent
                label={item.label}
                active={highlightedIndex === index}
                color={item.color}
                filter={item.filter}
              />
            );
          } else {
            suggestionItem = (
              <SearchSuggestionItemComponent
                label={item.label}
                active={highlightedIndex === index}
                filter={item.filter}
              />
            );
          }
          return (
            <InputPopoverMenuItem<SearchSuggestionDTO>
              key={index}
              onSelect={onSelect}
              suggestion={item}
              onMouseOver={() => {
                setHighlightedIndex(index);
              }}
            >
              {suggestionItem}
            </InputPopoverMenuItem>
          );
        })}
    </InputDropDownPopover>
  );
};

export default SearchSuggestions;

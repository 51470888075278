import { Icon24, type IconProps } from '@spaceduck/icons';

import type { ProjectFeature as ProjectFeatureType } from '@/types';

const { List, ResearchChatAI, RepositoryBrowse, Workflow } = Icon24;

type ProjectFeatureProps = {
  feature: ProjectFeatureType;
} & IconProps;

export default function ProjectFeature(projectFeatureIconProps: ProjectFeatureProps) {
  const { feature, size, ...iconProps } = projectFeatureIconProps;

  switch (feature) {
    case 'researchAssistant':
      return <ResearchChatAI {...iconProps} size={size ?? 16} />;
    case 'category':
      return <List {...iconProps} size={size ?? 16} />;
    case 'library':
      return <RepositoryBrowse {...iconProps} size={size ?? 16} />;
    case 'progress':
      return <Workflow {...iconProps} size={size ?? 16} />;
    default:
      return null;
  }
}

import { clsx } from 'clsx';
import * as RadixRadioGroup from '@radix-ui/react-radio-group';
import { type ReactNode, useId, useMemo } from 'react';
import { upperFirst } from 'lodash';

import styles from './RadioGroup.module.scss';

export type RadioGroupOption<T extends string = string> = {
  children: ReactNode;
  description?: string;
  value: T;
};

type RadioGroupProps<T extends string = string> = {
  children?: React.ReactNode;
  className?: string;
  defaultValue?: string;
  onValueChange?: (value: T) => void;
  label?: string;
  options?: RadioGroupOption<T>[];
  layout?: 'standard' | 'pill' | 'banner';
} & Omit<RadixRadioGroup.RadioGroupProps, 'onValueChange'>;

export default function RadioGroup<T extends string>({
  children,
  className,
  defaultValue,
  options,
  onValueChange,
  layout = 'standard',
  ...rootProps
}: RadioGroupProps<T>) {
  const handleValueChange = useMemo(
    () =>
      onValueChange
        ? (value: string) => {
            onValueChange(value as T);
          }
        : undefined,
    [onValueChange]
  );

  if (!(options?.length || children)) return null;

  return (
    <RadixRadioGroup.Root
      {...rootProps}
      className={clsx(
        styles.radioGroup,
        styles[`layout${upperFirst(layout)}`],
        className
      )}
      defaultValue={defaultValue}
      onValueChange={handleValueChange}
    >
      {options?.map((option) => (
        <RadioGroupItem key={option.value} {...option} />
      ))}
      {children}
    </RadixRadioGroup.Root>
  );
}

type RadioGroupItem = {
  className?: string;
  description?: string;
  children: ReactNode;
  value: string;
} & RadixRadioGroup.RadioGroupItemProps;

export const RadioGroupItem = ({
  className,
  description,
  children,
  value,
  ...itemProps
}: RadioGroupItem) => {
  const id = useId();

  return (
    <div className={clsx(styles.radioGroupItem, className)}>
      <label className={styles.radioGroupItemLabel} htmlFor={id}>
        {children}
      </label>
      <RadixRadioGroup.Item
        {...itemProps}
        className={styles.radioGroupItemControl}
        id={id}
        value={value}
      >
        <RadixRadioGroup.Indicator className={styles.radioGroupItemIndicator} />
      </RadixRadioGroup.Item>
      {description && (
        <div className={styles.radioGroupItemDescription}>{description}</div>
      )}
    </div>
  );
};

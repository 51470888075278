import { useWorkspaceStore } from '@/stores/useWorkspaceStore';
import { useShallow } from 'zustand/shallow';

const useWorkspaceId = () => {
  const { fallbackWorkspaceId, focusedWorkspaceId, sessionWorkspaceId } =
    useWorkspaceStore(
      useShallow((state) => ({
        fallbackWorkspaceId: state.fallbackWorkspaceId,
        focusedWorkspaceId: state.focusedWorkspaceId,
        sessionWorkspaceId: state.sessionWorkspaceId,
      }))
    );
  return focusedWorkspaceId ?? sessionWorkspaceId ?? fallbackWorkspaceId;
};

export default useWorkspaceId;

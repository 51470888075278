import { Icon16 } from '@spaceduck/icons';
import { useNavigate } from 'react-router';
import { useCallback } from 'react';

import { useBackgroundLocation } from '@hooks/useBackgroundLocation';
import useWorkspaceId from '@hooks/useWorkspaceId';
import { urlFor } from '@/urls';
import CreateItemMenu from './ContentManagementSection';
import styles from './SearchSection.module.scss';

const { Search } = Icon16;

export default function SearchSection() {
  const workspaceId = useWorkspaceId();
  const navigate = useNavigate();
  const searchUrl = workspaceId ? urlFor('globalSearch', { workspaceId }) : null;
  const backgroundLocation = useBackgroundLocation();
  const currentLocation = `${location.pathname}${location.search}${location.hash}`;

  const clickHandler = useCallback(() => {
    if (searchUrl) {
      navigate(searchUrl, {
        state: {
          backgroundLocation: [currentLocation, backgroundLocation],
        },
      });
    }
  }, [searchUrl, currentLocation, backgroundLocation]);

  return (
    <div className={styles.container}>
      <button className={styles.button} onClick={clickHandler} type="button">
        <Search className={styles.iconLeft} />
        <span className={styles.text}>Global Search</span>
      </button>
      <CreateItemMenu />
    </div>
  );
}

import type { LinkFieldInstance } from '@spaceduck/api';
import { Icon16, Icon24 } from '@spaceduck/icons';
import clsx from 'clsx';
import { useRef, useState } from 'react';

import { useOnClickOutside } from '@hooks/useOnClickOutside';
import Button from '@ui/Button';
import type { CommonProps } from '../InfoCategories';
import styles from './Email.module.scss';
import Empty from './Empty';

const { Add } = Icon16;
const { TrashCan } = Icon24;

export default function EmailField(commonProps: CommonProps) {
  const { instances, updateField } = commonProps;
  const [showCreateEntry, setShowCreateEntry] = useState(false);
  const emailInstances = instances as LinkFieldInstance[];

  const handleUpdate = async (
    instance: LinkFieldInstance | null,
    instanceId?: string
  ) => {
    if (!instanceId && instance) {
      // Add
      await updateField?.([...instances, instance]);
    } else if (instanceId && !instance) {
      // Remove
      await updateField?.(
        instances.filter(
          (existingInstance) => existingInstance?.instanceId !== instanceId
        )
      );
    } else {
      // Edit
      await updateField?.(
        instances.map((existingInstance) =>
          existingInstance?.instanceId === instanceId ? instance : existingInstance
        )
      );
    }
  };

  const addEntry = () => {
    setShowCreateEntry(true);
  };

  return (
    <div className={styles.container}>
      <Button
        className={styles.addButton}
        onClick={addEntry}
        size="xs"
        title="Add a new entry"
        variant="icon"
      >
        <Add size={10} />
      </Button>
      {emailInstances.length
        ? emailInstances.map((textInstance, idx) => {
            return (
              <EmailEntry
                key={idx}
                textInstance={textInstance}
                updateField={handleUpdate}
              />
            );
          })
        : !showCreateEntry && (
            <div className={styles.hoverBg}>
              <Empty onClick={addEntry} />
            </div>
          )}
      {showCreateEntry && (
        <EmailEntry
          onHide={() => setShowCreateEntry(false)}
          showInputOnRender={true}
          textInstance={{ url: '' }}
          updateField={handleUpdate}
        />
      )}
    </div>
  );
}

const EmailEntry = ({
  onHide,
  showInputOnRender = false,
  textInstance,
  updateField,
}: {
  onHide?: () => void;
  showInputOnRender?: boolean;
  textInstance: LinkFieldInstance;
  updateField?: (
    instance: LinkFieldInstance | null,
    instanceId?: string
  ) => Promise<void>;
}) => {
  const _localValueUrl = useRef(textInstance?.url ?? '');
  const [localValueUrl, setLocalValueUrl] = useState(textInstance?.url ?? '');
  const [shouldShowEditView, setShouldShowEditView] = useState(showInputOnRender);

  const updateWithCheck = async () => {
    if (
      textInstance.url !== _localValueUrl.current &&
      !(!textInstance.instanceId && !_localValueUrl.current)
    ) {
      await updateField?.(
        _localValueUrl.current
          ? {
              ...textInstance,
              url: _localValueUrl.current,
              text: '',
            }
          : null,
        textInstance.instanceId
      );
    }
    onHide?.();
    setShouldShowEditView(false);
  };

  const deleteEntry = async () => {
    if (textInstance.instanceId) {
      await updateField?.(null, textInstance.instanceId);
    }
    onHide?.();
    setShouldShowEditView(false);
  };

  const { containerRef } = useOnClickOutside<HTMLDivElement>({
    callback: updateWithCheck,
  });

  const handleKeyDown = async (ev: React.KeyboardEvent) => {
    if (ev.key === 'Escape') {
      ev.preventDefault();
      ev.stopPropagation();
      setShouldShowEditView(false);
    } else if (ev.key === 'Enter') {
      updateWithCheck();
    }
  };

  const handleChangeUrl = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = ev.currentTarget;
    _localValueUrl.current = value;
    setLocalValueUrl(value);
  };

  return (
    <div className={clsx(styles.wrapper)}>
      {shouldShowEditView ? (
        <div ref={containerRef}>
          <div className={styles.entry}>
            <input
              autoComplete="off"
              // biome-ignore lint/a11y/noAutofocus: non-disruptive and within context
              autoFocus
              className={styles.textarea}
              onChange={handleChangeUrl}
              onKeyDown={handleKeyDown}
              placeholder="Email address"
              type="email"
              value={localValueUrl}
            />
            <Button
              className={styles.removeButton}
              onClick={deleteEntry}
              size="xs"
              title="Remove"
              variant="icon"
            >
              <TrashCan size={16} />
            </Button>
          </div>
        </div>
      ) : (
        <div className={styles.hoverBg}>
          <div className={styles.overflowHidden}>
            <div
              className={styles.truncated}
              onClick={() => setShouldShowEditView(true)}
            >
              <a
                className={styles.email}
                href={`mailto:${localValueUrl}`}
                onClick={(ev) => ev.stopPropagation()}
              >
                {localValueUrl}
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

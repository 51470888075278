import type { Editor } from '@tiptap/core';
import type { Node } from '@xyflow/react';
import { upperFirst } from 'lodash';

import { Icon16 } from '@spaceduck/icons';

import { handleTransformation } from '@detailsModal/tiptap/transformations';
import { colors, getColorOption } from '@detailsModal/tiptap/SelectionMenu';
import Menu from '@ui/Menu';

const {
  Bold,
  BulletedList,
  CodeBlock,
  CodeInline,
  Color,
  FrameDiagram,
  Heading1,
  Heading2,
  Heading3,
  Heading4,
  Heading5,
  Heading6,
  Italics,
  NumberedList,
  Quote,
  ShapeColor,
  Strikethrough,
  TextT,
  TodoList,
  Underline,
} = Icon16;

export default function FloatingTextSelectionMenu({
  editor,
  node,
  updateNode,
}: {
  editor: Editor | null;
  node?: Node;
  updateNode: (patch: {
    className?: string;
    height?: number;
    data?: { autoHeightDisabled?: boolean; boxColor?: string };
  }) => void;
}) {
  const detailsModalContent = document.querySelector('#tiptapSelectionMenuRoot');

  if (!(editor && detailsModalContent) || !editor.isEditable) return null;
  const autoHeightDisabled = node?.data?.autoHeightDisabled;

  return (
    <div>
      <Menu
        menuItems={[
          {
            icon: <TextT />,
            title: 'Format',
            tooltip: 'Format',
            menuItems: [
              {
                label: 'Text',
                icon: <TextT />,
                onClick: () => handleTransformation(editor, 'text'),
              },
              {
                label: 'Heading 1',
                icon: <Heading1 />,
                onClick: () => handleTransformation(editor, 'heading1'),
              },
              {
                label: 'Heading 2',
                icon: <Heading2 />,
                onClick: () => handleTransformation(editor, 'heading2'),
              },
              {
                label: 'Heading 3',
                icon: <Heading3 />,
                onClick: () => handleTransformation(editor, 'heading3'),
              },
              {
                label: 'Heading 4',
                icon: <Heading4 />,
                onClick: () => handleTransformation(editor, 'heading4'),
              },
              {
                label: 'Heading 5',
                icon: <Heading5 />,
                onClick: () => handleTransformation(editor, 'heading5'),
              },
              {
                label: 'Heading 6',
                icon: <Heading6 />,
                onClick: () => handleTransformation(editor, 'heading6'),
              },
              {
                label: 'Inline code',
                icon: <CodeInline />,
                onClick: () => handleTransformation(editor, 'inlineCode'),
              },
              {
                label: 'Code block',
                icon: <CodeBlock />,
                onClick: () => handleTransformation(editor, 'codeBlock'),
              },
              {
                label: 'Quote',
                icon: <Quote />,
                onClick: () => handleTransformation(editor, 'quote'),
              },
              {
                label: 'Bold',
                icon: <Bold />,
                onClick: () => handleTransformation(editor, 'bold'),
              },
              {
                label: 'Strikethrough',
                icon: <Strikethrough />,
                onClick: () => handleTransformation(editor, 'strikethrough'),
              },
              {
                label: 'Underline',
                icon: <Underline />,
                onClick: () => handleTransformation(editor, 'underline'),
              },
              {
                label: 'Italics',
                icon: <Italics />,
                onClick: () => handleTransformation(editor, 'italic'),
              },
            ],
          },
          {
            icon: <BulletedList />,
            tooltip: 'Bulleted list',
            onClick: () => handleTransformation(editor, 'bulletedList'),
          },
          {
            icon: <NumberedList />,
            tooltip: 'Numbered list',
            onClick: () => handleTransformation(editor, 'numberedList'),
          },
          {
            icon: <TodoList />,
            tooltip: 'Todo list',
            onClick: () => handleTransformation(editor, 'todoList'),
          },
          {
            isSeparator: true,
          },
          {
            icon: <Color />,
            tooltip: 'Color',
            title: 'Color',
            menuItems: Object.getOwnPropertyNames(colors).map((color) =>
              getColorOption(editor, color, colors[color]!, handleTransformation)
            ),
          },
          {
            icon: <ShapeColor />,
            tooltip: 'Highlight',
            title: 'Box Color',
            menuItems: Object.getOwnPropertyNames(boxColors).map((color) =>
              getBoxColorOption(color, boxColors[color]!, updateNode)
            ),
          },
          {
            isSeparator: true,
          },
          {
            icon: <CodeBlock />,
            tooltip: 'Code block',
            onClick: () => handleTransformation(editor, 'codeBlock'),
          },
          {
            icon: <Quote />,
            tooltip: 'Quote',
            onClick: () => handleTransformation(editor, 'quote'),
          },
          {
            isSeparator: true,
          },
          {
            icon: <FrameDiagram />,
            isActive: !!node?.data?.autoHeightDisabled,
            tooltip: !autoHeightDisabled ? 'Auto-height' : 'Fixed-height',
            onClick: () => {
              const autoHeightWillBeDisabled = !autoHeightDisabled;

              updateNode({
                className: autoHeightWillBeDisabled ? 'fixedHeight' : 'autoHeight',
                data: { autoHeightDisabled: autoHeightWillBeDisabled },
                height: autoHeightWillBeDisabled
                  ? undefined
                  : (node.height ?? node.measured?.height),
              });
            },
          },
        ]}
      />
    </div>
  );
}

const boxColors: Record<string, string> = {
  default: 'none',
  yellow: '#FFDF6B',
  amber: '#FFC46B',
  orange: '#FBA179',
  red: '#F16F6F',
  pink: '#ED8CBD',
  purple: '#937EE7',
  green: '#9DD194',
  sky: '#B8E3FF',
  blue: '#7A95F6',
  grey: '#232323',
  white: '#FFFFFF',
};

const getBoxColorOption = (
  color: string,
  hex: string,
  cb: (patch: { data: { boxColor: string } }) => void
) => ({
  label: upperFirst(color),
  icon: <ShapeColor color={hex === 'none' ? 'transparent' : hex} />,
  onClick: () => cb({ data: { boxColor: color } }),
});

import { DropdownMenuItem } from '@radix-ui/react-dropdown-menu';
import clsx from 'clsx';

import type { CreateMediaGroupSchema } from '@spaceduck/api';
import { Icon16, Icon24 } from '@spaceduck/icons';

import type { CreateBookmarkData } from '@components/CreateBookmarkModal';
import { useCreateMediaGroupActions } from '@hooks/useCreateMediaGroupActions';
import sharedProjectStyles from '@pages/projects/Shared.module.scss';
import Button, { type ButtonVariant } from '@ui/Button';
import DropdownMenu from '@ui/DropdownMenu';

const { Add } = Icon16;
const { ImageUpload, Board, Bookmark, Document } = Icon24;

const items = ['media', 'document', 'board', 'link'] as const;
type Item = (typeof items)[number];

type NewMediaGroupDropdownProps = {
  align?: 'start' | 'end' | 'center';
  children?: React.ReactNode;
  className?: string;
  exclude?: Item[];
  mediaGroupAttributes?: Omit<CreateMediaGroupSchema, 'kind' | 'workspaceId'>;
  open?: boolean;
  setOpen?: (state: boolean) => void;
  showTriggerIcon?: boolean;
  triggerText?: string;
  variant?: ButtonVariant;
};

export const NewMediaGroupDropdown = ({
  align = 'end',
  children,
  className,
  exclude,
  mediaGroupAttributes,
  open,
  setOpen,
  showTriggerIcon = true,
  triggerText,
  variant = 'outlined',
}: NewMediaGroupDropdownProps) => {
  const {
    createBoard,
    createDocument,
    createLink,
    fileInputRef,
    handleChange,
    openCreateBookmarkModal,
  } = useCreateMediaGroupActions(mediaGroupAttributes);

  return (
    <DropdownMenu
      open={open}
      setOpen={setOpen}
      align={align}
      className={clsx('menu', sharedProjectStyles.dropdownMenu, className)}
      triggerContent={
        <div>
          {children ?? (
            <Button
              className={sharedProjectStyles.createButton}
              iconBefore={showTriggerIcon ? <Add size={16} /> : undefined}
              size="sm"
              type="button"
              variant={variant}
            >
              {triggerText ?? (
                <>
                  <span className={sharedProjectStyles.desktop}>New item</span>
                  <span className={sharedProjectStyles.mobile}>New</span>
                </>
              )}
            </Button>
          )}
          {!exclude?.includes('media') && (
            <input
              type="file"
              multiple
              ref={fileInputRef}
              onChange={handleChange}
              style={{ display: 'none' }}
            />
          )}
        </div>
      }
    >
      {!exclude?.includes('media') && (
        <DropdownMenuItem
          className={sharedProjectStyles.dropdownMenuItem}
          onClick={(ev) => {
            ev.stopPropagation();
            setOpen?.(false);
            fileInputRef?.current?.click();
          }}
        >
          <div className={sharedProjectStyles.icon}>
            <ImageUpload />
          </div>
          <div className={sharedProjectStyles.text}>
            <div className={sharedProjectStyles.title}>Media & Files</div>
            <div className={sharedProjectStyles.description}>
              Images, Videos, PDFs, etc
            </div>
          </div>
        </DropdownMenuItem>
      )}
      {!exclude?.includes('document') && (
        <DropdownMenuItem
          className={sharedProjectStyles.dropdownMenuItem}
          onClick={(ev) => {
            ev.stopPropagation();
            setOpen?.(false);
            createDocument();
          }}
        >
          <div className={sharedProjectStyles.icon}>
            <Document />
          </div>
          <div className={sharedProjectStyles.text}>
            <div className={sharedProjectStyles.title}>Document</div>
            <div className={sharedProjectStyles.description}>Notes</div>
          </div>
        </DropdownMenuItem>
      )}
      {!exclude?.includes('board') && (
        <DropdownMenuItem
          className={sharedProjectStyles.dropdownMenuItem}
          onClick={(ev) => {
            ev.stopPropagation();
            setOpen?.(false);
            createBoard();
          }}
        >
          <div className={sharedProjectStyles.icon}>
            <Board />
          </div>
          <div className={sharedProjectStyles.text}>
            <div className={sharedProjectStyles.title}>Board</div>
            <div className={sharedProjectStyles.description}>Integrated whiteboard</div>
          </div>
        </DropdownMenuItem>
      )}
      {!exclude?.includes('link') && (
        <DropdownMenuItem
          className={sharedProjectStyles.dropdownMenuItem}
          onClick={(ev) => {
            ev.stopPropagation();
            setOpen?.(false);
            openCreateBookmarkModal({
              onSubmit: (data: CreateBookmarkData) => {
                createLink(data.url);
              },
            });
          }}
        >
          <div className={sharedProjectStyles.icon}>
            <Bookmark />
          </div>
          <div className={sharedProjectStyles.text}>
            <div className={sharedProjectStyles.title}>Link</div>
            <div className={sharedProjectStyles.description}>
              Bookmark URLs, Website pages etc
            </div>
          </div>
        </DropdownMenuItem>
      )}
    </DropdownMenu>
  );
};

import { useCallback, useRef } from 'react';
import { useParams } from 'react-router';

import type { CreateMediaGroupSchema } from '@spaceduck/api';

import { useCreateAndNavigateToMediaGroup, useCreateMediaGroup } from '@api/mediaGroup';
import { toastApiErrorOr } from '@api/util';
import { useBookmarkModal } from '@components/CreateBookmarkModal';
import { useFileUploadWrapper } from '@hooks/useFileUploadWrapper';
import useWorkspaceId from '@hooks/useWorkspaceId';

export const useCreateMediaGroupActions = (
  mediaGroupAttributes?: Omit<CreateMediaGroupSchema, 'kind' | 'workspaceId'>
) => {
  const workspaceId = useWorkspaceId();
  const projectId =
    mediaGroupAttributes?.projectId || useParams<{ projectId?: string }>().projectId;

  const fileInputRef = useRef<HTMLInputElement>(null);
  const { handleChange } = useFileUploadWrapper({
    ...mediaGroupAttributes,
    workspaceId,
    projectId,
  });

  const createBoard = useCreateAndNavigateToMediaGroup('board');
  const createDocument = useCreateAndNavigateToMediaGroup('document');
  const { open: openCreateBookmarkModal } = useBookmarkModal();
  const { mutateAsync: createMediaGroup } = useCreateMediaGroup();
  const createLink = useCallback(
    async (data: Omit<CreateMediaGroupSchema, 'kind'>) => {
      const { projectId, workspaceId, ...rest } = data;
      try {
        await createMediaGroup({
          kind: 'bookmark',
          projectId: projectId,
          workspaceId: !projectId ? workspaceId : undefined,
          ...rest,
        });
      } catch (error) {
        return toastApiErrorOr(error, 'Failed to create bookmark', {
          iconVariant: 'warning',
          titleText: 'Bookmark Create Error',
          bodyText:
            'An unknown error occurred while creating bookmark. Please try again later',
        });
      }
    },
    [createMediaGroup]
  );

  return {
    createBoard: () =>
      createBoard({
        ...mediaGroupAttributes,
        workspaceId: workspaceId ? workspaceId : undefined,
        projectId: projectId,
      }),
    createDocument: () =>
      createDocument({
        ...mediaGroupAttributes,
        workspaceId: workspaceId ? workspaceId : undefined,
        projectId: projectId,
      }),
    createLink: (url: string) =>
      createLink({
        ...mediaGroupAttributes,
        workspaceId: workspaceId ? workspaceId : undefined,
        projectId: projectId,
        url,
      }),
    fileInputRef,
    handleChange,
    openCreateBookmarkModal,
  };
};

import type { IconProps } from '../types/IconProps';

export default function Icon({ className, size = 64 }: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 64 64"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        stroke="#4B4E68"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M60.333 33.667V20.333a6.667 6.667 0 0 0-6.667-6.666H11a6.667 6.667 0 0 0-6.667 6.666V39A6.667 6.667 0 0 0 11 45.667h18"
      />
      <path
        stroke="#7A7E9F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M40.714 54.995h-3.052c-2.04 0-3.32-2.206-2.309-3.977L46.027 32.34c1.02-1.787 3.597-1.787 4.616 0l10.672 18.677c1.014 1.772-.267 3.978-2.309 3.978h-3.052"
      />
      <path
        stroke="#B5B7CA"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M48.334 41.667V47"
      />
      <path
        stroke="#212121"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M48.335 52.961a1 1 0 1 0 0 2 1 1 0 0 0 0-2"
      />
      <path
        fill="#B5B7CA"
        d="M48.335 52.295a1.667 1.667 0 1 0 0 3.333 1.667 1.667 0 0 0 0-3.333"
      />
      <path
        stroke="#212121"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M31.333 29.667a1 1 0 1 0 2 0 1 1 0 0 0-2 0"
      />
      <path
        fill="#7A7E9F"
        d="M30.666 29.667a1.667 1.667 0 1 0 3.333 0 1.667 1.667 0 0 0-3.333 0"
      />
      <path
        stroke="#212121"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M17 29.667a1 1 0 1 0 2 0 1 1 0 0 0-2 0"
      />
      <path
        fill="#4B4E68"
        d="M16.333 29.667a1.667 1.667 0 1 0 3.333 0 1.667 1.667 0 0 0-3.333 0"
      />
    </svg>
  );
}

import type { ComponentProps } from 'react';

import Tooltip from '@/components/ui/Tooltip';
import { useDetailsModalStore } from '@/stores/useDetailsModalStore';

export const DetailsModalTooltip = (props: ComponentProps<typeof Tooltip>) => {
  const isDraggingOnBoard = useDetailsModalStore((state) => state.isDraggingOnBoard);

  if (isDraggingOnBoard) {
    return props.children;
  }

  return <Tooltip {...props} />;
};

import { useCallback, useContext, useMemo } from 'react';
import type { NodeProps } from '@xyflow/react';
import { useMediaGroupDetail } from '@api/mediaGroup';

import { FlowCard } from '../components/FlowCard';
import { BoardStoreContext } from '../context/boardContext';
import { useCardUtils } from '../hooks/useCardUtils';
import type { ImageNodeType } from '../types/board';
import styles from './ImageNode.module.scss';

export function ImageNode(props: NodeProps<ImageNodeType>) {
  const {
    data: mediaGroupData,
    isError,
    isLoading,
    refetch,
  } = useMediaGroupDetail(props.data.mediaGroupId, {
    retry: false,
  });

  const boardMediaGroupId = useContext(BoardStoreContext)?.mediaGroupId;

  const { openCardInSidebar } = useCardUtils();
  const handleDoubleClick = useCallback(() => {
    openCardInSidebar({
      cardMediaGroupId: props.data.mediaGroupId,
      boardMediaGroupId,
    });
  }, [openCardInSidebar, props.data.mediaGroupId, boardMediaGroupId]);

  const firstMedia = mediaGroupData?.mediaGroup.media[0];

  const handleRefetch = useCallback(() => refetch(), []);

  const content = useMemo(() => {
    return (
      <div className={styles.imagePadding} onDoubleClick={handleDoubleClick}>
        {firstMedia && (
          <img
            className={styles.image}
            src={firstMedia.assetUrl}
            alt={firstMedia.assetName}
          />
        )}
      </div>
    );
  }, [firstMedia, handleDoubleClick]);

  return (
    <FlowCard
      {...props}
      isError={isError}
      isLoading={isLoading}
      mediaGroup={mediaGroupData?.mediaGroup}
      refetch={handleRefetch}
    >
      {content}
    </FlowCard>
  );
}

export default function Spinner({
  className,
  backgroundColor = 'transparent',
  size = 32,
  speed = '1.6s',
}: {
  className?: string;
  backgroundColor?: string;
  size?: number;
  speed?: string;
}) {
  if (size <= 16) {
    return (
      <svg
        className={className}
        fill="none"
        height={size}
        viewBox="0 0 16 16"
        width={size}
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_6169_119748)">
          <circle
            cx="8"
            cy="8"
            r="7"
            stroke="url(#paint0_linear_6169_119748)"
            strokeWidth="2"
          >
            <animateTransform
              attributeName="transform"
              type="rotate"
              dur={speed}
              values="0 8 8;360 8 8"
              repeatCount="indefinite"
            />
          </circle>
        </g>
        <defs>
          <linearGradient
            id="paint0_linear_6169_119748"
            x1="0"
            y1="8"
            x2="16"
            y2="8"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#6F53F9" />
            <stop offset="1" stopColor="#04D8DD" />
          </linearGradient>
          <clipPath id="clip0_6169_119748">
            <rect width="16" height="16" fill="white" />
          </clipPath>
        </defs>
      </svg>
    );
  }

  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 32 32"
    >
      <path
        d="M0 8C0 3.58172 3.58172 0 8 0H24C28.4183 0 32 3.58172 32 8V24C32 28.4183 28.4183 32 24 32H8C3.58172 32 0 28.4183 0 24V8Z"
        fill={backgroundColor}
      />
      <circle
        cx="16"
        cy="16"
        r="10.5"
        stroke="url(#paint0_linear_8356_115269)"
        strokeWidth="3"
        fill="transparent"
      >
        <animateTransform
          attributeName="transform"
          type="rotate"
          dur={speed}
          values="0 16 16;360 16 16"
          repeatCount="indefinite"
        />
      </circle>
      <defs>
        <linearGradient
          id="paint0_linear_8356_115269"
          x1="4"
          y1="16"
          x2="28"
          y2="16"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6F53F9" />
          <stop offset="1" stopColor="#04D8DD" />
        </linearGradient>
      </defs>
    </svg>
  );
}

import type { ReactNode } from 'react';
import clsx from 'clsx';

import styles from './PlaceholderCard.module.scss';

type PlaceholderCardProps = {
  className?: string;
  containerClassName?: string;
  children?: ReactNode;
  description?: ReactNode;
  icon?: ReactNode;
  title?: string;
};

export default function PlaceholderCard({
  className,
  containerClassName,
  children,
  description,
  icon,
  title,
}: PlaceholderCardProps) {
  return (
    <div className={clsx(styles.container, containerClassName)}>
      <div className={clsx(styles.card, icon && styles.hasIcon, className)}>
        {icon && <div className={styles.icon}>{icon}</div>}
        <div className={styles.text}>
          {title && <h4>{title}</h4>}
          {description}
          {children}
        </div>
      </div>
    </div>
  );
}

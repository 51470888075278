import { useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useParams } from 'react-router';
import { useStore, useViewport } from '@xyflow/react';
import { useShallow } from 'zustand/shallow';

import type { CreateMediaGroupSchema, MediaGroupDetailDTO } from '@spaceduck/api';
import { Icon16, Icon24 } from '@spaceduck/icons';

import { useCreateMediaGroup } from '@api/mediaGroup';
import { toastApiErrorOr } from '@api/util';
import {
  type CreateBookmarkData,
  useBookmarkModal,
} from '@components/CreateBookmarkModal';
import { useNavigateToBackgroundLocation } from '@hooks/useBackgroundLocation';
import { useFileUploadWrapper } from '@hooks/useFileUploadWrapper';
import { useIsInWebViewRoute } from '@/hooks/useIsWebView';
import useWorkspaceId from '@hooks/useWorkspaceId';
import { useDetailsModalStore } from '@stores/useDetailsModalStore';
import { getCloseTo } from '@utils/mediaGroup';
import HorizontalMenu, { type MenuItemProps } from '../components/HorizontalMenu';
import { BoardStoreContext } from '../context/boardContext';
import { useBoardStore } from '../hooks/useBoardStore';
import { DEFAULT_CARD_HEIGHT, DEFAULT_CARD_WIDTH, useNodes } from '../hooks/useNodes';
import { useModalManager } from '@context/ModalManagerContext';
import styles from './ActionMenu.module.scss';

const { CardLarge, Cursor, Link, TextT, Upload, Hand } = Icon16;
const { Frame } = Icon24;

const STYLE_NO_DISPLAY = { display: 'none' };

export default function ActionMenu({
  hasSelected,
  mediaGroup,
}: {
  hasSelected: boolean;
  mediaGroup: MediaGroupDetailDTO;
}) {
  const { actionMenuMode, setActionMenuMode, panOnDrag, setPanOnDrag } = useBoardStore(
    useShallow((state) => ({
      actionMenuMode: state.actionMenuMode,
      setActionMenuMode: state.setActionMenuMode,
      panOnDrag: state.panOnDrag,
      setPanOnDrag: state.setPanOnDrag,
    }))
  );

  const isInWebViewRoute = useIsInWebViewRoute();

  const boardContext = useContext(BoardStoreContext);
  const { addMediaNode } = useNodes();
  const { zoom } = useViewport();
  const getScreenCenter = useCallback(() => {
    if (boardContext?.containerRef.current) {
      const { height, width } =
        boardContext.containerRef.current.getBoundingClientRect();
      const flowContainerCenterX = width / 2;
      const flowContainerCenterY = height / 2;
      const offsetX = (DEFAULT_CARD_WIDTH / 2) * zoom;
      const offsetY = (DEFAULT_CARD_HEIGHT / 2) * zoom;

      return { x: flowContainerCenterX - offsetX, y: flowContainerCenterY - offsetY };
    }

    return { x: 0, y: 0 };
  }, [boardContext]);

  const workspaceId = useWorkspaceId();
  const projectId = useParams<{ projectId?: string }>().projectId;
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { handleChange } = useFileUploadWrapper(
    {
      workspaceId,
      projectId,
    },
    {
      onSuccess: async (mediaGroupId: string) => {
        const { x, y } = getScreenCenter();
        addMediaNode(mediaGroupId, x, y);
      },
    }
  );

  const { mutateAsync: createMediaGroup } = useCreateMediaGroup();
  const createLink = useCallback(
    async (data: Omit<CreateMediaGroupSchema, 'kind'>) => {
      const { projectId, workspaceId, ...rest } = data;
      let response: Awaited<ReturnType<typeof createMediaGroup>>;

      try {
        response = await createMediaGroup({
          kind: 'bookmark',
          projectId: projectId,
          workspaceId: !projectId ? workspaceId : undefined,
          ...rest,
        });
      } catch (error) {
        return toastApiErrorOr(error, 'Failed to create media group from action menu', {
          iconVariant: 'warning',
          titleText: 'Failed to create item',
          bodyText:
            'An unknown error occurred while creating item. Please try again later',
        });
      }
      const { mediaGroupId } = response;

      const { x, y } = getScreenCenter();
      addMediaNode(mediaGroupId, x, y);
    },
    [createMediaGroup, getScreenCenter, addMediaNode]
  );
  const { open: openCreateBookmarkModal } = useBookmarkModal();
  const launchCreateBookmarkModal = useCallback(() => {
    openCreateBookmarkModal({
      onSubmit: (data: CreateBookmarkData) => {
        createLink({
          workspaceId: workspaceId ? workspaceId : undefined,
          projectId: projectId,
          url: data.url,
        });
      },
    });
  }, [openCreateBookmarkModal, createLink, workspaceId, projectId]);

  useHotkeys('V', (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    setActionMenuMode('select');
  });

  useHotkeys('D', (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    setActionMenuMode('document');
  });

  useHotkeys('T', (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    setActionMenuMode('text');
  });

  useHotkeys('F', (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    setActionMenuMode('frame');
  });

  const { isCommenting, setQuickViewMediaGroupId } = useDetailsModalStore(
    useShallow((store) => ({
      isCommenting: store.isCommenting,
      setQuickViewMediaGroupId: store.setQuickViewMediaGroupId,
    }))
  );

  const navigateToBackgroundLocation = useNavigateToBackgroundLocation();
  const { modals } = useModalManager();

  const unselectNodesAndEdges = useStore((store) => store.unselectNodesAndEdges);

  const handleEscape = useCallback(() => {
    if (hasSelected) {
      unselectNodesAndEdges();
      return;
    }

    if (actionMenuMode === 'select') {
      navigateToBackgroundLocation(getCloseTo(mediaGroup));
      setQuickViewMediaGroupId(null);
    }

    if (actionMenuMode !== 'select') {
      setActionMenuMode('select');
    }
  }, [
    hasSelected,
    unselectNodesAndEdges,
    actionMenuMode,
    navigateToBackgroundLocation,
    mediaGroup,
    setQuickViewMediaGroupId,
    setActionMenuMode,
  ]);

  useHotkeys(
    'Escape',
    handleEscape,
    {
      enabled: !isCommenting && !modals.length,
    },
    [isCommenting, actionMenuMode, hasSelected]
  );

  useEffect(() => {
    if (boardContext?.containerRef?.current) {
      const supportedCursors = ['document', 'frame', 'text'];
      supportedCursors
        .filter((state) => state !== actionMenuMode)
        .forEach((state) => {
          boardContext.containerRef.current?.classList.remove(state);
        });

      if (supportedCursors.includes(actionMenuMode)) {
        boardContext.containerRef.current.classList.add(actionMenuMode);
      }
    }
  }, [actionMenuMode, boardContext]);

  const handMenuItem = useMemo(() => {
    return {
      title: 'Hand',
      shortKeys: ['Space'],
      buttonIcon: <Hand size={16} />,
      isActive: panOnDrag,
      onClick: () => setPanOnDrag(!panOnDrag),
    };
  }, [panOnDrag, setPanOnDrag]);

  const selectMenuItem = useMemo(() => {
    return {
      title: 'Select',
      shortKeys: ['V'],
      buttonIcon: <Cursor size={16} />,
      isActive: actionMenuMode === 'select',
      onClick: () => setActionMenuMode('select'),
    };
  }, [actionMenuMode, setActionMenuMode]);

  const documentMenuItem = useMemo(() => {
    return {
      title: 'Document',
      shortKeys: ['D'],
      buttonIcon: <CardLarge size={16} />,
      isActive: actionMenuMode === 'document',
      onClick: () => setActionMenuMode('document'),
    };
  }, [actionMenuMode, setActionMenuMode]);

  const textMenuItem = useMemo(() => {
    return {
      title: 'Text',
      shortKeys: ['T'],
      buttonIcon: <TextT size={16} />,
      isActive: actionMenuMode === 'text',
      onClick: () => setActionMenuMode('text'),
    };
  }, [actionMenuMode, setActionMenuMode]);

  const frameMenuItem = useMemo(() => {
    return {
      title: 'Frame',
      shortKeys: ['F'],
      buttonIcon: <Frame size={16} />,
      showSeparatorAfter: true,
      isActive: actionMenuMode === 'frame',
      onClick: () => setActionMenuMode('frame'),
    };
  }, [actionMenuMode, setActionMenuMode]);

  const linkMenuItem = useMemo(() => {
    return {
      title: 'Link',
      buttonIcon: <Link size={16} />,
      onClick: () => launchCreateBookmarkModal(),
    };
  }, [launchCreateBookmarkModal]);

  const uploadMenuItem = useMemo(() => {
    return {
      title: 'Upload',
      buttonIcon: <Upload size={16} />,
      onClick: () => {
        fileInputRef?.current?.click();
      },
    };
  }, []);

  const menu = useMemo(() => {
    const _menu: MenuItemProps[] = [
      selectMenuItem,
      documentMenuItem,
      textMenuItem,
      frameMenuItem,
      linkMenuItem,
      uploadMenuItem,
    ];

    if (isInWebViewRoute) {
      _menu.unshift(handMenuItem);
    }

    return _menu;
  }, [
    selectMenuItem,
    documentMenuItem,
    textMenuItem,
    frameMenuItem,
    linkMenuItem,
    uploadMenuItem,
    isInWebViewRoute,
    handMenuItem,
  ]);

  return (
    <div className={styles.container}>
      <HorizontalMenu menu={menu} />
      <input
        type="file"
        multiple
        ref={fileInputRef}
        onChange={handleChange}
        style={STYLE_NO_DISPLAY}
      />
    </div>
  );
}

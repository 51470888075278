import clsx from 'clsx';
import { useCallback, useMemo } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useShallow } from 'zustand/shallow';

import type { MediaGroupDetailDTO } from '@spaceduck/api';
import { Icon16, Icon24 } from '@spaceduck/icons';

import type { DetailsModelTab } from '@/types';
import { urlFor } from '@/urls';
import { isCapable } from '@api/util';
import { useBackgroundLocation } from '@hooks/useBackgroundLocation';
import { useNavigateWithState } from '@hooks/useNavigateWithState';
import { useDetailsModalStore } from '@stores/useDetailsModalStore';
import { useNotesStore } from '@stores/useNotesStore';
import Button, { ButtonLink } from '@ui/Button';
import Spinner from '@ui/Spinner';
import Tooltip from '@ui/Tooltip';
import { copyUrlToClipboard } from '@utils/copyToClipboard';
import { getCloseTo } from '@utils/mediaGroup';
import TabButton, { TabButtonWrapper } from '../ui/TabButton';
import DetailsModalActionsDropdown from './DetailsModalActionsDropdown';
import styles from './DetailsModalHeader.module.scss';
import { getSupportedViews } from './views';
import { useIsMac } from '@/hooks/useOs';
import { DetailsModalTooltip } from './comments/DetailsModalTooltip';

const { Close, Comment, DrawerRight } = Icon24;
const { Image, IFrame, ReadingMode, Summary, ShowEmbed } = Icon16;

type DetailsModalHeaderProps = {
  mediaGroup: MediaGroupDetailDTO;
  isNote: boolean;
  activeTab: DetailsModelTab;
  view: string | null;
};

export default function DetailsModalHeader({
  mediaGroup,
  isNote,
  activeTab,
  view,
}: DetailsModalHeaderProps) {
  const {
    contentView,
    drawerIsOpen,
    isCommenting,
    openDrawer,
    setIsCommenting,
    toggleDrawer,
    userCapabilities,
  } = useDetailsModalStore(
    useShallow((state) => ({
      contentView: state.contentView,
      drawerIsOpen: state.drawerIsOpen,
      isCommenting: state.isCommenting,
      openDrawer: state.openDrawer,
      setIsCommenting: state.setIsCommenting,
      toggleDrawer: state.toggleDrawer,
      userCapabilities: state.userCapabilities,
    }))
  );

  const [lastBackgroundLocation, ...previousLocations] = useBackgroundLocation();
  const navigateWithState = useNavigateWithState();

  const mediaGroupId = mediaGroup.id;
  const setActiveTab = useCallback(
    (activeTab: DetailsModelTab) => {
      const url = urlFor(
        activeTab === 'comment' ? 'mediaGroupComments' : 'mediaGroup',
        { mediaGroupId }
      );
      navigateWithState(url);
    },
    [mediaGroupId]
  );

  const isMac = useIsMac();

  useHotkeys(
    'meta+alt+s',
    () => {
      copyUrlToClipboard();
    },
    {
      enabled: isMac,
      preventDefault: true,
    }
  );

  useHotkeys(
    'ctrl+alt+s',
    () => {
      copyUrlToClipboard();
    },
    {
      enabled: !isMac,
      preventDefault: true,
    }
  );

  useHotkeys(
    'meta+.',
    () => {
      toggleDrawer();
    },
    {
      enabled: isMac,
      preventDefault: true,
    }
  );

  useHotkeys(
    'ctrl+.',
    () => {
      toggleDrawer();
    },
    {
      enabled: !isMac,
      preventDefault: true,
    }
  );

  const toggleCommenting = useCallback(() => {
    if (isCommenting) {
      setIsCommenting(false);
    } else {
      setIsCommenting(true);
      setActiveTab('comment');
      openDrawer();
    }
  }, [isCommenting, setIsCommenting, setActiveTab, openDrawer]);

  useHotkeys(
    'c',
    () => {
      toggleCommenting();
      openDrawer();
    },
    {
      enabled: isCapable('comment', userCapabilities).capable,
    },
    [isCommenting, userCapabilities]
  );

  useHotkeys(
    'Escape',
    () => {
      setIsCommenting(false);
    },
    {
      enabled: isCommenting,
    },
    [isCommenting]
  );

  const handleToggleDrawer = useCallback(() => toggleDrawer(), [toggleDrawer]);

  const showCommentButton =
    isCapable('comment', userCapabilities).capable &&
    ['content'].includes(contentView ?? '') &&
    mediaGroup.media.length !== 0 &&
    !mediaGroup.isGeneratingContent;

  const closeTo = useMemo(() => {
    return lastBackgroundLocation || getCloseTo(mediaGroup);
  }, [lastBackgroundLocation, mediaGroup]);

  const closeState = useMemo(() => {
    return previousLocations.length
      ? { backgroundLocation: previousLocations }
      : undefined;
  }, [previousLocations]);

  return (
    <div className={styles.header}>
      <nav className={styles.navigation}>
        <CloseButton to={closeTo} state={closeState} />
      </nav>
      {isNote && <NoteSavingIndicator />}
      <ViewSwitcher mediaGroup={mediaGroup} view={view} activeTab={activeTab} />
      <div className={styles.utils}>
        <DetailsModalActionsDropdown mediaGroupId={mediaGroup.id} />
        {showCommentButton && (
          <DetailsModalTooltip
            content="Comment"
            icons={['c']}
            size="medium"
            variant="secondary"
          >
            <Button
              className={clsx(styles.button, isCommenting && styles.active)}
              onClick={toggleCommenting}
              variant="icon"
            >
              <Comment size={20} />
            </Button>
          </DetailsModalTooltip>
        )}
        <DetailsModalTooltip
          align="end"
          content={`${drawerIsOpen ? 'Hide' : 'Show'} Sidebar`}
          shortKeys={['⌘', '.']}
          size="medium"
          variant="secondary"
        >
          <Button className={styles.button} variant="icon" onClick={handleToggleDrawer}>
            <DrawerRight size={20} />
          </Button>
        </DetailsModalTooltip>
      </div>
    </div>
  );
}

const CloseButton = ({
  to,
  state,
}: { to: string; state: { backgroundLocation: string[] } | undefined }) => {
  const setQuickViewMediaGroupId = useDetailsModalStore(
    (state) => state.setQuickViewMediaGroupId
  );
  const isSaving = useNotesStore(
    (state) => state.isNotesSaving || state.isQuickViewNotesSaving
  );

  const handleCloseClick = useCallback(() => {
    setQuickViewMediaGroupId(null);
  }, [setQuickViewMediaGroupId]);

  return (
    <Tooltip
      align="start"
      content="Close"
      shortKeys={['Esc']}
      size="medium"
      variant="secondary"
    >
      {isSaving ? (
        <Button disabled className={styles.button} variant="icon">
          <Close size={20} />
        </Button>
      ) : (
        <ButtonLink
          className={styles.button}
          variant="icon"
          onClick={handleCloseClick}
          to={to}
          state={state}
        >
          <Close size={20} />
        </ButtonLink>
      )}
    </Tooltip>
  );
};

const NoteSavingIndicator = () => {
  const isSaving = useNotesStore(
    (state) => state.isNotesSaving || state.isQuickViewNotesSaving
  );

  return (
    <div className={styles.notesUtils}>
      <div className={styles.savingIndicator}>
        {isSaving && (
          <>
            <Spinner size={16} />
            <span className={styles.savingLabel}>Saving...</span>
          </>
        )}
      </div>
    </div>
  );
};

type ViewSwitcherProps = {
  mediaGroup: MediaGroupDetailDTO;
  activeTab: DetailsModelTab;
  view: string | null;
};

const ViewSwitcher = (props: ViewSwitcherProps) => {
  const { mediaGroup } = props;
  if (mediaGroup.kind === 'bookmark') {
    return <BookmarkViewSwitcher {...props} />;
  }
  return null;
};

const BookmarkViewSwitcher = ({ mediaGroup, activeTab, view }: ViewSwitcherProps) => {
  const navigateWithState = useNavigateWithState();

  const { kind } = mediaGroup;

  if (kind !== 'bookmark') {
    return null;
  }

  const views = getSupportedViews(mediaGroup);

  if (views.size < 2) {
    return null;
  }

  return (
    <div className={styles.noteToggle}>
      <TabButtonWrapper variant="dark" className={styles.tabButtonWrapper}>
        {views.has('embed') && (
          <TabButton
            isActive={view === 'embed'}
            iconBefore={<ShowEmbed />}
            onClick={() =>
              navigateWithState(
                urlFor('mediaGroupModal', {
                  mediaGroupId: mediaGroup.id,
                  view: 'embed',
                  activeTab,
                })
              )
            }
            size="sm"
            variant="dark"
          >
            Embed
          </TabButton>
        )}
        {views.has('media') && (
          <TabButton
            isActive={view === 'media' || view === null}
            iconBefore={<Image />}
            onClick={() =>
              navigateWithState(
                urlFor('mediaGroupModal', {
                  mediaGroupId: mediaGroup.id,
                  view: 'media',
                  activeTab,
                })
              )
            }
            size="sm"
            variant="dark"
          >
            Screenshot
          </TabButton>
        )}
        {views.has('reading') && (
          <TabButton
            isActive={view === 'reading'}
            iconBefore={<ReadingMode />}
            onClick={() =>
              navigateWithState(
                urlFor('mediaGroupModal', {
                  mediaGroupId: mediaGroup.id,
                  view: 'reading',
                  activeTab,
                })
              )
            }
            size="sm"
            variant="dark"
          >
            Reading mode
          </TabButton>
        )}
        {views.has('iframe') && (
          <TabButton
            isActive={view === 'iframe'}
            iconBefore={<IFrame />}
            onClick={() =>
              navigateWithState(
                urlFor('mediaGroupModal', {
                  mediaGroupId: mediaGroup.id,
                  view: 'iframe',
                  activeTab,
                })
              )
            }
            size="sm"
            variant="dark"
          >
            iFrame
          </TabButton>
        )}
        {views.has('summary') && (
          <TabButton
            isActive={view === 'summary'}
            iconBefore={<Summary />}
            onClick={() =>
              navigateWithState(
                urlFor('mediaGroupModal', {
                  mediaGroupId: mediaGroup.id,
                  view: 'summary',
                  activeTab,
                })
              )
            }
            size="sm"
            variant="dark"
          >
            Summary
          </TabButton>
        )}
      </TabButtonWrapper>
    </div>
  );
};

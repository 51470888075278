import {
  type MediaGroupCategoryDetail,
  mediaGroupCategoryFieldKinds,
} from '@spaceduck/api';
import { Icon16 } from '@spaceduck/icons';
import { upperFirst } from 'lodash';

import { CategoryKind } from '@icons/CategoryKind';
import Button from '@ui/Button';
import DropdownMenu, { DropdownMenuItem } from '@ui/DropdownMenu';
import { type OnCreateSubmit, useCreatePropertyModal } from '../Forms';
import styles from './AddPropertyButton.module.scss';

const { Add } = Icon16;

function getDisplayName(name: string) {
  switch (name) {
    case 'url':
      return 'URL';
    case 'relationship':
      return 'relation';
    default:
      return name;
  }
}

export default function AddPropertyButton({
  onSubmit,
  category,
}: {
  onSubmit: OnCreateSubmit;
  category: MediaGroupCategoryDetail;
}) {
  const { open } = useCreatePropertyModal();

  return (
    <DropdownMenu
      isPadded
      triggerContent={
        <Button className={styles.addPropertyButton} size="xs" variant="ghost">
          <Add />
        </Button>
      }
      width={220}
    >
      {mediaGroupCategoryFieldKinds.map((type, idx) => {
        return (
          <DropdownMenuItem
            asChild
            key={idx}
            onSelect={() => {
              open({ onSubmit, type, category });
            }}
          >
            <Button
              className={styles.button}
              iconBefore={<CategoryKind kind={type} />}
              variant="menu"
            >
              <span>{upperFirst(getDisplayName(type))}</span>
            </Button>
          </DropdownMenuItem>
        );
      })}
    </DropdownMenu>
  );
}

import { NavLink, useParams } from 'react-router';
import type { ReactNode } from 'react';
import clsx from 'clsx';

import { Icon24 } from '@spaceduck/icons';

import { useProject } from '@api/project';
import { isCapable } from '@api/util';
import { useWorkspace } from '@api/workspace';
import useWorkspaceId from '@hooks/useWorkspaceId';
import Button from '@ui/Button';
import { urlFor } from '@/urls';
import { useProjectLayoutStore } from './TwoColumnContent';
import styles from './ProjectTabs.module.scss';

const { DrawerRight, RepositoryBrowse, List, Workflow, ResearchChatAI } = Icon24;

const ResearchAssistantLink = ({
  isResearchChat,
  projectId,
}: { isResearchChat?: boolean; projectId: string }) => {
  const workspaceId = useWorkspaceId();
  const { data } = useWorkspace(workspaceId);

  if (!workspaceId) {
    return null;
  }

  const canCreate = data
    ? isCapable('researchAssistantSessionCreate', data?.workspace.capabilities).capable
    : false;

  return (
    <li>
      <NavLink
        className={({ isActive }) =>
          clsx(
            isActive || isResearchChat ? styles.isActive : undefined,
            styles.navLink,
            !canCreate ? styles.disabled : undefined
          )
        }
        onClick={(e) => {
          if (!canCreate) {
            e.preventDefault();
          }
        }}
        to={urlFor('spaceResearchAssistant', { projectId })}
      >
        <ResearchChatAI size={20} />
        Research Assistant
      </NavLink>
    </li>
  );
};

export default function ProjectTabs({
  cta,
  isResearchChat,
  projectId: projectIdProp,
  showSidebarToggle = true,
}: {
  cta: ReactNode;
  isResearchChat?: boolean;
  projectId?: string;
  showSidebarToggle?: boolean;
}) {
  const projectIdParam = useParams<{ projectId: string }>().projectId!;
  const projectId = projectIdProp || projectIdParam;
  const { data } = useProject(projectId);
  const { project } = data || {};
  const toggleDrawer = useProjectLayoutStore((state) => state.toggleDrawer);
  if (!project) return null;

  return (
    <div className={styles.projectTabs}>
      <ul>
        <ResearchAssistantLink isResearchChat={isResearchChat} projectId={project.id} />
        <li>
          <NavLink
            className={({ isActive }) =>
              clsx(isActive ? styles.isActive : undefined, styles.navLink)
            }
            to={urlFor('spaceLibrary', { projectId: project.id })}
          >
            <RepositoryBrowse size={20} /> Library
          </NavLink>
        </li>
        <li>
          <NavLink
            className={({ isActive }) =>
              clsx(isActive ? styles.isActive : undefined, styles.navLink)
            }
            to={urlFor('spaceCategories', { projectId: project.id })}
          >
            <List size={20} />
            Categories
          </NavLink>
        </li>
        <li>
          <NavLink
            className={({ isActive }) =>
              clsx(isActive ? styles.isActive : undefined, styles.navLink)
            }
            to={urlFor('spaceProgress', { projectId: project.id })}
          >
            <Workflow size={20} />
            Progress
          </NavLink>
        </li>
      </ul>
      <div className={styles.projectTabCTAs}>
        {cta}
        {showSidebarToggle && (
          <Button onClick={toggleDrawer} variant="outlined" isSquare size="sm">
            <DrawerRight size={20} />
          </Button>
        )}
      </div>
    </div>
  );
}

import { useMemo, type PropsWithChildren } from 'react';
import { Icon16, Icon24 } from '@spaceduck/icons';

import Tooltip from '@/components/ui/Tooltip';
import type { Feature } from './billingData';

import styles from './FeaturesSummary.module.scss';

const { Info } = Icon16;
const { Check } = Icon24;

export const FeaturesSummary = ({
  title,
  features,
}: { title: string; features: Feature[] | null }) => {
  if (!features?.length) {
    return null;
  }

  return (
    <div className={styles.featuresSummary}>
      <h4>{title}</h4>
      <FeatureList features={features} />
    </div>
  );
};

const FeatureList = ({
  features,
  short = false,
}: { features: Feature[]; short?: boolean }) => {
  return (
    <>
      {features.map((feature, idx) => (
        <FeatureInfo key={idx} tooltip={feature.tooltip}>
          {short ? (feature.shortTitle ?? feature.title) : feature.title}
        </FeatureInfo>
      ))}
    </>
  );
};

const intoColumns = <T,>(items: T[], columnCount: number): T[][] => {
  const columns = [];

  const perColumn = Math.ceil(items.length / columnCount);

  for (let i = 0; i < columnCount; i++) {
    columns.push(items.slice(i * perColumn, (i + 1) * perColumn));
  }

  return columns;
};

export const FeatureColumns = ({
  features,
  columns = 2,
  short,
}: { features: Feature[]; columns?: number; short?: boolean }) => {
  const cols = useMemo(() => intoColumns(features, columns), [features, columns]);

  return (
    <div className={styles.featureColumns}>
      {cols.map((features, index) => {
        return (
          <div key={index} className={styles.column}>
            <FeatureList features={features} short={short} />
          </div>
        );
      })}
    </div>
  );
};

export const FeatureInfo = ({
  children,
  tooltip,
}: PropsWithChildren<Pick<Feature, 'tooltip'>>) => {
  return (
    <div className={styles.feature}>
      <span className={styles.title}>
        <Check className={styles.check} />
        {children}
      </span>
      {!!tooltip && (
        <Tooltip content={tooltip}>
          <span className={styles.tooltip}>
            <Info />
          </span>
        </Tooltip>
      )}
    </div>
  );
};

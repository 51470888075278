import { useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router';

export const useBackgroundLocation = (): string[] => {
  const location = useLocation();
  const backgroundLocation = useMemo(() => {
    return location.state?.backgroundLocation ?? [];
  }, [location]);

  return backgroundLocation;
};

export const useLastBackgroundLocation = (): string | null => {
  const backgroundLocation = useBackgroundLocation();

  return backgroundLocation[0] ?? null;
};

export const useNavigateToBackgroundLocation = () => {
  const navigate = useNavigate();
  const backgroundLocation = useBackgroundLocation();

  const navigateToBackgroundLocation = useCallback(
    (fallbackLocation?: string) => {
      if (!backgroundLocation[0]) {
        if (fallbackLocation) navigate(fallbackLocation);
        return;
      }

      const [lastBackgroundLocation, ...previousLocations] = backgroundLocation;
      navigate(lastBackgroundLocation, {
        state: { backgroundLocation: previousLocations },
      });
    },
    [backgroundLocation, navigate]
  );

  return navigateToBackgroundLocation;
};

import {
  type CapabilitySchema,
  type ProjectDuty,
  type ProjectMemberCapability,
  type Role,
  type UserProjectMembership,
  type WorkspaceMember,
  projectDutySchema,
  roleSchema,
  workspaceMemberDetails,
} from '@spaceduck/api';
import { Icon48, Icon64 } from '@spaceduck/icons';
import { useQueryClient } from '@tanstack/react-query';
import { clsx } from 'clsx';
import { type Ref, forwardRef, useCallback, useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { z } from 'zod';

import { billingKeys, useGetSubscriptionInfo } from '@api/billing';
import { useDeleteWorkspaceInvite, useResendWorkspaceInvite } from '@api/invite';
import { usePatchProjectMembership, useRemoveUserFromProject } from '@api/project';
import { useUserProjectMemberships } from '@api/users';
import { isCapable, toastApiErrorOr } from '@api/util';
import {
  useDeleteWorkspaceMember,
  usePatchWorkspaceMember,
  useWorkspaceInvites,
  useWorkspaceMembers,
  workspaceKeys,
} from '@api/workspace';
import NoEntries from '@components/NoEntries';
import Spinner from '@components/Spinner';
import { PROJECT_DUTY_NAMES, ROLE_NAMES } from '@components/admin/const';
import { useCreateInviteModal } from '@components/admin/people/CreateInviteModal';
import { useSeatManagementModal } from '@components/admin/people/SeatManagementModal';
import { useModalManager } from '@context/ModalManagerContext';
import { useChangeSeats } from '@hooks/useChangeSeats';
import { useDebouncedSearch } from '@hooks/useDebouncedSearch';
import useWorkspaceId from '@hooks/useWorkspaceId';
import { EditMenu, Search, SubNav } from '@pages/common';
import Badge from '@ui/Badge';
import Button from '@ui/Button';
import { useConfirmModal, useDeleteConfirmModal } from '@ui/ConfirmModal';
import Dialog from '@ui/Dialog';
import { DropdownMenuItem } from '@ui/DropdownMenu';
import Select from '@ui/Select';
import { ControlledTabButtonWrapper } from '@ui/TabButton';
import UserAvatar from '@ui/UserAvatar';
import createToast from '@utils/createToast';
import styles from './PeopleManager.module.scss';
import { Container, Header, TopNav } from './common';
import SettingsError from './common/SettingsError';

const { HashTag } = Icon48;
const { Invite } = Icon64;

const roleInputCapabilities = [
  'revokeViewer',
  'revokeEditor',
  'revokeAdmin',
  'revokeOwner',
  'assignViewer',
  'assignEditor',
  'assignAdmin',
  'assignOwner',
] as const;
type RoleInputCapabilities = (typeof roleInputCapabilities)[number];

const REVOKE_CAPABILITY: Record<Role, RoleInputCapabilities> = {
  V: 'revokeViewer',
  E: 'revokeEditor',
  A: 'revokeAdmin',
  O: 'revokeOwner',
};

const ASSIGN_CAPABILITY: Record<Role, RoleInputCapabilities> = {
  V: 'assignViewer',
  E: 'assignEditor',
  A: 'assignAdmin',
  O: 'assignOwner',
};

const DEFAULT_ROLE_ORDER: Role[] = ['O', 'A', 'E', 'V'];

const SELECT_EMPTY_STRING_PLACEHOLDER = '---';

type RoleInputProps = {
  value: Role;
  capabilities: Array<CapabilitySchema<RoleInputCapabilities>>;
  onChange?: (role: Role) => Promise<void>;
  order?: Role[];
  loading?: boolean;
};

const getCapabilitiesForRoleInput = (
  capabilities: CapabilitySchema<string>[]
): Array<CapabilitySchema<RoleInputCapabilities>> => {
  return capabilities.filter(
    (cap): cap is CapabilitySchema<RoleInputCapabilities> =>
      roleInputCapabilities.indexOf(cap.capability as RoleInputCapabilities) >= 0
  );
};

export const useChangeRole = () => {
  const { mutateAsync: patchWorkspaceMember } = usePatchWorkspaceMember();
  const queryClient = useQueryClient();
  return useChangeSeats(
    async ({
      member,
      workspaceId,
      role,
    }: {
      member: WorkspaceMember;
      workspaceId: string;
      role: Role;
    }) => {
      const capable = isCapable(ASSIGN_CAPABILITY[role], member.capabilities);
      if (!capable.capable && capable.reason === 'plan') {
        return {
          reason: null,
          status: 'failed',
          kind: 'success',
          invoice: null,
        };
      }
      return await patchWorkspaceMember({
        workspaceId,
        userId: member.id,
        patch: { role },
      });
    },
    async ({ member, workspaceId }) => {
      const workspaceMember = await workspaceMemberDetails(workspaceId, member.id);
      return workspaceMember.member.role === workspaceMember.member.assignedRole;
    },
    ({ member, workspaceId, role }) => {
      const existingRole = member.role;

      createToast({
        titleText: 'Role changed successfully',
        bodyText: `${member.name}'s role has been successfully changed from ${ROLE_NAMES[existingRole]} to ${ROLE_NAMES[role]}.`,
        iconVariant: 'success',
      });

      queryClient.invalidateQueries({ queryKey: workspaceKeys.all });
      queryClient.invalidateQueries({
        queryKey: billingKeys.info(workspaceId),
      });
    }
  );
};

const RoleInput = ({
  value,
  onChange,
  capabilities,
  order,
  loading = false,
}: RoleInputProps) => {
  const hasRevokeCapability = isCapable(REVOKE_CAPABILITY[value], capabilities).capable;

  const handleChange = (role: string) => {
    const result = roleSchema.safeParse(role);
    if (!result.success) {
      console.error('Unexpected role selected', role, result.error);
      return;
    }
    return onChange?.(result.data);
  };

  if (!hasRevokeCapability) {
    return <span>{ROLE_NAMES[value]}</span>;
  }

  return (
    <Select
      loading={loading}
      className={styles.selectBox}
      contentClassName={styles.selectBoxContent}
      onValueChange={handleChange}
      placeholder={ROLE_NAMES[value]}
      selectGroups={[
        {
          options: (order ?? DEFAULT_ROLE_ORDER).map((role) => {
            const capable = isCapable(ASSIGN_CAPABILITY[role], capabilities);
            return {
              label: ROLE_NAMES[role],
              value: role,
              isDisabled: capable.capable === false && capable.reason !== 'plan',
            };
          }),
        },
      ]}
      showIndicator
      value={value ? (value as string) : undefined}
    />
  );
};

type ProjectDutyInputParams = {
  value: ProjectDuty | null;
  capabilities: Set<ProjectMemberCapability>;
  onChange?: (duty: ProjectDuty | null) => void;
};

const DUTY_SET_CAPABILITY: Record<ProjectDuty, ProjectMemberCapability> = {
  L: 'dutySetLead',
  S: 'dutySetStakeholder',
};

const DUTY_CLEAR_CAPABILITY: Record<ProjectDuty, ProjectMemberCapability> = {
  L: 'dutyClearLead',
  S: 'dutyClearStakeholder',
};

const DUTY_NAMES: Record<ProjectDuty, string> = {
  L: 'Lead',
  S: 'Stakeholder',
};

// TODO(@dbowring): Not offering stakeholder for now
const DUTY_ORDER: ProjectDuty[] = ['L'];
const dutyInputCapabilities = [
  'dutySetLead',
  'dutyClearLead',
  'dutySetStakeholder',
  'dutyClearStakeholder',
] as const;
type DutyInputCapabilities = (typeof dutyInputCapabilities)[number];

const getCapabilitiesForProjectDutyInput = (
  capabilities: (string | null)[]
): Set<DutyInputCapabilities> => {
  return new Set(
    capabilities.filter(
      (cap): cap is DutyInputCapabilities =>
        dutyInputCapabilities.indexOf(cap as DutyInputCapabilities) >= 0
    )
  );
};

const ProjectDutyInput = ({
  value,
  capabilities,
  onChange,
}: ProjectDutyInputParams) => {
  const hasClearCapability = value
    ? capabilities.has(DUTY_CLEAR_CAPABILITY[value])
    : true;

  const handleChange = (role?: string) => {
    // Radix UI requires a string for an option value but does not allow for empty string
    // Upcoming release may fix https://github.com/radix-ui/primitives/pull/2174
    // Using a placeholder value of SELECT_EMPTY_STRING_PLACEHOLDER ('---') to reset

    const roleValue = role === SELECT_EMPTY_STRING_PLACEHOLDER ? '' : role;
    const result = projectDutySchema.or(z.literal('')).safeParse(roleValue ?? '');
    if (!result.success) {
      console.error('Unexpected role selected', roleValue, result.error);
      return;
    }
    return onChange?.(result.data || null);
  };

  if (!hasClearCapability) {
    return <span>{value ? PROJECT_DUTY_NAMES[value] : '(none)'}</span>;
  }

  return (
    <Select
      className={styles.selectBox}
      contentClassName={styles.selectBoxContent}
      onValueChange={handleChange}
      placeholder={value ? DUTY_NAMES[value] : SELECT_EMPTY_STRING_PLACEHOLDER}
      selectGroups={[
        {
          options: [
            {
              label: SELECT_EMPTY_STRING_PLACEHOLDER,
              value: SELECT_EMPTY_STRING_PLACEHOLDER,
            },
            ...DUTY_ORDER.map((role) => ({
              label: DUTY_NAMES[role],
              value: role,
              isDisabled: !capabilities.has(DUTY_SET_CAPABILITY[role]),
            })),
          ],
        },
      ]}
      showIndicator
      value={value ? (value as string) : undefined}
    />
  );
};

function sortMembershipByLabel(
  membership1: UserProjectMembership,
  membership2: UserProjectMembership
) {
  const label1 = membership1.project.label.toLowerCase();
  const label2 = membership2.project.label.toLowerCase();
  if (label1 < label2) return -1;
  if (label1 > label2) return 1;
  return 0;
}

const ProjectListing = ({
  workspaceId,
  member,
}: {
  workspaceId: string;
  member: WorkspaceMember;
}) => {
  const { data: paginator, status } = useUserProjectMemberships({
    userId: member.id,
    workspaceId,
  });
  const { mutateAsync: patchProjectMembership } = usePatchProjectMembership();
  const { mutateAsync: removeUserFromProject } = useRemoveUserFromProject();
  const confirmDeleteModal = useDeleteConfirmModal<UserProjectMembership>({
    onConfirm: async ({ project }) => {
      try {
        await removeUserFromProject({ projectId: project.id, userId: member.id });
      } catch (error) {
        return toastApiErrorOr(error, 'Failed to remove project membership', {
          iconVariant: 'warning',
          titleText: 'Member Removal Failed',
          bodyText:
            'An unknown error occurred while removing user from project. Please try again later',
        });
      }
      createToast({
        bodyText: 'Removed user from space',
        iconVariant: 'success',
      });
    },
  });

  const setRole = useCallback(
    async (projectId: string, userId: string, role: Role) => {
      try {
        await patchProjectMembership({ projectId, userId, role });
      } catch (error) {
        return toastApiErrorOr(
          error,
          'Failed to patch project membership for role update',
          {
            iconVariant: 'warning',
            titleText: 'Permission Update Failed',
            bodyText:
              'An unknown error occurred while updating user permissions. Please try again later',
          }
        );
      }
      createToast({
        bodyText: 'Updated permission',
        iconVariant: 'success',
      });
    },
    [patchProjectMembership]
  );

  const setDuty = useCallback(
    async (projectId: string, userId: string, duty: ProjectDuty | null) => {
      try {
        await patchProjectMembership({ projectId, userId, duty: duty || null });
      } catch (error) {
        return toastApiErrorOr(
          error,
          'Failed to patch project membership for duty update',
          {
            iconVariant: 'warning',
            titleText: 'Role Update Failed',
            bodyText:
              'An unknown error occurred while updating user role. Please try again later',
          }
        );
      }
      createToast({
        bodyText: 'Updated role',
        iconVariant: 'success',
      });
    },
    [patchProjectMembership]
  );

  if (status === 'error') {
    return <SettingsError />;
  }
  if (status === 'pending') {
    return <Spinner />;
  }

  const memberships = paginator.pages.flatMap((page) => page.memberships);

  if (memberships.length === 0) {
    return <div>Not a member of any spaces</div>;
  }

  return (
    <table className={styles.projectListingTable}>
      <thead>
        <tr>
          <th className={styles.label}>Space</th>
          <th className={styles.role}>Permission</th>
          <th className={styles.role}>Role</th>
          <th className={styles.actions} title="Actions" />
        </tr>
      </thead>
      <tbody>
        {memberships.sort(sortMembershipByLabel).map((membership) => (
          <tr key={membership.project.id}>
            <td>{membership.project.label}</td>
            <td>
              <RoleInput
                value={membership.role}
                capabilities={getCapabilitiesForRoleInput(
                  []
                  // TODO: membership.capabilities
                )}
                order={DEFAULT_ROLE_ORDER.filter((r) => r !== 'A')}
                onChange={(role) => setRole(membership.project.id, member.id, role)}
              />
            </td>
            <td>
              <ProjectDutyInput
                // Reset to '---' when toggle from value to undefined
                // Force re-render when key changes
                // https://github.com/radix-ui/primitives/issues/1569#issuecomment-1364447541
                key={`${member.id}${membership.project}${membership.duty}`}
                value={membership.duty}
                capabilities={getCapabilitiesForProjectDutyInput(
                  membership.capabilities
                )}
                onChange={(duty) =>
                  setDuty(membership.project.id, member.id, duty || null)
                }
              />
            </td>
            <td>
              <EditMenu>
                <DropdownMenuItem onSelect={() => confirmDeleteModal.open(membership)}>
                  Remove
                </DropdownMenuItem>
              </EditMenu>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

const ProjectListingModal = ({
  closeModal,
  ...params
}: {
  closeModal: () => void;
  workspaceId: string;
  member: WorkspaceMember;
}) => {
  return (
    <Dialog
      className={styles.projectListingModal}
      closeModal={closeModal}
      isOpen={true}
      maxWidth="45rem"
      modalHeading={`${params.member.name} roles`}
      padding="lg"
    >
      <div className={styles.content}>
        <ProjectListing {...params} />
        <div className={styles.projectListingModalFooter}>
          <Button size="sm" type="button" variant="secondary" onClick={closeModal}>
            Done
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

type UserPreviewProps = { user: { name: string; avatarUrl: string | null } };

export const UserPreview = ({ user }: UserPreviewProps) => {
  return (
    <div className={styles.profile}>
      <UserAvatar name={user.name} imageUrl={user.avatarUrl} size="sm" />
      <div className={styles.content}>
        <div className={styles.details}>
          <div className={styles.name}>{user.name}</div>
        </div>
      </div>
    </div>
  );
};

export function useProjectListingModal() {
  const { openModal, closeModal } = useModalManager();
  return {
    open: ({
      workspaceId,
      member,
    }: {
      workspaceId: string;
      member: WorkspaceMember;
    }) => {
      openModal({
        component: (
          <ProjectListingModal
            closeModal={closeModal}
            workspaceId={workspaceId}
            member={member}
          />
        ),
      });
    },
    close: closeModal,
  };
}

const MemberRow = forwardRef(
  (
    {
      member,
      workspaceId,
      openProjectListingModal,
      openConfirmDeleteModal,
    }: {
      member: WorkspaceMember;
      workspaceId: string;
      openProjectListingModal: (member: WorkspaceMember) => void;
      openConfirmDeleteModal: (userId: string) => void;
    },
    ref: Ref<HTMLTableRowElement>
  ) => {
    const { do: changeRole, complete } = useChangeRole();

    const performChange = async (role: Role) => {
      const result = await changeRole({ member, workspaceId, role });
      if (result?.status === 'failed') {
        createToast({
          titleText: 'Failed to update permission',
          bodyText: 'Please check available seats and viewers, and try again later.',
          iconVariant: 'warning',
        });
      }
    };

    const confirmCreateSeatModel = useConfirmModal<{ role: Role }>({
      onConfirm: async ({ role }) => performChange(role),
      title: 'Adding a seat',
      subtitle:
        "When you change a member's role from Viewer to Editor, Admin, or Owner on Spaceduck, an additional seat purchase may be necessary.",
      cancelText: 'Cancel',
      confirmText: 'Continue',
      confirmVariant: 'primary',
    });

    return (
      <tr ref={ref}>
        <td>
          <UserPreview user={member} />
        </td>
        <td>
          <RoleInput
            loading={!complete}
            value={member.role}
            capabilities={getCapabilitiesForRoleInput(member.capabilities)}
            onChange={async (role) => {
              if (member.role === 'V' && role !== 'V') {
                return confirmCreateSeatModel.open({ role });
              }
              return performChange(role);
            }}
          />
        </td>
        <td>
          <Button
            onClick={() => openProjectListingModal(member)}
            variant="link"
            disabled={member.projectCount === 0}
          >
            {member.projectCount} {`project${member.projectCount !== 1 ? 's' : ''}`}
          </Button>
        </td>
        <td>
          <EditMenu>
            <DropdownMenuItem onSelect={() => openConfirmDeleteModal(member.id)}>
              Remove
            </DropdownMenuItem>
          </EditMenu>
        </td>
      </tr>
    );
  }
);

type PeopleTableProps = {
  members: WorkspaceMember[];
  lastRef: ReturnType<typeof useInView>['ref'];
  workspaceId: string;
};

const PeopleTable = ({ members, lastRef, workspaceId }: PeopleTableProps) => {
  const { mutateAsync: deleteWorkspaceMember } = useDeleteWorkspaceMember();
  const confirmDeleteModal = useDeleteConfirmModal<{
    workspaceId: string;
    userId: string;
  }>({
    onConfirm: async (params) => {
      await deleteWorkspaceMember(params);
      const member = members.find((member) => member.id === params.userId);

      createToast({
        titleText: 'Removed successfully',
        // TODO: Replace name with email address
        bodyText: `${member?.name ?? 'User'} has been successfully removed and can no longer access this workspace.`,
        iconVariant: 'success',
      });
    },
  });
  const { open: openProjectListing } = useProjectListingModal();
  const lastIndex = members.length - 1;
  return (
    <table className={clsx(styles.table, styles.membersTable)}>
      <thead>
        <tr>
          <th className={styles.label}>User</th>
          <th className={styles.role}>Role</th>
          <th className={styles.projects}>Spaces</th>
          <th className={styles.actions} title="Actions" />
        </tr>
      </thead>
      <tbody>
        {members.map((member, index) => (
          <MemberRow
            ref={index === lastIndex ? lastRef : null}
            workspaceId={workspaceId}
            member={member}
            key={index}
            openConfirmDeleteModal={(userId) =>
              confirmDeleteModal.open({
                workspaceId,
                userId,
              })
            }
            openProjectListingModal={(member) =>
              openProjectListing({ workspaceId, member })
            }
          />
        ))}
      </tbody>
    </table>
  );
};

const PeopleManager = ({ workspaceId }: { workspaceId: string | null }) => {
  const { ref, inView } = useInView();
  const [searchQuery, setSearchQuery] = useState('');
  const {
    data: paginator,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    status,
  } = useWorkspaceMembers(workspaceId, searchQuery);
  const pageCount = paginator?.pages.length ?? 0;
  const { debouncedSetSearchQuery } = useDebouncedSearch(setSearchQuery);

  useEffect(() => {
    if (inView && hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [inView, hasNextPage, pageCount, fetchNextPage, isFetchingNextPage]);

  if (status === 'error') {
    return <SettingsError />;
  }

  const lastPage = paginator?.pages.at(-1)!;

  if (lastPage?.total === 0) {
    return <NoMembers />;
  }

  const members = paginator?.pages.flatMap((page) => page.members);

  return (
    <div className={styles.peopleManager}>
      <Search
        defaultValue={searchQuery}
        onInput={(e) => debouncedSetSearchQuery(e.currentTarget.value)}
        placeholder="Search for people..."
        status={status}
      />
      {workspaceId === null || status === 'pending' ? (
        <div className={styles.statusMessage}>Searching...</div>
      ) : (
        <>
          {members?.length ? (
            <>
              <PeopleTable members={members} lastRef={ref} workspaceId={workspaceId} />
              {isFetchingNextPage && (
                <div className={styles.statusMessage}>Loading more...</div>
              )}
            </>
          ) : (
            <div className={styles.statusMessage}>No matches found.</div>
          )}
        </>
      )}
    </div>
  );
};

const InvitesTable = ({ workspaceId }: { workspaceId: string | null }) => {
  const { ref, inView } = useInView();
  const {
    data: paginator,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    status,
  } = useWorkspaceInvites(workspaceId, '');

  const { mutateAsync: resendWorkspaceInvite } = useResendWorkspaceInvite();
  const { mutateAsync: deleteWorkspaceInvite } = useDeleteWorkspaceInvite();
  const confirmDeleteModal = useDeleteConfirmModal<{
    inviteId: string;
    email: string;
  }>({
    onConfirm: async ({ inviteId, email }) => {
      try {
        await deleteWorkspaceInvite({ inviteId });
      } catch (error) {
        return toastApiErrorOr(error, 'Failed to delete workspace invite', {
          iconVariant: 'warning',
          titleText: 'Invitation Delete Failed',
          bodyText:
            'An unknown error occurred while deleting workspace invitation. Please try again later',
        });
      }
      createToast({
        iconVariant: 'success',
        titleText: 'Canceled invitation',
        bodyText: `The invitation for ${email} was canceled.`,
      });
    },
  });

  const pageCount = paginator?.pages.length ?? 0;
  useEffect(() => {
    if (inView && hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [inView, hasNextPage, pageCount, fetchNextPage, isFetchingNextPage]);

  const resend = async (invite: { id: string; email: string }) => {
    try {
      await resendWorkspaceInvite({ inviteId: invite.id });
    } catch (error) {
      return toastApiErrorOr(error, 'Failed to resend workspace invite', {
        iconVariant: 'warning',
        titleText: 'Invite Error',
        bodyText:
          'An unknown error occurred while resending workspace invite. Please try again later',
      });
    }
    createToast({
      iconVariant: 'success',
      titleText: 'Invite re-sent',
      bodyText: `An invitation has been successfully re-sent to ${invite.email}`,
    });
  };

  if (status === 'error') {
    return (
      <Container className={styles.verticallyCentered}>
        <SettingsError />
      </Container>
    );
  }

  if (status === 'pending') {
    return <Spinner />;
  }

  const invites = paginator.pages.flatMap((page) => page.invites);

  if (invites.length === 0) {
    return (
      <Container className={styles.verticallyCentered}>
        <NoInvites workspaceId={workspaceId} />
      </Container>
    );
  }

  const lastIndex = invites.length - 1;

  return (
    <Container>
      <Header className={styles.noMargin}>
        <h1>People</h1>
        <p>Manage the members of your workspace.</p>
      </Header>
      <table className={clsx(styles.table, styles.invitesTable)}>
        <thead>
          <tr>
            <th className={styles.label}>User</th>
            <th className={styles.role}>Role</th>
            <th className={styles.actions} title="Actions" />
          </tr>
        </thead>
        <tbody>
          {invites.map((invite, index) => (
            <tr key={invite.id} ref={index === lastIndex ? ref : null}>
              <td>
                <div className={styles.profile}>
                  <UserAvatar name={invite.email} size="sm" isMuted />
                  <div className={styles.content}>
                    <div className={styles.details}>
                      <div className={styles.name}>{invite.email}</div>
                    </div>
                    <Badge size="sm" className={styles.badge}>
                      Pending
                    </Badge>
                  </div>
                </div>
              </td>
              <td>
                <RoleInput
                  value={invite.role}
                  // TODO(@dbowring): STRETCH: Support role update
                  capabilities={getCapabilitiesForRoleInput([])}
                />
              </td>
              <td>
                <EditMenu>
                  <DropdownMenuItem
                    onSelect={() => resend(invite)}
                    disabled={!invite.capabilities.includes('resend')}
                  >
                    Resend invite
                  </DropdownMenuItem>
                  <DropdownMenuItem
                    onSelect={() =>
                      confirmDeleteModal.open({
                        inviteId: invite.id,
                        email: invite.email,
                      })
                    }
                    disabled={!invite.capabilities.includes('delete')}
                  >
                    Cancel invite
                  </DropdownMenuItem>
                </EditMenu>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {isFetchingNextPage && (
        <div className={styles.statusMessage}>Loading more...</div>
      )}
    </Container>
  );
};

export default function PeopleManagerPage() {
  const workspaceId = useWorkspaceId();
  const { open } = useCreateInviteModal(workspaceId);
  const { data: subscriptionInfo, isLoading: subscriptionInfoLoading } =
    useGetSubscriptionInfo(workspaceId);
  const { open: openSeatManagementModal } = useSeatManagementModal(subscriptionInfo);
  const [activeTab, setActiveTab] = useState<'members' | 'invited'>('members');

  return (
    <>
      <TopNav
        currentBreadcrumb="People"
        buttonOnClick={open}
        buttonText="Invite people"
        owner="workspace"
        showAddIcon
        title="Manage People"
      />
      <SubNav
        actions={
          !subscriptionInfoLoading && subscriptionInfo?.plan ? (
            <Button onClick={openSeatManagementModal} size="sm" variant="outlined">
              {`${subscriptionInfo.assignedSeats}/${subscriptionInfo.seats} Contributor seats used`}
            </Button>
          ) : undefined
        }
      >
        <ControlledTabButtonWrapper
          activeTab={activeTab}
          options={[
            {
              label: 'Members',
              onClick: () => {
                setActiveTab('members');
              },
              value: 'members',
            },
            {
              label: 'Invited',
              onClick: () => {
                setActiveTab('invited');
              },
              value: 'invited',
            },
          ]}
          nav="SubNav"
          variant="ghost"
        />
      </SubNav>
      {activeTab === 'members' && (
        <Container>
          <Header>
            <h1>People</h1>
            <p>Manage the members of your workspace.</p>
          </Header>
          <PeopleManager workspaceId={workspaceId} />
        </Container>
      )}
      {activeTab === 'invited' && <InvitesTable workspaceId={workspaceId} />}
    </>
  );
}

const NoMembers = () => {
  return (
    <SettingsError>
      <HashTag />
      <h2 className="h6">No workspace members yet...</h2>
      <p className="body5">Somehow not even you are a member?</p>
    </SettingsError>
  );
};

const NoInvites = ({ workspaceId }: { workspaceId: string | null }) => {
  const { open } = useCreateInviteModal(workspaceId);

  return (
    <NoEntries icon={<Invite />}>
      <h1>No pending invites</h1>
      <p>N I C E ...</p>
      {workspaceId && (
        <Button onClick={open} size="sm" variant="primary">
          Invite people
        </Button>
      )}
    </NoEntries>
  );
};

import { useListMediaGroups, usePatchMediaGroup } from '@/api/mediaGroup';
import { useProject } from '@/api/project';
import { toastApiErrorOr } from '@/api/util';
import { useMediaGroupContextMenu } from '@/components/MediaGroupActionMenu';
import { NewMediaGroupDropdown } from '@/components/NewMediaGroupDropdown';
import { STATUS_SPECIAL_VALUES } from '@/components/StatusSelect';
import MediaGroupStatusIcon from '@/components/icons/MediaGroupStatus';
import Button from '@/components/ui/Button';
import Card from '@/components/ui/Card';
import ContextMenu from '@/components/ui/ContextMenu';
import { useCheckMediaGroupUpdated } from '@/hooks/useCheckMediaGroupUpdated';
import useWorkspaceId from '@/hooks/useWorkspaceId';
import createToast from '@/utils/createToast';
import type {
  MediaGroupDTO,
  MediaGroupSortOption,
  MediaGroupStatus,
} from '@spaceduck/api';
import { Icon16 } from '@spaceduck/icons';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { create } from 'zustand';
import { useShallow } from 'zustand/shallow';
import styles from './Kanban.module.scss';

const { Add } = Icon16;

type MediaGroupsByStatus = Record<string, MediaGroupDTO[]>;
type KanbanStore = {
  mediaGroupsByStatus: MediaGroupsByStatus;
  setMediaGroupsByStatus: (mediaGroupsByStatus: MediaGroupsByStatus) => void;
  updateMediaGroupsByStatus: (status: string, groups: MediaGroupDTO[]) => void;
};

const useKanbanStore = create<KanbanStore>()((set) => ({
  mediaGroupsByStatus: {},
  setMediaGroupsByStatus: (mediaGroupsByStatus: MediaGroupsByStatus) =>
    set(() => ({ mediaGroupsByStatus })),
  updateMediaGroupsByStatus: (status: string, groups: MediaGroupDTO[]) =>
    set(({ mediaGroupsByStatus: current }) => ({
      mediaGroupsByStatus: { ...current, [status]: groups },
    })),
}));

const KanbanCard = ({ mediaGroup }: { mediaGroup: MediaGroupDTO }) => {
  const mgMenuItems = useMediaGroupContextMenu({ mediaGroup });
  return (
    <ContextMenu items={mgMenuItems}>
      <Card mediaGroup={mediaGroup} />
    </ContextMenu>
  );
};

const StatusColumn = ({
  status,
  projectId,
  sortBy,
}: {
  status: MediaGroupStatus;
  projectId: string;
  sortBy: MediaGroupSortOption;
}) => {
  const workspaceId = useWorkspaceId();
  const {
    data: mediaGroupsData,
    hasNextPage,
    fetchNextPage,
  } = useListMediaGroups(workspaceId, {
    status: [status.id],
    project: [projectId],
    sort: sortBy,
  });

  const { mediaGroupsByStatus, updateMediaGroupsByStatus } = useKanbanStore(
    useShallow((state) => ({
      mediaGroupsByStatus: state.mediaGroupsByStatus,
      updateMediaGroupsByStatus: state.updateMediaGroupsByStatus,
    }))
  );

  const [enabled, setEnabled] = useState(import.meta.env.MODE === 'production');

  // Re-order by dragging in development mode fix
  // Source - https://stackoverflow.com/questions/67780314/react-beautiful-dnd-invariant-failed-cannot-find-droppable-entry-with-id
  useEffect(() => {
    const animation = requestAnimationFrame(() => setEnabled(true));

    return () => {
      cancelAnimationFrame(animation);
      setEnabled(false);
    };
  }, []);

  const mediaGroups = useMemo(() => {
    return mediaGroupsData?.pages.flatMap((page) => page.mediaGroups) || [];
  }, [mediaGroupsData]);

  const updatedMediaGroupMap = useCheckMediaGroupUpdated(mediaGroups);

  useEffect(() => {
    updateMediaGroupsByStatus(
      status.id || STATUS_SPECIAL_VALUES.NO_STATUS,
      mediaGroups
    );
  }, [mediaGroups]);

  if (!enabled) {
    return;
  }
  return (
    <Droppable droppableId={status.id ?? STATUS_SPECIAL_VALUES.NO_STATUS}>
      {(provided, snapshot) => {
        return (
          <div className={styles.statusColumn}>
            <div className={styles.statusColHeader}>
              <div className={styles.statusInfo}>
                <MediaGroupStatusIcon status={status} />
                <span className={styles.statusLabel}>{status.label}</span>
                <span className={styles.statusItemCount}>{status.itemsCount}</span>
              </div>
              <NewMediaGroupDropdown mediaGroupAttributes={{ statusId: status.id }}>
                <Button variant="icon">
                  <Add />
                </Button>
              </NewMediaGroupDropdown>
            </div>
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              className={styles.mediaGroupList}
            >
              {mediaGroupsByStatus?.[status.id ?? STATUS_SPECIAL_VALUES.NO_STATUS]?.map(
                (mediaGroup, i) => {
                  const mg = updatedMediaGroupMap[mediaGroup.id] || mediaGroup;
                  return (
                    <Draggable
                      index={i}
                      key={mediaGroup.id}
                      draggableId={mediaGroup.id}
                    >
                      {(provided) => {
                        return (
                          <div
                            className={styles.cardWrapper}
                            ref={provided.innerRef}
                            {...provided.dragHandleProps}
                            {...provided.draggableProps}
                          >
                            <KanbanCard mediaGroup={mg} />
                          </div>
                        );
                      }}
                    </Draggable>
                  );
                }
              )}
              {hasNextPage && (
                <Button
                  size="sm"
                  onClick={() => fetchNextPage()}
                  variant="outlined"
                  className={styles.addMediaGroupButton}
                >
                  Load more
                </Button>
              )}
              {!hasNextPage && (
                <NewMediaGroupDropdown mediaGroupAttributes={{ statusId: status.id }}>
                  <Button
                    size="sm"
                    variant="outlined"
                    className={styles.addMediaGroupButton}
                  >
                    <Add />
                  </Button>
                </NewMediaGroupDropdown>
              )}
            </div>
            {provided.placeholder}
            {snapshot.isDraggingOver && (
              <div className={styles.draggingOverOverlay}>
                <div>Ordered by the sort filter</div>
              </div>
            )}
          </div>
        );
      }}
    </Droppable>
  );
};

const changeStatusLocally = ({
  mediaGroupId,
  fromId,
  mediaGroupsByStatus,
  toId,
}: {
  mediaGroupsByStatus: MediaGroupsByStatus;
  fromId: string;
  toId: string;
  mediaGroupId: string;
}) => {
  const previousMediaGroup = mediaGroupsByStatus[fromId]?.find((mediaGroup) => {
    return mediaGroup.id === mediaGroupId;
  });
  if (previousMediaGroup) {
    mediaGroupsByStatus[toId]?.push(previousMediaGroup);

    mediaGroupsByStatus[fromId] =
      mediaGroupsByStatus[fromId]?.filter(
        (mediaGroup) => mediaGroup.id !== mediaGroupId
      ) || [];
  }
  return mediaGroupsByStatus;
};

export const ProgressKanban = ({
  projectId,
  sortBy,
}: {
  projectId: string;
  sortBy: MediaGroupSortOption;
}) => {
  const { data: project } = useProject(projectId);
  const { mediaGroupsByStatus, setMediaGroupsByStatus } = useKanbanStore(
    useShallow((state) => ({
      mediaGroupsByStatus: state.mediaGroupsByStatus,
      setMediaGroupsByStatus: state.setMediaGroupsByStatus,
    }))
  );
  const { mutateAsync: patchMediaGroup } = usePatchMediaGroup();
  const handleStatusUpdate = useCallback(
    async (fromStatusId: string, mediaGroupId: string, statusId: string | null) => {
      if (fromStatusId === statusId) return;
      try {
        await patchMediaGroup({
          mediaGroupId,
          patch: { status: statusId },
        });
      } catch (error) {
        setMediaGroupsByStatus(
          changeStatusLocally({
            mediaGroupsByStatus,
            fromId: statusId || STATUS_SPECIAL_VALUES.NO_STATUS,
            toId: fromStatusId,
            mediaGroupId,
          })
        );
        return toastApiErrorOr(error, 'Failed to update media group status', {
          iconVariant: 'warning',
          titleText: 'Status Update Error',
          bodyText:
            'An unknown error occurred while updating status. Please try again later',
        });
      }
      createToast({
        titleText: 'Status updated',
        bodyText: 'Status was updated',
        iconVariant: 'success',
      });
    },
    [patchMediaGroup]
  );
  return (
    <div className={styles.kanbanContainer}>
      <DragDropContext
        onDragEnd={async ({ draggableId, destination, source }) => {
          if (!destination) {
            return;
          }
          if (mediaGroupsByStatus === undefined) {
            return;
          }
          if (destination.droppableId === source.droppableId) {
            return;
          }

          setMediaGroupsByStatus(
            changeStatusLocally({
              mediaGroupsByStatus,
              fromId: source.droppableId,
              toId: destination.droppableId,
              mediaGroupId: draggableId,
            })
          );
          await handleStatusUpdate(
            source.droppableId,
            draggableId,
            destination.droppableId !== STATUS_SPECIAL_VALUES.NO_STATUS
              ? destination?.droppableId
              : null
          );
        }}
      >
        {project?.project.mediaGroupStatuses.map((status) => (
          <StatusColumn
            key={status.id}
            status={status}
            projectId={projectId}
            sortBy={sortBy}
          />
        ))}
      </DragDropContext>
    </div>
  );
};

import type { MediaGroupDetailDTO } from '@spaceduck/api';
import { Icon24 } from '@spaceduck/icons';

import { useDeleteMediaGroup } from '@api/mediaGroup';
import { isCapable } from '@api/util';
import useDeleteMediaGroupConfirmModal from '@hooks/useDeleteMediaGroupConformModal';
import Button from '@ui/Button';
import DropdownMenu, { MenuItem } from '@ui/DropdownMenu';
import { copyTextToClipboard } from '@utils/copyToClipboard';
import createToast from '@utils/createToast';
import { urlFor } from '@/urls';
import styles from './ExtendedPreviewCard.module.scss';
import MediaGroupPreview from '@/components/MediaGroupPreview';
import { DetailsModalTooltip } from '../comments/DetailsModalTooltip';

const { AlertInfo, Close, Menu, Link, Open, Swap, TrashCan } = Icon24;

type ExtendedPreviewCardProps = {
  className?: string;
  containerClassName?: string;
  hasStickyHeader?: boolean;
  mediaGroup: MediaGroupDetailDTO;
  onClose?: () => void;
  onDelete?: () => void;
  onSwapClick?: () => void;
  onViewInfoClick?: () => void;
};

export default function ExtendedPreviewCard({
  className,
  containerClassName,
  hasStickyHeader = true,
  mediaGroup,
  onClose,
  onDelete,
  onSwapClick,
  onViewInfoClick,
}: ExtendedPreviewCardProps) {
  const userCanEdit = isCapable('edit', mediaGroup?.userCapabilities).capable;

  const { mutateAsync: deleteMutation } = useDeleteMediaGroup();
  const mediaGroupId = mediaGroup.id;

  const openDeleteMediaGroupConfirmModal = useDeleteMediaGroupConfirmModal({
    onConfirm: async (id: string) => {
      await deleteMutation(id);
    },
    onSuccess: () => {
      createToast({
        bodyText: 'Item deleted',
        iconVariant: 'success',
      });
      onDelete?.();
    },
  });

  return (
    <MediaGroupPreview
      className={className}
      containerClassName={containerClassName}
      hasStickyHeader={hasStickyHeader}
      mediaGroup={mediaGroup}
      menu={
        <>
          {!!onSwapClick && (
            <DetailsModalTooltip content="Swap views" align="end">
              <Button onClick={onSwapClick} variant="icon">
                <Swap size={20} />
              </Button>
            </DetailsModalTooltip>
          )}
          <DetailsModalTooltip content="Info" align="end">
            <Button onClick={onViewInfoClick} variant="icon">
              <AlertInfo size={20} />
            </Button>
          </DetailsModalTooltip>
          {userCanEdit && (
            <DetailsModalTooltip content="Show menu" align="end">
              <span>
                <DropdownMenu
                  align="end"
                  className={styles.dropdownMenu}
                  isPadded
                  width={260}
                  triggerContent={
                    <Button variant="icon">
                      <Menu size={20} />
                    </Button>
                  }
                >
                  <MenuItem
                    iconBefore={<Open size={20} />}
                    onSelect={() => {
                      window.open(
                        urlFor('mediaGroup', {
                          mediaGroupId,
                        })
                      );
                    }}
                  >
                    Open in new tab
                  </MenuItem>
                  <MenuItem
                    iconBefore={<Link size={20} />}
                    onSelect={() => {
                      copyTextToClipboard(
                        new URL(
                          urlFor('mediaGroup', {
                            mediaGroupId,
                          }),
                          window.location.origin
                        ).toString(),
                        {
                          titleText: undefined,
                          bodyText: 'Content Block link copied',
                        }
                      );
                    }}
                  >
                    Copy link
                  </MenuItem>
                  <MenuItem
                    iconBefore={<TrashCan size={20} />}
                    onSelect={() => openDeleteMediaGroupConfirmModal(mediaGroupId)}
                  >
                    Delete
                  </MenuItem>
                </DropdownMenu>
              </span>
            </DetailsModalTooltip>
          )}
          <DetailsModalTooltip content="Show Content Block list" align="end">
            <Button onClick={onClose} variant="icon">
              <Close size={20} />
            </Button>
          </DetailsModalTooltip>
        </>
      }
    />
  );
}

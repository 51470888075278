import { useContext, useState } from 'react';
import clsx from 'clsx';
import { Position, type NodeProps } from '@xyflow/react';

import { useMediaGroupDetail } from '@api/mediaGroup';
import FileBlock from '@components/FileBlock';
import Spinner from '@ui/Spinner';
import Handle from '../components/Handle';
import { BoardStoreContext } from '../context/boardContext';
import { useBoardStore } from '../hooks/useBoardStore';
import { useCardUtils } from '../hooks/useCardUtils';
import type { FileNodeType } from '../types/board';
import styles from './FileNode.module.scss';

export const FileNode = (props: NodeProps<FileNodeType>) => {
  const {
    data: { mediaGroupId },
  } = props;
  const { data, isLoading } = useMediaGroupDetail(mediaGroupId);
  const [showHandles, setShowHandles] = useState(false);

  const selectedNodes = useBoardStore((state) => state.selectedNodes);
  const isSelected = selectedNodes.includes(props.id);

  const context = useContext(BoardStoreContext);
  const { openCardInSidebar } = useCardUtils();
  const handleDoubleClick = () => {
    openCardInSidebar({
      cardMediaGroupId: mediaGroupId,
      boardMediaGroupId: context?.mediaGroupId,
    });
  };

  return (
    <div
      className="nowheel"
      onMouseOver={() => setShowHandles(true)}
      onMouseOut={() => setShowHandles(false)}
      onFocus={() => setShowHandles(true)}
      onBlur={() => setShowHandles(false)}
    >
      <Handle
        id={`${props.id}--handle-source-top`}
        type="source"
        position={Position.Top}
        className={clsx(styles.handle, !isSelected && showHandles && styles.isVisible)}
      />
      <Handle
        id={`${props.id}--handle-source-right`}
        type="source"
        position={Position.Right}
        className={clsx(styles.handle, !isSelected && showHandles && styles.isVisible)}
      />
      <Handle
        id={`${props.id}--handle-source-bottom`}
        type="source"
        position={Position.Bottom}
        className={clsx(styles.handle, !isSelected && showHandles && styles.isVisible)}
      />
      <Handle
        id={`${props.id}--handle-source-left`}
        type="source"
        position={Position.Left}
        className={clsx(styles.handle, !isSelected && showHandles && styles.isVisible)}
      />
      <Handle
        id={`${props.id}--handle-target-top`}
        type="target"
        position={Position.Top}
        className={clsx(styles.handle, !isSelected && showHandles && styles.isVisible)}
      />
      <Handle
        id={`${props.id}--handle-target-right`}
        type="target"
        position={Position.Right}
        className={clsx(styles.handle, !isSelected && showHandles && styles.isVisible)}
      />
      <Handle
        id={`${props.id}--handle-target-bottom`}
        type="target"
        position={Position.Bottom}
        className={clsx(styles.handle, !isSelected && showHandles && styles.isVisible)}
      />
      <Handle
        id={`${props.id}--handle-target-left`}
        type="target"
        position={Position.Left}
        className={clsx(styles.handle, !isSelected && showHandles && styles.isVisible)}
      />
      {isLoading ? (
        <div>
          <Spinner />
        </div>
      ) : (
        <FileBlock
          mediaGroup={data?.mediaGroup}
          onDoubleClick={handleDoubleClick}
          orientation="portrait"
        />
      )}
    </div>
  );
};

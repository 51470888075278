import clsx from 'clsx';
import { Link, Outlet } from 'react-router';

import { Icon24 } from '@spaceduck/icons';
import styles from './Support.module.scss';
const { LeftArrow } = Icon24;

export default function SupportLayout() {
  return (
    <div className={styles.wrapper}>
      <div className={styles.lightWrapper}>
        <div className={styles.light} />
      </div>
      <div className={clsx('container', styles.back)}>
        <Link to="/">
          <LeftArrow size={16} />
          Back to Spaceduck
        </Link>
      </div>
      <div className={clsx('container', styles.content)}>
        <main>
          <Outlet />
        </main>
      </div>
    </div>
  );
}

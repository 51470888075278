import { Navigate, useLocation } from 'react-router';

import { useSession } from '@hooks/useAuth';

export default function RequireAuth({
  children,
}: {
  children: React.ReactNode;
}) {
  const session = useSession();
  const location = useLocation();
  const encodedUrl = encodeURIComponent(window.location.href) || '/';

  if (session?.kind !== 'authenticated') {
    return (
      <Navigate to={`/login?next=${encodedUrl}`} state={{ from: location }} replace />
    );
  }

  return children;
}

import * as Popover from '@radix-ui/react-popover';
import { useEffect } from 'react';

import type { MediaGroupFiltersSchema } from '@spaceduck/api';

import { useGetSearchDateSuggestions } from '@api/mediaGroup';
import { type SearchQuery, useSearch } from '@hooks/useSearch';
import DatePicker from '@ui/DatePicker';
import useWorkspaceId from '@hooks/useWorkspaceId';
import { getDisplayDate } from '@utils/date';
import styles from './SearchDatePickerPopover.module.scss';

type SearchDatePickerPopoverBaseProps = {
  datePickerOpen: boolean;
  dateRange: { start?: Date; end?: Date };
  defaultFilters?: MediaGroupFiltersSchema;
  searchInputValue: string;
  setDatePickerOpen: (open: boolean) => void;
  setDateRange: (range: { start?: Date; end?: Date }) => void;
};

type SearchProps = {
  excludeProjectLibraries: boolean;
  mediaGroupFilters: MediaGroupFiltersSchema;
  searchQueries: SearchQuery[];
  setSearchInputValue:
    | React.Dispatch<React.SetStateAction<string>>
    | ((searchInputValue: string) => void);
  setSearchQueries: (searchQueries: SearchQuery[]) => void;
};

type GlobalSearchbarProps = {
  isLocalSearch?: false;
  localSearchProps?: null;
};

type LocalSearchbarProps = {
  isLocalSearch: true;
  localSearchProps: SearchProps;
};

type SearchDatePickerPopoverProps = SearchDatePickerPopoverBaseProps &
  (GlobalSearchbarProps | LocalSearchbarProps);

export const SearchDatePickerPopover = (props: SearchDatePickerPopoverProps) => {
  if (props.isLocalSearch && props.localSearchProps) {
    return <LocalSearchDatePickerPopover {...props} />;
  }

  return <GlobalSearchDatePickerPopover {...props} />;
};

const LocalSearchDatePickerPopover = (
  props: SearchDatePickerPopoverBaseProps & LocalSearchbarProps
) => {
  const {
    mediaGroupFilters,
    excludeProjectLibraries,
    setSearchInputValue,
    setSearchQueries,
    searchQueries,
  } = props.localSearchProps;

  const searchProps = {
    mediaGroupFilters,
    excludeProjectLibraries,
    setSearchInputValue,
    setSearchQueries,
    searchQueries,
  };

  return <SearchDatePickerPopoverComponent {...props} {...searchProps} />;
};

const GlobalSearchDatePickerPopover = (
  props: SearchDatePickerPopoverBaseProps & GlobalSearchbarProps
) => {
  const {
    mediaGroupFilters,
    excludeProjectLibraries,
    setSearchInputValue,
    setSearchQueries,
    searchQueries,
  } = useSearch();

  const searchProps = {
    mediaGroupFilters,
    excludeProjectLibraries,
    setSearchInputValue,
    setSearchQueries,
    searchQueries,
  };

  return <SearchDatePickerPopoverComponent {...props} {...searchProps} />;
};

const SearchDatePickerPopoverComponent = ({
  datePickerOpen,
  dateRange,
  defaultFilters,
  setDatePickerOpen,
  searchInputValue,
  setDateRange,
  mediaGroupFilters,
  excludeProjectLibraries,
  setSearchInputValue,
  setSearchQueries,
  searchQueries,
}: SearchDatePickerPopoverProps & SearchProps) => {
  const workspaceId = useWorkspaceId();
  const { data: dateSuggestions, isLoading } = useGetSearchDateSuggestions({
    ...mediaGroupFilters,
    ...(defaultFilters || {}),
    workspace: workspaceId,
    excludeProjectLibraries,
  });
  const minDate = isLoading
    ? dateRange.start
    : dateRange.start || dateSuggestions?.minDatetime;
  const maxDate = isLoading
    ? dateRange.end
    : dateSuggestions?.maxDatetime || dateRange.end;

  useEffect(() => {
    if (dateRange.start && dateRange.end) {
      setSearchQueries([
        ...searchQueries,
        {
          filter: 'date',
          minDatetime: dateRange.start,
          maxDatetime: dateRange.end,
        },
      ]);
      setSearchInputValue('');
    }
  }, [dateRange]);
  return (
    <span>
      {searchInputValue} {dateRange.start && getDisplayDate(dateRange.start)}{' '}
      <Popover.Root open={datePickerOpen}>
        <Popover.Anchor asChild>
          <span className={styles.activeDateLabel}>
            {datePickerOpen && (dateRange.start ? ' - end date' : 'start date')}
          </span>
        </Popover.Anchor>
        <Popover.Portal>
          <Popover.Content>
            <DatePicker
              selectsStart={!dateRange.start}
              selectsEnd={dateRange.start && !dateRange.end}
              startDate={dateRange.start || minDate}
              minDate={minDate}
              maxDate={maxDate}
              onValueChange={(date) => {
                if (dateRange.start) {
                  const end = new Date(date.getTime());
                  end.setHours(23);
                  end.setMinutes(59);
                  end.setSeconds(59);
                  end.setMilliseconds(999);
                  setDateRange({
                    start: dateRange.start,
                    end,
                  });
                  setDatePickerOpen(false);
                } else {
                  const start = new Date(date.getTime());
                  start.setHours(0);
                  start.setMinutes(0);
                  start.setSeconds(0);
                  start.setMilliseconds(0);
                  setDateRange({
                    start,
                  });
                }
              }}
              onClickOutside={() => {
                setSearchInputValue('');
              }}
              onKeyDown={(e) => {
                if (e.key === 'Escape') {
                  setSearchInputValue('');
                  setDateRange({});
                  e.stopPropagation();
                }
              }}
              // biome-ignore lint/complexity/noUselessFragments: nullish means use default, but we want none
              customInput={<></>}
              open={datePickerOpen}
            />
          </Popover.Content>
        </Popover.Portal>
      </Popover.Root>
      {!dateRange.start && (
        <span className={styles.inactiveDateLabel}>{' - '}end date</span>
      )}
    </span>
  );
};

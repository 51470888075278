import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import { type Node, Position, useReactFlow, type NodeProps } from '@xyflow/react';
import type { Editor } from '@tiptap/core';
import { EditorContent, useEditor } from '@tiptap/react';

import { exists } from '@spaceduck/utils';

import { useMetaKey } from '@hooks/useMetaKey';
import { css } from '@lib/css';
import { useOnClickOutside } from '@hooks/useOnClickOutside';
import ContextMenu, { type ContextMenuItemProps } from '@ui/ContextMenu';
import ScrollArea from '@ui/ScrollArea';
import FloatingTextSelectionMenu from '../components/FloatingTextSelectionMenu';
import Handle from '../components/Handle';
import Resizer from '../components/Resizer';
import { useBoardStore } from '../hooks/useBoardStore';
import { DEFAULT_FLOATING_NOTE_WIDTH, useNodes } from '../hooks/useNodes';
import { boardFloatingTextExtensions } from '../../tiptap/extensions';
import type { FloatingTextNodeType } from '../types/board';
import styles from './FloatingTextNode.module.scss';
import { useIsInWebViewRoute } from '@/hooks/useIsWebView';

const findRelativePosition = (
  currentNode: Node,
  allNodes: Node[]
): { x: number; y: number } => {
  if (!currentNode?.parentId) {
    return { x: currentNode.position.x, y: currentNode.position.y };
  }

  const parentNode = allNodes.find((n) => n.id === currentNode.parentId);
  if (!parentNode) {
    return { x: currentNode.position.x, y: currentNode.position.y };
  }

  const parentPosition = findRelativePosition(parentNode, allNodes);

  return {
    x: parentPosition.x + currentNode.position.x,
    y: parentPosition.y + currentNode.position.y,
  };
};

export const FloatingTextNode = (props: NodeProps<FloatingTextNodeType>) => {
  const [showResizer, setShowResizer] = useState(false);
  const [isInEditMode, setIsInEditMode] = useState(props.data.isInEditMode ?? false);
  const editor = useEditor(
    {
      extensions: boardFloatingTextExtensions,
      content: props.data.content ?? undefined,
      editable: isInEditMode,
    },
    [isInEditMode, props.data.content]
  );

  const { containerRef } = useOnClickOutside<HTMLDivElement>({
    callback: () => {
      setIsInEditMode(false);
    },
  });

  useEffect(() => {
    const editorContent = editor?.getJSON();
    if (editor && !isInEditMode && props.data.content !== editorContent) {
      updateNode(props.id, {
        data: {
          content: editorContent ?? null,
        },
      });
    }
  }, [isInEditMode]);

  useEffect(() => {
    if (isInEditMode && editor) {
      editor.commands.focus();
    }
  }, [editor, isInEditMode]);

  const { copyNode, cutNode, deleteNode, duplicateNode, updateNode } = useNodes();
  const selectedNodes = useBoardStore((state) => state.selectedNodes);
  const { getNode, setNodes } = useReactFlow();

  const node = getNode(props.id);
  const parent = node?.parentId ? getNode(node.parentId) : undefined;
  const isDragging = node?.dragging || parent?.dragging;
  const isSelected = selectedNodes.includes(props.id);

  const removeFromFrame = useCallback(() => {
    setNodes((nodes) => {
      return nodes.map((node) => {
        if (selectedNodes.includes(node.id) && node.type !== 'groupNode') {
          const { x, y } = node ? findRelativePosition(node, nodes) : { x: 0, y: 0 };

          return {
            ...node,
            extent: undefined,
            parentId: undefined,
            position: {
              x,
              y,
            },
          };
        }

        return node;
      });
    });
  }, [selectedNodes, setNodes]);

  const copy = useCallback(() => copyNode(props.id), [props.id]);
  const cut = useCallback(() => cutNode(props.id), [props.id]);
  const deleteSelf = useCallback(() => deleteNode(props.id), [props.id]);
  const duplicate = useCallback(() => {
    duplicateNode(props.id);
    setShowResizer(false);
  }, [duplicateNode, setShowResizer]);

  const colorStyle = useMemo(() => {
    return node?.data?.boxColor ? styles[`${node.data.boxColor}`] : undefined;
  }, [node?.data]);

  const autoHeightDisabled = node?.data.autoHeightDisabled;
  const height = !autoHeightDisabled
    ? undefined
    : (node?.height ?? node.measured?.height);

  return (
    <div
      className={clsx(
        'nowheel',
        styles.container,
        isInEditMode && 'nodrag',
        isInEditMode && styles.isInEditMode,
        isSelected && styles.selected
      )}
      onMouseOver={() => setShowResizer(true)}
      onMouseOut={() => setShowResizer(false)}
      onFocus={() => setShowResizer(true)}
      onBlur={() => setShowResizer(false)}
      ref={containerRef}
      style={{
        width: node?.width ?? DEFAULT_FLOATING_NOTE_WIDTH,
        height,
      }}
    >
      <Resizer
        isVisible={isSelected && !isDragging}
        maxHeight={autoHeightDisabled ? undefined : height}
      />
      <Card
        colorStyle={colorStyle}
        copy={copy}
        cut={cut}
        deleteSelf={deleteSelf}
        duplicate={duplicate}
        editor={editor}
        id={props.id}
        isInEditMode={isInEditMode}
        isSelected={isSelected}
        node={node}
        removeFromFrame={removeFromFrame}
        setIsInEditMode={setIsInEditMode}
        showResizer={showResizer}
        updateNode={updateNode}
      />
    </div>
  );
};

function createContextMenu({
  copy,
  cut,
  deleteSelf,
  duplicate,
  isInFrame,
  removeFromFrame,
}: {
  copy: () => void;
  cut: () => void;
  deleteSelf: () => void;
  duplicate: () => void;
  isInFrame: boolean;
  removeFromFrame: () => void;
}) {
  const metaKey = useMetaKey();
  const isInWebviewRoute = useIsInWebViewRoute();

  const contextMenu: ContextMenuItemProps[] = [
    {
      content: 'Cut',
      onClick: cut,
      shortcut: isInWebviewRoute ? undefined : `${metaKey} X`,
    },
    {
      content: 'Copy',
      onClick: copy,
      shortcut: isInWebviewRoute ? undefined : `${metaKey} C`,
    },
    {
      content: 'Duplicate',
      onClick: duplicate,
      shortcut: isInWebviewRoute ? undefined : `${metaKey} D`,
    },
    {
      content: 'Remove from board',
      onClick: deleteSelf,
      shortcut: isInWebviewRoute ? undefined : 'Del',
    },
    isInFrame
      ? {
          content: 'Remove from Frame',
          onClick: removeFromFrame,
        }
      : undefined,
  ].filter(exists);

  return contextMenu;
}

const Card = memo(
  ({
    colorStyle,
    copy,
    cut,
    deleteSelf,
    duplicate,
    editor,
    id,
    isInEditMode,
    isSelected,
    node,
    removeFromFrame,
    setIsInEditMode,
    showResizer,
    updateNode,
  }: {
    colorStyle?: string;
    copy: () => void;
    cut: () => void;
    deleteSelf: () => void;
    duplicate: () => void;
    editor: Editor | null;
    isInEditMode: boolean;
    isSelected: boolean;
    id: string;
    node?: Node;
    removeFromFrame: () => void;
    setIsInEditMode: React.Dispatch<React.SetStateAction<boolean>>;
    showResizer: boolean;
    updateNode: (id: string, patch: Partial<Node>) => void;
  }) => {
    const contextMenu = createContextMenu({
      copy,
      cut,
      deleteSelf,
      duplicate,
      isInFrame: !!node?.parentId,
      removeFromFrame,
    });

    const autoHeightDisabled = node?.data?.autoHeightDisabled;
    const content = useMemo(() => {
      if (!editor?.getText().trim() && !isInEditMode) {
        return <div className={styles.editorPlaceholder}>Text...</div>;
      }

      return <EditorContent className={styles.editor} editor={editor} />;
    }, [editor]);

    return (
      <ContextMenu contentClassName={styles.menu} items={contextMenu}>
        <Handle
          id={`${id}--handle-source-top`}
          type="source"
          position={Position.Top}
          className={clsx(
            styles.handle,
            !isSelected && showResizer && styles.isVisible
          )}
        />
        <Handle
          id={`${id}--handle-source-right`}
          type="source"
          position={Position.Right}
          className={clsx(
            styles.handle,
            !isSelected && showResizer && styles.isVisible
          )}
        />
        <Handle
          id={`${id}--handle-source-bottom`}
          type="source"
          position={Position.Bottom}
          className={clsx(
            styles.handle,
            !isSelected && showResizer && styles.isVisible
          )}
        />
        <Handle
          id={`${id}--handle-source-left`}
          type="source"
          position={Position.Left}
          className={clsx(
            styles.handle,
            !isSelected && showResizer && styles.isVisible
          )}
        />
        <Handle
          id={`${id}--handle-target-top`}
          type="target"
          position={Position.Top}
          className={clsx(
            styles.handle,
            !isSelected && showResizer && styles.isVisible
          )}
        />
        <Handle
          id={`${id}--handle-target-right`}
          type="target"
          position={Position.Right}
          className={clsx(
            styles.handle,
            !isSelected && showResizer && styles.isVisible
          )}
        />
        <Handle
          id={`${id}--handle-target-bottom`}
          type="target"
          position={Position.Bottom}
          className={clsx(
            styles.handle,
            !isSelected && showResizer && styles.isVisible
          )}
        />
        <Handle
          id={`${id}--handle-target-left`}
          type="target"
          position={Position.Left}
          className={clsx(
            styles.handle,
            !isSelected && showResizer && styles.isVisible
          )}
        />
        <div
          className={clsx(styles.textbox, colorStyle)}
          onDoubleClick={() => setIsInEditMode(true)}
        >
          <div className={styles.formatMenu}>
            <FloatingTextSelectionMenu
              editor={editor}
              node={node}
              updateNode={(patch) => updateNode(id, patch)}
            />
          </div>
          <div
            className={clsx(
              styles.content,
              autoHeightDisabled ? styles.fixedHeight : undefined
            )}
          >
            {autoHeightDisabled ? (
              <ScrollArea
                orientation="vertical"
                style={css({
                  '--width': '100%',
                  '--maxHeight': '100%',
                })}
              >
                {content}
              </ScrollArea>
            ) : (
              content
            )}
          </div>
        </div>
      </ContextMenu>
    );
  }
);

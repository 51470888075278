import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import clsx from 'clsx';
import { EditorContent } from '@tiptap/react';
import { v4 } from 'uuid';
import { useShallow } from 'zustand/shallow';

import type {
  AiModel,
  Chat,
  ChatTemporaryLinkSource,
  ChatMediaGroupSource,
  ChatTemporaryFileSource,
  MediaGroupDTO,
} from '@spaceduck/api';
import { Icon16, Icon24 } from '@spaceduck/icons';
import { exists } from '@spaceduck/utils';

import { useCreateTemporaryLink, type SourceType } from '@api/ai';
import { toastApiErrorOr } from '@api/util';
import MediaGroupSourcePopup from '@components/ai/MediaGroupSourcePopover';
import {
  ResearchSourcesDropdown,
  type SourceOption,
} from '@components/ai/ResearchSourcesDropdown';
import { useResearchAssistantEditor } from '@components/ai/Tiptap';
import {
  type SelectedSourceType,
  useSourcesStore,
} from '@components/ai/useSourcesStore';
import { useBookmarkModal } from '@components/CreateBookmarkModal';
import { textNode } from '@components/detailsModal/tiptap/utils';
import { css } from '@lib/css';
import Button from '@ui/Button';
import ScrollArea from '@ui/ScrollArea';
import { eqSet } from '@utils/set';
import { SelectedSource } from './InlineSource';
import { FileUpload } from './FileUpload';
import styles from './ChatMessageInput.module.scss';

const { AI, StatusInfo } = Icon16;
const { At, SendComment, Attach } = Icon24;

type FilterContext =
  | {
      exclude: boolean;
      sourceType: SourceType;
      suggestionQuery: string;
      context: 'suggestion';
    }
  | {
      exclude: boolean;
      filterQuery: string;
      context: 'filter';
    }
  | {
      context: undefined;
    };

type Mention = Partial<{
  range?: { from?: number; to?: number };
  text?: string;
}>;

const extractFilterAndSuggestionQuery = (value: string): FilterContext => {
  let context: 'filter' | 'suggestion' | undefined;
  if (value.includes(':')) {
    context = 'suggestion';
  } else if (value.startsWith('@')) {
    context = 'filter';
  }

  const [filter, suggestionQuery] = value
    .replace('@', '')
    .split(':')
    .map((f) => f.trim());
  const filterParsed = (['library', 'url'] as const).filter(
    (property) => property === filter?.replace('-', '')
  );

  if (filterParsed.length && filterParsed[0] && context === 'suggestion') {
    return {
      exclude: filter?.startsWith('-') ?? false,
      sourceType: filterParsed[0],
      suggestionQuery: suggestionQuery ?? '',
      context,
    };
  }
  if (context === 'filter') {
    return {
      exclude: filter?.startsWith('-') ?? false,
      filterQuery: filter?.replace('-', '') ?? '',
      context,
    };
  }

  return {
    context: undefined,
  };
};

type TempSelectedSourceType = Omit<SelectedSourceType, 'id'> & { fileKey: string };

export default function ChatMessageInput({
  readOnly,
  onSubmit,
  placeholder,
  showTools = false,
  initialSources,
  projectId,
  currentChatSession,
}: {
  readOnly: boolean;
  onSubmit: (
    query: string,
    mediaGroups: ChatMediaGroupSource[],
    links: ChatTemporaryLinkSource[],
    temporaryFiles: ChatTemporaryFileSource[],
    model: AiModel
  ) => PromiseLike<boolean | undefined>;
  placeholder?: string;
  showTools?: boolean;
  initialSources?: SelectedSourceType[];
  projectId: string;
  currentChatSession?: Chat | null;
}) {
  const { includedSources, addFile, setIncludedSources } = useSourcesStore(
    useShallow((state) => ({
      includedSources: state.includedSources,
      addFile: state.addFile,
      setIncludedSources: state.setIncludedSources,
    }))
  );
  const [filterContext, setFilterContext] = useState<FilterContext>();
  const inputField = useRef<HTMLInputElement>(null);
  const [inputValue, setInputValue] = useState('');
  const sendButtonRef = useRef<HTMLButtonElement>(null);
  const [mentionCommand, setMentionCommand] =
    useState<(props: SelectedSourceType) => void>();
  const [editorSources, setEditorSources] = useState<SelectedSourceType[]>([]);
  const [tempEditorSources, setTempEditorSources] = useState<TempSelectedSourceType[]>(
    []
  );

  useEffect(() => {
    if (currentChatSession) {
      setTempEditorSources([]);
      setEditorSources([]);
      setIncludedSources([]);
    }
  }, [currentChatSession]);

  const isPending = useMemo(() => {
    return readOnly || tempEditorSources.length !== 0;
  }, [readOnly, tempEditorSources]);

  const { mutateAsync: createLink } = useCreateTemporaryLink();
  const { open: openCreateBookmarkModal } = useBookmarkModal();
  const selectSourceLookup = useCallback(
    async (sourceLookup: SourceOption['label']) => {
      if (!inputField.current) {
        return;
      }
      if (sourceLookup === 'library') {
        const toInsert = sourceLookup.replace(inputValue.replace('@', ''), '');
        editor?.commands.insertContent(textNode(`${toInsert}:`));
        return;
      }
      if (sourceLookup === 'link') {
        setInputValue('');
        openCreateBookmarkModal({
          onEscapeKeyDown: () => {
            editor?.commands.focus();
          },
          onSubmit: async (data) => {
            let tempLink: Awaited<ReturnType<typeof createLink>>;

            try {
              tempLink = await createLink({
                url: data.url,
              });
            } catch (error) {
              return toastApiErrorOr(error, 'Failed to create source link', {
                iconVariant: 'warning',
                titleText: 'Create link failed',
                bodyText:
                  'An unknown error occurred while creating source link. Please try again later',
              });
            }
            mentionCommand?.({
              label: data.url,
              id: tempLink.link.id,
              type: 'link',
            });
          },
        });
      }
      return;
    },
    [inputValue]
  );

  const selectMediaGroup = (mediaGroup: MediaGroupDTO) => {
    mentionCommand?.({ id: mediaGroup.id, type: 'library', label: mediaGroup.label });
  };

  useEffect(() => {
    if (!isPending) {
      setFilterContext(extractFilterAndSuggestionQuery(inputValue));
    }
  }, [inputValue, isPending]);

  const pendingSources = useMemo(() => {
    const includedSourcesIds = new Set(includedSources.map((source) => source.id));
    const editorSourcesIds = new Set(editorSources.map((source) => source.id));

    return !eqSet(includedSourcesIds, editorSourcesIds);
  }, [includedSources, editorSources]);

  const editor = useResearchAssistantEditor({
    onMentionUpdate: (value) => setInputValue(value),
    onSend: () => {
      sendButtonRef.current?.click();
      return true;
    },
    setCommand: (command) => setMentionCommand(() => command),
    placeholder:
      placeholder ??
      'Ask Ducklas the research duck a question or chat to your repository...',
    initialSources,
    currentChatSession,
  });

  const handleSubmit = useCallback(async () => {
    if (!editor) {
      return;
    }

    const text = editor.getText()?.trim() || '';
    if (text.length === 0 || pendingSources) {
      return;
    }
    const content = editor.getJSON();
    editor.commands.clearContent(true);
    let result: boolean | undefined;
    try {
      result = await onSubmit(
        text,
        includedSources.filter((source) => source.type === 'library'),
        includedSources.filter((source) => source.type === 'link'),
        includedSources.filter((source) => source.type === 'file'),
        'gpt-4o'
      );
    } catch (error) {
      console.error('Chat message submission failed', error);
      editor.commands.setContent(content);
      return;
    }

    if (!(result ?? true)) {
      editor.commands.setContent(content);
    }
  }, [editor, includedSources, pendingSources]);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const addEditorSources = useCallback((newSources: SelectedSourceType[]) => {
    setEditorSources((sources) => {
      const allSources = [...sources, ...newSources];
      return [...allSources].filter((s, idx) => {
        return allSources.findIndex((source) => source.id === s.id) === idx;
      });
    });
  }, []);

  const handleRemoveSource = useCallback((id: string | null) => {
    if (!id) return;

    setEditorSources((sources) => sources.filter((source) => source.id !== id));
  }, []);

  const handleRemoveTempSource = useCallback((fileKey?: string) => {
    if (!fileKey) return;

    setTempEditorSources((sources) =>
      sources.filter((source) => source.fileKey !== fileKey)
    );
  }, []);

  const migrateTempSourceToSource = useCallback(
    (source: SelectedSourceType, fileKey?: string) => {
      if (source.id) {
        addEditorSources([source]);
      }
      if (fileKey) {
        handleRemoveTempSource(fileKey);
      }
    },
    []
  );

  useEffect(() => {
    if (!editor) return;

    const container = document.createElement('div');
    container.innerHTML = editor.getHTML();
    const mentionElements = container.querySelectorAll('span[data-type=mention]');

    if (!mentionElements.length) return;

    const allSourceElements = Array.from(mentionElements.values());
    const allSources = allSourceElements
      .map((mentionElement) => {
        return {
          id: mentionElement.getAttribute('id'),
          label: mentionElement.getAttribute('label'),
          type: mentionElement.getAttribute('type'),
          fileKey: mentionElement.getAttribute('filekey') ?? null,
        };
      })
      .filter((source) => source.label !== null && source.type !== null);

    const sources = allSources
      .filter((source) => {
        if (!source.id || source.label === null || !source.type || source.fileKey)
          return false;
        if (
          source.type === 'file' ||
          source.type === 'library' ||
          source.type === 'link'
        )
          return true;
        return false;
      })
      .map(({ fileKey, ...rest }) => rest as SelectedSourceType);

    if (sources.length) {
      addEditorSources(sources);
    }

    const tempSources = allSources
      .filter((source) => {
        return source.label !== null && source.type === 'file' && source.fileKey;
      })
      .map(({ id, ...rest }) => rest as TempSelectedSourceType);

    if (tempSources.length) {
      setTempEditorSources((sources) => [...sources, ...tempSources]);
    }

    allSourceElements.forEach((element) => element.remove());

    queueMicrotask(() => {
      if (!editor) return;
      editor.commands.setContent(container.innerHTML);
    });
  }, [editor?.getHTML()]);

  const handleSourceButtonClick = useCallback(() => {
    if (!editor) return;
    editor.commands.focus();

    const string = editor.state.selection.$from.nodeBefore?.textContent;

    if (!string || string.trim().length === 0 || /\s$/.test(string)) {
      editor.commands.insertContent(textNode('@'));
      return;
    }

    if (/.*\s@$/.test(string) || /^@$/.test(string)) {
      if (!inputValue) {
        setInputValue('@');
        setFilterContext(extractFilterAndSuggestionQuery('@'));
        return;
      }

      setFilterContext(extractFilterAndSuggestionQuery(inputValue));
      return;
    }

    editor.commands.insertContent(textNode(' @'));
  }, [editor]);

  return (
    <>
      <div className={styles.widget}>
        <div className={clsx(styles.inputBar, !showTools && styles.noPadding)}>
          <div className={styles.inputWrapper}>
            {!!(editorSources.length || tempEditorSources.length) && (
              <ScrollArea
                orientation="horizontal"
                style={{
                  maxWidth: '100%',
                }}
              >
                <div className={styles.sources}>
                  {editorSources.map(({ id, label, type }) => {
                    return (
                      <SelectedSource
                        id={id}
                        key={id}
                        label={label}
                        onDelete={() => handleRemoveSource(id)}
                        type={type}
                      />
                    );
                  })}
                  {tempEditorSources.map(({ fileKey, label }) => {
                    return (
                      <FileUpload
                        fileKey={fileKey}
                        fileName={label}
                        key={fileKey}
                        onDelete={handleRemoveTempSource}
                        onTempFileUploaded={migrateTempSourceToSource}
                      />
                    );
                  })}
                </div>
              </ScrollArea>
            )}
            <div className={styles.content}>
              <ScrollArea
                orientation="vertical"
                rootClassName={clsx(!showTools && styles.paddedScrollArea)}
                style={css({
                  '--width': '100%',
                  '--maxHeight': '30vh',
                })}
              >
                <EditorContent
                  editor={editor}
                  className={clsx(styles.input, !showTools && styles.hasSiblingSubmit)}
                  innerRef={inputField}
                  onKeyDown={() => editor?.commands.scrollIntoView()}
                />
                {!showTools && (
                  <Button
                    ref={sendButtonRef}
                    className={clsx(styles.submitButton, styles.inlineSubmit)}
                    disabled={editor?.getText()?.trim()?.length === 0 || pendingSources}
                    onClick={handleSubmit}
                    size="sm"
                    variant="light"
                    isSquare={true}
                  >
                    <SendComment />
                  </Button>
                )}
              </ScrollArea>
            </div>
            {filterContext?.context === 'filter' && (
              <ResearchSourcesDropdown
                filter={filterContext.filterQuery}
                inputRef={inputField}
                setSelectedSource={selectSourceLookup}
                onDismiss={() => {
                  setFilterContext({ context: undefined });
                }}
              />
            )}
            {filterContext?.context === 'suggestion' &&
              filterContext.sourceType === 'library' && (
                <MediaGroupSourcePopup
                  inputRef={inputField}
                  handleSelectItem={selectMediaGroup}
                  searchTitle={filterContext.suggestionQuery}
                  onEscapeKeyDown={() => {
                    setFilterContext({ context: undefined });
                    if (!editor || !('mention$' in editor.view.state)) return;

                    const mention = editor.view.state.mention$ as Mention;
                    if (mention.text !== '@library:' || !mention.range) return;

                    const {
                      range: { from, to },
                    } = mention;
                    if (!from || !to) return;

                    editor
                      .chain()
                      .setTextSelection({ from, to })
                      .deleteSelection()
                      .insertContent('@')
                      .run();
                  }}
                  excludeMediaGroupIds={editorSources
                    .filter((source) => source.type === 'library')
                    .map((mediaGroup) => mediaGroup.id)
                    .filter(exists)}
                  projectId={projectId}
                />
              )}
          </div>
        </div>
        {showTools && (
          <div className={styles.tools}>
            <div className={styles.sources}>
              <Button
                className={styles.sourceButton}
                disabled={isPending}
                onClick={handleSourceButtonClick}
                size="md"
                variant="ghost"
                iconBefore={<At size={20} />}
              >
                Source
              </Button>
              <Button
                className={styles.sourceButton}
                disabled={isPending}
                size="md"
                variant="ghost"
                iconBefore={<Attach size={20} />}
                onClick={() => {
                  fileInputRef.current?.click();
                }}
              >
                Attach
              </Button>
              <Button
                className={styles.sourceButton}
                disabled
                size="md"
                variant="ghost"
                iconBefore={<AI size={20} />}
              >
                {/* TODO: Support multiple models */}
                {'GPT-4o'}
              </Button>
              <input
                type="file"
                ref={fileInputRef}
                onChange={(ev) => {
                  if (ev.target.files) {
                    const keys = [];
                    for (let i = 0; i < ev.target.files.length; i++) {
                      const file = ev.target.files[i];
                      const key = v4();
                      if (file) {
                        keys.push(key);
                        editor?.commands.insertContent?.({
                          type: 'mention',
                          attrs: {
                            type: 'file',
                            fileKey: key,
                            label: file?.name || '',
                          },
                        });
                        addFile(file, key);
                      }
                    }
                  }
                }}
                style={{ display: 'none' }}
              />
            </div>
            <Button
              ref={sendButtonRef}
              className={styles.submitButton}
              disabled={(editor?.getText()?.trim()?.length ?? 0) < 3 || pendingSources}
              onClick={handleSubmit}
              size="sm"
              variant="light"
              isSquare={true}
            >
              <SendComment />
            </Button>
          </div>
        )}
      </div>
      {!readOnly && (
        <span
          className={clsx(
            styles.pendingNotice,
            pendingSources ? styles.active : undefined
          )}
        >
          <AlertBanner />
        </span>
      )}
    </>
  );
}

const AlertBanner = () => {
  return (
    <div className={styles.alertBanner}>
      <StatusInfo />
      <p>Working on your content. This may take a moment...</p>
    </div>
  );
};

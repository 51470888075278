import {
  type TagColor,
  type TagFieldInstance,
  type TagOption,
  isTagFieldInstance,
  tagColors,
} from '@spaceduck/api';
import { Icon16, Icon24 } from '@spaceduck/icons';
import Tippy from '@tippyjs/react';
import clsx from 'clsx';
import isEqual from 'lodash/isEqual';
import { useEffect, useId, useMemo, useRef, useState } from 'react';

import type {
  AddTagOption,
  AvailableTypes,
  DeleteTagOption,
  EditTagOption,
  SelectableTypes,
  StandardCellValue,
  StandardEditCellValue,
} from '@/types/Category';
import { useCategoryCellSelectionKeyboard } from '@hooks/useCategoryCellSelection';
import { useOnClickOutside } from '@hooks/useOnClickOutside';
import { css } from '@lib/css';
import { exists } from '@spaceduck/utils';
import Button from '@ui/Button';
import ScrollArea from '@ui/ScrollArea';
import styles from './Tag.module.scss';
import { stringContains } from '@/utils/string';

const { Close, OptionsThick } = Icon16;
const { Add, Check, Search, TrashCan } = Icon24;

export const getColor = (id: string | undefined, options?: TagOption[]) => {
  return options?.find((option) => option.id === id)?.color ?? 'neutral';
};

export const getLabel = (id: string | undefined, options?: TagOption[]) => {
  if (!id) return 'neutral';
  return options?.find((option) => option.id === id)?.label ?? 'neutral';
};

type TagValueProps = {
  addTagOption: AddTagOption;
  deleteTagOption: DeleteTagOption;
  editTagOption: EditTagOption;
  options?: TagOption[];
  type: SelectableTypes;
};

function getRandomColor() {
  const min = 0;
  const max = tagColors.length - 1;
  const rnd = Math.floor(Math.random() * (max - min + 1) + min);
  return tagColors[rnd] as TagColor;
}

function getInitialValue(value: AvailableTypes) {
  if (!value || !Array.isArray(value)) return [];

  return value.filter((entry) => !!entry && 'tag' in entry) as TagFieldInstance[];
}

export default function TagValue({
  addTagOption,
  clearSelectedCell,
  canEdit,
  deleteTagOption,
  editTagOption,
  handleDelete,
  handleUpdate,
  info,
  options,
  setSelectedCell,
  showPreview,
  type,
  value,
}: StandardCellValue & TagValueProps) {
  const initialValue = getInitialValue(value);
  const [shouldShowEditView, setShouldShowEditView] = useState(false);
  const [localValue, setLocalValue] = useState<TagFieldInstance[]>(() => {
    return value.filter(isTagFieldInstance);
  });

  const { setEnabled } = useCategoryCellSelectionKeyboard({
    setSelectedCell,
    enableOnLoad: false,
    onEnter: () => setShouldShowEditView(true),
    onEscape: () => {
      shouldShowEditView ? setShouldShowEditView(false) : clearSelectedCell();
    },
  });

  useEffect(() => {
    setEnabled(showPreview);
  }, [showPreview]);

  return (
    <>
      {canEdit && shouldShowEditView && (
        <EditTagValue
          addTagOption={addTagOption}
          clearSelectedCell={clearSelectedCell}
          deleteTagOption={deleteTagOption}
          editTagOption={editTagOption}
          handleDelete={handleDelete}
          handleUpdate={handleUpdate}
          info={info}
          initialValue={initialValue}
          isMultipleSelect={type === 'multi-select'}
          localValue={localValue}
          options={options}
          setLocalValue={setLocalValue}
          setShouldShowEditView={setShouldShowEditView}
        />
      )}
      {showPreview ? (
        <div
          className={clsx(
            type === 'multi-select' && styles.multiple,
            styles.tags,
            styles.preview
          )}
          onClick={canEdit ? () => setShouldShowEditView(true) : undefined}
        >
          <div className={styles.tagsInner}>
            {localValue.map(({ tag }, idx) => {
              const value = options?.find((option) => option.id === tag);
              if (!value) {
                return null;
              }
              return (
                <span
                  className={clsx(styles.tag, styles[getColor(value.id, options)])}
                  key={idx}
                >
                  {value.label}
                </span>
              );
            })}
          </div>
        </div>
      ) : (
        <div
          className={clsx(
            type === 'multi-select' && styles.multiple,
            styles.tags,
            styles.tagsRow,
            shouldShowEditView && styles.hidden
          )}
        >
          <div className={styles.tagsInner}>
            {localValue.map(({ tag }, idx) => {
              const value = options?.find((option) => option.id === tag);
              if (!value) {
                return null;
              }
              return (
                <span
                  className={clsx(styles.tag, styles[getColor(value.id, options)])}
                  key={idx}
                >
                  {value.label}
                </span>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
}

const EditTagValue = ({
  addTagOption,
  deleteTagOption,
  editTagOption,
  handleDelete,
  handleUpdate,
  info,
  initialValue,
  isMultipleSelect,
  localValue,
  options,
  setLocalValue,
  setShouldShowEditView,
}: StandardEditCellValue<TagFieldInstance[]> & {
  addTagOption: AddTagOption;
  deleteTagOption: DeleteTagOption;
  editTagOption: EditTagOption;
  isMultipleSelect: boolean;
  options?: TagOption[];
}) => {
  const localValueRef = useRef(localValue);
  const setLocalValueRef = (value: TagFieldInstance[]) => {
    localValueRef.current = value;
    setLocalValue(value);
  };

  const persist = async () => {
    // Stop update if value is same as initial value
    if (isEqual(initialValue, localValueRef.current)) {
      return;
    }

    const value =
      options && localValueRef.current
        ? localValueRef.current
            .map((curr): TagFieldInstance | null => {
              const tag = curr.tag;
              if (!tag) return null;

              const isAvailableOption = !!options?.find((option) => option.id === tag);
              if (!isAvailableOption) return null;
              return curr;
            })
            .filter(exists)
        : null;

    // Optimistic update
    info.table.options.meta?.updateData?.(info.row.index, info.column.id, value ?? []);

    if (!value) {
      handleDelete?.({
        propertyId: info.column.id,
        rowIndex: info.row.index,
      });
      return;
    }

    await handleUpdate?.({
      propertyId: info.column.id,
      rowIndex: info.row.index,
      value,
    });
  };

  const handleAddOption = async (option: TagOption) => {
    // TODO: move persist from addEntry and removeEntry
    // and pass intermediate state to addTagOption to update together
    addTagOption({
      propertyId: info.column.id,
      rowIndex: info.row.index,
      option,
      isMultipleSelect,
    });
  };

  const handleDeleteOption = async (option: TagOption) => {
    await deleteTagOption({ propertyId: info.column.id, option });
  };

  const handleEditOption = async (option: TagOption) => {
    await editTagOption({ propertyId: info.column.id, option });
  };

  const handleAddEntry = (entry: TagFieldInstance) => {
    const existingOption = options?.find((option) => option.id === entry.tag);

    if (!existingOption?.id) return;

    if (isMultipleSelect) {
      setLocalValueRef([
        ...localValueRef.current,
        {
          tag: existingOption.id,
        },
      ]);
    } else {
      setLocalValueRef([
        {
          tag: existingOption.id,
        },
      ]);
    }
    persist();
  };

  const handleRemoveEntry = (option: TagFieldInstance) => {
    setLocalValueRef([
      ...localValueRef.current.filter((entry) => entry.tag !== option.tag),
    ]);
    persist();
  };

  const { containerRef } = useOnClickOutside<HTMLDivElement>({
    callback: () => {
      persist();
      setShouldShowEditView(false);
    },
  });

  return (
    <div className={styles.tagEdit} ref={containerRef}>
      <Tippy
        content={
          <TagManager
            addEntry={handleAddEntry}
            addTagOption={handleAddOption}
            deleteTagOption={handleDeleteOption}
            editTagOption={handleEditOption}
            localValue={localValue}
            options={options}
            removeEntry={handleRemoveEntry}
            setShouldShowEditView={setShouldShowEditView}
          />
        }
        className={styles.tagManagerPopup}
        interactive={true}
        offset={[0, 4]}
        placement="bottom-start"
        visible={true}
      >
        <div className={styles.tags}>
          <ScrollArea
            style={css({
              maxHeight: '100%',
              width: '100%',
            })}
            orientation="vertical"
          >
            <div className={styles.tagsInner}>
              {localValue.map(({ tag }, idx) => (
                <span
                  className={clsx(styles.tag, styles[getColor(tag, options)])}
                  key={idx}
                  onClick={() => handleRemoveEntry({ tag })}
                >
                  {getLabel(tag, options)}
                  <Close />
                </span>
              ))}
            </div>
          </ScrollArea>
        </div>
      </Tippy>
    </div>
  );
};

export const TagManager = ({
  addEntry,
  addTagOption,
  deleteTagOption,
  editTagOption,
  localValue,
  options,
  removeEntry,
  setShouldShowEditView,
}: {
  addEntry: (option: TagFieldInstance) => void;
  addTagOption: (option: TagOption) => Promise<void>;
  deleteTagOption: (option: TagOption) => Promise<void>;
  editTagOption: (option: TagOption) => Promise<void>;
  localValue: TagFieldInstance[];
  options?: TagOption[];
  removeEntry: (option: TagFieldInstance) => void;
  setShouldShowEditView: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [search, setSearch] = useState<string>('');
  const results: TagOption[] = useMemo(() => {
    if (!search) return options ?? [];
    return (
      options?.filter(({ label }) =>
        stringContains(label, search, { ignoreCase: true })
      ) ?? []
    );
  }, [search, options]);

  const exactMatch = !!options?.find(
    (option) => option.label.toLowerCase().trim() === search.toLowerCase().trim()
  );
  const noOptions = !options?.length;
  const [newTagColor, setNewTagColor] = useState<TagColor>('cyan');

  const randomizeColor = () => setNewTagColor(getRandomColor());

  const handleKeydown = async (ev: React.KeyboardEvent<HTMLInputElement>) => {
    if (ev.key === 'Enter' && !exactMatch && !!ev.currentTarget.value) {
      ev.preventDefault();

      await addTagOption({
        label: search,
        color: newTagColor,
      });

      randomizeColor();
      return true;
    }

    if (ev.key === 'Escape') {
      setShouldShowEditView(false);
      return true;
    }
  };

  // Used for Tippy to load outside of ScrollArea to prevent clipping
  const [ref, setRef] = useState<HTMLDivElement | null>(null);

  return (
    <div
      className={clsx(styles.tagManager, noOptions && styles.noOptions)}
      ref={(newRef) => setRef(newRef)}
    >
      <div className={styles.searchBox}>
        {noOptions ? <Add size={20} /> : <Search size={20} />}
        <input
          autoComplete="off"
          // biome-ignore lint/a11y/noAutofocus: non-disruptive and within context
          autoFocus
          onChange={(ev) => setSearch(ev.currentTarget.value)}
          onFocus={randomizeColor}
          onKeyDown={handleKeydown}
          placeholder="Select or create one…"
          type="search"
          value={search}
        />
      </div>
      {!!search && !exactMatch && (
        <div className={styles.createTag}>
          <button
            onClick={() => {
              addTagOption({
                label: search,
                color: newTagColor,
              });
            }}
            type="button"
          >
            Create{' '}
            <span className={clsx(styles.tag, styles[newTagColor])}>{search}</span>
          </button>
        </div>
      )}
      {results.length > 0 && (
        <div className={styles.options}>
          <ScrollArea
            style={css({
              maxHeight: '100%',
              width: '100%',
            })}
            orientation="vertical"
          >
            <div className={styles.optionsInner}>
              {results.map((option, idx) => {
                const isSelectedOption =
                  option.id && !!localValue.find((entry) => entry.tag === option.id);

                return (
                  <div className={styles.option} key={option.id ?? idx}>
                    <div>
                      <div
                        className={clsx(styles.tag, styles[option.color])}
                        onClick={() => {
                          isSelectedOption
                            ? removeEntry({ tag: option.id! })
                            : addEntry({ tag: option.id! });
                        }}
                      >
                        {option.label}
                      </div>
                    </div>
                    {option.id &&
                      (isSelectedOption ? (
                        <Button
                          onClick={() => removeEntry({ tag: option.id! })}
                          type="button"
                          variant="ghost"
                        >
                          <Check size={16} />
                        </Button>
                      ) : (
                        <Button
                          onClick={() => addEntry({ tag: option.id! })}
                          type="button"
                          variant="ghost"
                        >
                          <Add size={16} />
                        </Button>
                      ))}
                    {ref && (
                      <Tippy
                        appendTo={ref}
                        content={
                          <OptionManager
                            deleteTagOption={deleteTagOption}
                            editTagOption={editTagOption}
                            option={option}
                          />
                        }
                        placement="bottom-start"
                        interactive={true}
                        trigger="click"
                      >
                        <Button type="button" variant="ghost">
                          <OptionsThick size={16} />
                        </Button>
                      </Tippy>
                    )}
                  </div>
                );
              })}
            </div>
          </ScrollArea>
        </div>
      )}
    </div>
  );
};

const OptionManager = ({
  deleteTagOption,
  editTagOption,
  option,
}: {
  deleteTagOption: (option: TagOption) => void;
  editTagOption: (option: TagOption) => void;
  option: TagOption;
}) => {
  const originalLabel = option.label;
  const originalColor = option.color;
  const [tagLabel, setTagLabel] = useState(originalLabel);
  const [_, setTagColor] = useState(originalLabel);
  const _tagLabel = useRef(originalLabel);
  const _tagColor = useRef(originalColor);
  const id = useId();

  const handleKeyDown = (ev: React.KeyboardEvent<HTMLInputElement>) => {
    if (ev.key === 'Enter') {
      (ev.currentTarget as HTMLElement).blur();
    } else if (ev.key === 'Escape') {
      _tagLabel.current = originalLabel;
      setTagLabel(originalLabel);
      (ev.currentTarget as HTMLElement).blur();
    }
  };

  const handleLabelChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = ev.currentTarget;
    _tagLabel.current = value;
    setTagLabel(value);
  };

  const handleBlur = () => {
    if (_tagLabel.current === originalLabel) return;

    editTagOption({
      ...option,
      label: _tagLabel.current,
    });
  };

  const handleColorChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const value = ev.currentTarget.value as TagColor;
    if (ev.currentTarget.checked) {
      _tagColor.current = value;
      setTagColor(value);
    }

    if (_tagColor.current === originalColor) return;

    editTagOption({
      ...option,
      color: value,
    });
  };

  return (
    <div className={styles.optionManager}>
      <div className={styles.labelField}>
        <label>Option name</label>
        <input
          onBlur={handleBlur}
          onChange={handleLabelChange}
          onKeyDown={handleKeyDown}
          type="text"
          value={tagLabel}
        />
      </div>
      <div className={styles.deleteOption}>
        <button
          onClick={() => {
            deleteTagOption(option);
          }}
          type="button"
        >
          <TrashCan size={20} />
          Delete option
        </button>
      </div>
      <div className={styles.dots}>
        {tagColors.map((color, idx) => {
          return (
            <label key={idx}>
              <input
                defaultChecked={color === originalColor}
                onChange={handleColorChange}
                name={`${id}TagColor`}
                type="radio"
                value={color}
              />
              <span className={clsx(styles.dot, styles[color])} />
            </label>
          );
        })}
      </div>
    </div>
  );
};

import clsx from 'clsx';
import prettyBytes from 'pretty-bytes';

import { useGetSubscriptionInfo } from '@/api/billing';
import { css } from '@/lib/css';
import LoadingPlaceholder from '../LoadingPlaceholder';

import styles from './PlanUsageChart.module.scss';
import type { UsageCategories } from '@spaceduck/api';

const usageCategoryLabel: Record<UsageCategories, string> = {
  fileStorage: 'storage',
  mediaGroupCount: 'items',
  projectCount: 'spaces',
  aiWritingWordsPerDay: 'AI Word limit per day',
  aiAssistantInteractionsPerDay: 'AI Assists',
  aiSummaryCount: 'AI summaries',
};

export const PlanUsageChart = ({ workspaceId }: { workspaceId: string }) => {
  return (
    <div className={styles.planUsageChart}>
      <ChartItems workspaceId={workspaceId} />
    </div>
  );
};

export const ChartItems = ({ workspaceId }: { workspaceId: string }) => {
  const { data, status } = useGetSubscriptionInfo(workspaceId);

  if (status === 'pending') {
    return <LoadingPlaceholder />;
  }

  if (status === 'error') {
    return <div>TODO(@dbowring)</div>;
  }

  return (
    <div className={styles.chart}>
      {data.usage
        .filter(
          (
            usage
          ): usage is Pick<typeof usage, 'currentUsage' | 'limitName'> & {
            planLimit: number;
          } => !!usage.planLimit
        )
        .map((usage, index) => (
          <ChartItem
            key={usage.limitName ?? index}
            label={usage.limitName ? usageCategoryLabel[usage.limitName] : 'Unknown'}
            max={usage.planLimit}
            value={usage.currentUsage}
            format={(value) =>
              usage.limitName === 'fileStorage' ? prettyBytes(value) : value
            }
          />
        ))}
    </div>
  );
};

const ChartItem = ({
  className,
  label,
  max,
  value,
  format,
}: {
  className?: string;
  label: string;
  max: number;
  value: number;
  format?: (value: number) => string | number;
}) => {
  const width = (value / max) * 100;
  return (
    <div className={clsx(styles.chartItem, className)}>
      <div className={styles.bar}>
        <div
          className={styles.progress}
          style={css({
            '--width': `${width < 100 ? width : 100}%`,
          })}
        />
      </div>
      <div className={styles.label}>
        <span className={styles.highlight}>{format?.(value) ?? value}</span> /{' '}
        {format?.(max) ?? max} {label}
      </div>
    </div>
  );
};

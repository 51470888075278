/**
 * Calculate how long to sleep for `attempt` using exponential backoff.
 * Here are some sample results for `interval=1000, exponential=1.05`:
 *
 * | attempt | result (rounded) |
 * |---------|------|
 * | 0       | 0    |
 * | 1       | 1000 |
 * | 2       | 2050 |
 * | 3       | 3152 |
 * | 4       | 4310 |
 * | 5       | 5526 |
 *
 * GUI Calculator: https://exponentialbackoffcalculator.com/
 * @param interval starting/initial interval
 * @param exponent
 * @param attempt
 * @returns
 */
export const exponentialBackoff = (
  interval: number,
  exponent: number,
  attempt: number
) => interval * exponent ** attempt;

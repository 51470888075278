import useWorkspaceId from '@/hooks/useWorkspaceId';
import { urlFor } from '@/urls';
import { NavLink } from 'react-router';
import styles from './TrashTabs.module.scss';

export const TrashTabs = () => {
  const workspaceId = useWorkspaceId();
  if (!workspaceId) {
    return null;
  }
  return (
    <div className={styles.trashTabs}>
      <ul>
        <li>
          <NavLink
            className={({ isActive }) => (isActive ? styles.isActive : undefined)}
            to={urlFor('workspaceTrashItems', { workspaceId })}
          >
            Items
          </NavLink>
        </li>
        <li>
          <NavLink
            className={({ isActive }) => (isActive ? styles.isActive : undefined)}
            to={urlFor('workspaceTrashSpaces', { workspaceId })}
          >
            Spaces
          </NavLink>
        </li>
      </ul>
    </div>
  );
};

import { clsx } from 'clsx';
import { useNavigate } from 'react-router';

import ErrorEgg from '@/assets/img/ErrorEgg';
import starsSm from '@/assets/img/stars-sm.png';
import LoadingPlaceholder from '@components/LoadingPlaceholder';
import styles from '@components/detailsModal/DetailsModalBody.module.scss';
import Button from '@ui/Button';
import DetailsModalSidebarPlaceholder from './DetailsModalSidebarPlaceholder';
import placeholderStyles from './Placeholder.module.scss';

export type PlaceholderContentType = 'error' | 'loading';

export default function DetailsModalBodyPlaceholder({
  errorAction,
  errorMessage,
  state,
}: {
  errorAction?: React.ReactNode;
  state: PlaceholderContentType;
  errorMessage?: string;
}) {
  const navigate = useNavigate();

  return (
    <div className={styles.dialogBody}>
      <DetailsModalSidebarPlaceholder />
      <div className={placeholderStyles.container}>
        <div className={clsx('body5', placeholderStyles.container)}>
          <div className={placeholderStyles.starsWrapper}>
            <img
              alt=""
              height={451}
              className={placeholderStyles.stars}
              src={starsSm}
              width={645}
            />
          </div>
          {state === 'loading' && (
            <div className={clsx(placeholderStyles.loadingContent)}>
              <LoadingPlaceholder />
            </div>
          )}
          {state === 'error' && (
            <div className={clsx('body5', placeholderStyles.errorContent)}>
              <ErrorEgg />
              <h1 className="h6">Oops!</h1>
              <p>
                {errorMessage ?? "We couldn't find the page you were looking for..."}
              </p>
              {errorAction ? (
                errorAction
              ) : (
                <Button onClick={() => navigate('/')}>Return to homepage</Button>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

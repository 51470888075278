import { useProject } from '@/api/project';
import { useWorkspaceStore } from '@/stores/useWorkspaceStore';
import { useEffect } from 'react';
import { Outlet, useParams } from 'react-router';

export default function SpaceLayout() {
  const projectId = useParams<{ projectId: string }>().projectId!;
  const { data } = useProject(projectId);
  const workspaceId = data?.project.workspace.id;
  const setFocusedWorkspaceId = useWorkspaceStore(
    (state) => state.setFocusedWorkspaceId
  );

  useEffect(() => {
    setFocusedWorkspaceId(workspaceId);
    return () => setFocusedWorkspaceId(null);
  }, [workspaceId]);

  return <Outlet />;
}

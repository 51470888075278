import type { JSONContent } from '@tiptap/react';
import { debounce } from 'lodash';
import { useCallback } from 'react';

import { usePatchMediaGroup } from '@api/mediaGroup';
import { toastApiErrorOr } from '@api/util';
import { useNotesStore } from '@stores/useNotesStore';
import { useShallow } from 'zustand/shallow';

export const useNotesSave = (asQuickView?: boolean) => {
  const { setIsNotesSaving, setIsQuickViewNotesSaving } = useNotesStore(
    useShallow((state) => ({
      setIsNotesSaving: state.setIsNotesSaving,
      setIsQuickViewNotesSaving: state.setIsQuickViewNotesSaving,
    }))
  );

  const { mutateAsync: updateMediaGroup } = usePatchMediaGroup();

  const save = useCallback(
    async (mediaGroupId: string, content?: JSONContent, plainText?: string) => {
      if (!content) {
        return;
      }
      asQuickView ? setIsQuickViewNotesSaving(true) : setIsNotesSaving(true);

      try {
        await updateMediaGroup({
          mediaGroupId,
          patch: {
            document: content,
            plainText,
          },
        });
      } catch (error) {
        return toastApiErrorOr(error, 'Failed to update media group in notes save', {
          iconVariant: 'warning',
          titleText: 'Save Error',
          bodyText:
            'An unknown error occurred while saving content. Please try again later',
        });
      }
    },
    [asQuickView, updateMediaGroup, setIsQuickViewNotesSaving, setIsNotesSaving]
  );

  const debounceSave = useCallback(
    debounce(async (mediaGroupId, value?: JSONContent, plainText?: string) => {
      try {
        await save(mediaGroupId, value, plainText);
      } catch (e) {}
      asQuickView ? setIsQuickViewNotesSaving(false) : setIsNotesSaving(false);
    }, 2000),
    [save]
  );

  return {
    debounceSave,
    save,
  };
};

import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router';

import { useMediaGroupDetail } from '@api/mediaGroup';
import ErrorDuck from '@assets/img/ErrorDuck';
import Spinner from '@components/Spinner';
import styles from './MediaGroup.module.scss';
import DocumentWrapper from './DocumentWrapper';
import { EmbedView } from '@/components/detailsModal/contentView/EmbedView';
import DetailsModalBoardContent from '@/components/detailsModal/DetailsModalBoardContent';
import { ReactFlowProvider } from '@xyflow/react';
import clsx from 'clsx';

export type ContentBlockNode = {
  attrs?: { id?: string; 'data-minimal-view'?: boolean };
  type?: { name: string };
};

export default function MediaGroupPage() {
  const { mediaGroupId } = useParams<{ mediaGroupId: string }>();
  if (!mediaGroupId) {
    throw new Error('TODO(@dbowring)');
  }
  return <Wrapper mediaGroupId={mediaGroupId} />;
}

const Wrapper = ({ mediaGroupId }: { mediaGroupId: string }) => {
  const { data, status } = useMediaGroupDetail(mediaGroupId);

  if (status === 'pending') {
    return <Spinner />;
  }

  if (status === 'error') {
    return (
      <div className={styles.errorContainer}>
        <ErrorDuck />
        <h1 className="h6">Content Error</h1>
        <p>Please try again later.</p>
      </div>
    );
  }

  if (data.mediaGroup.kind === 'document') {
    return (
      <>
        <Helmet>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
          />
        </Helmet>
        <DocumentWrapper mediaGroup={data.mediaGroup} />
      </>
    );
  }

  if (data.mediaGroup.kind === 'bookmark') {
    return (
      <>
        <Helmet>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
          />
        </Helmet>
        <EmbedView
          mediaGroup={data.mediaGroup}
          containerClassName={styles.embedContainer}
        />
      </>
    );
  }

  if (data.mediaGroup.kind === 'board') {
    return (
      <>
        <Helmet>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0, viewport-fit=cover"
          />
        </Helmet>
        <div className={clsx(styles.visuals)}>
          <ReactFlowProvider>
            <DetailsModalBoardContent mediaGroupId={mediaGroupId} />
          </ReactFlowProvider>
        </div>
      </>
    );
  }

  return (
    <div className={styles.errorContainer}>
      <ErrorDuck />
      <h1 className="h6">Content Error</h1>
      <p>No preview available for this content.</p>
    </div>
  );
};

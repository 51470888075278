import { Fragment, Suspense } from 'react';
import { BrowserRouter, Navigate, Route, Routes, useLocation } from 'react-router';

import { ErrorBoundary } from '@components/ErrorBoundary';
import LoadingPlaceholder from '@components/LoadingPlaceholder';
import Onboarding from '@components/Onboarding';
import { ModalManagerProvider } from '@context/ModalManagerContext';
import useGooglePageTracking from '@hooks/useGooglePageTracking';
import { useUserInfo } from '@hooks/useAuth';
import AuthLayout from '@layouts/Auth';
import SpaceLayout from '@layouts/Space';
import SpacesLayout from '@layouts/Spaces';
import StarsLayout, { OuterSpace } from '@layouts/Stars';
import SupportLayout from '@layouts/Support';
import UserSettingsLayout from '@layouts/UserSettings';
import WorkspaceLayout from '@layouts/Workspace';
import WorkspaceSettingsLayout from '@layouts/WorkspaceSettings';

import BrowserLoginRedirect from '@browser/BrowserLoginRedirect';
import FigmaEntryPoint from '@figma/FigmaEntryPoint';
import { useLastBackgroundLocation } from '@hooks/useBackgroundLocation';
import MobileMediaGroupPage from '@mobile/MediaGroup';
import NotFoundPage from '@pages/404';
import ServerErrorPage from '@pages/500';
import ResearchAssistantChatPage from '@pages/ai/ResearchAssistant';
import ResearchAssistantIndex from '@pages/ai/ResearchAssistantIndex';
import UserProfilePage from '@pages/auth/Profile';
import AuthorizeIntegrationPage from '@pages/AuthorizeIntegration';
import CategoriesRedirectPage from '@pages/CategoriesRedirect';
import ChatSessionsRedirectPage from '@pages/ChatSessionsRedirect';
import DraftsRedirectPage from '@pages/DraftsRedirect';
import ErrorPage from '@pages/Error';
import HelpAndSupportPage from '@pages/HelpAndSupport';
import LoginPage from '@pages/Login';
import MediaGroupPage from '@pages/MediaGroup';
import CategoriesPage from '@pages/projects/Categories';
import CategoryPage from '@pages/projects/Category';
import DraftsPage from '@pages/projects/Drafts';
import LibraryPage from '@pages/projects/Library';
import ProgressPage from '@pages/projects/Progress';
import SpacesPage from '@pages/projects/Spaces';
import TrashPage from '@pages/projects/Trash';
import SearchPage from '@pages/Search';
import BillingAndPlanPage from '@/pages/settings/workspace/BillingAndPlan';
import CreateWorkspacePage from '@pages/settings/workspace/CreateWorkspacePage';
import IntegrationsPage from '@pages/settings/workspace/Integrations';
import PeopleManagerPage from '@pages/settings/workspace/PeopleManager';
import ProfileManagerPage from '@pages/settings/workspace/ProfileManager';
import SpacesManagerPage from '@pages/settings/workspace/SpacesManager';
import TagManagerPage from '@pages/settings/workspace/TagManager';
import WorkspaceManagerPage from '@pages/settings/workspace/WorkspaceManager';
import WorkspacesManagerPage from '@pages/settings/workspace/WorkspacesManager';
import SpacesRedirectPage from '@pages/SpacesRedirect';
import SupportedFileTypesPage from '@pages/SupportedFileTypes';
import WorkspacePage from '@pages/Workspace';
import WelcomePage from '@pages/Welcome';

const GooglePageTracking = () => {
  useGooglePageTracking();
  return null;
};

export default function Router() {
  const user = useUserInfo();

  if (user?.isNew) {
    return (
      <Suspense
        fallback={
          <OuterSpace>
            <LoadingPlaceholder />
          </OuterSpace>
        }
      >
        <Onboarding />
      </Suspense>
    );
  }

  return (
    <Suspense
      fallback={
        <OuterSpace>
          <LoadingPlaceholder />
        </OuterSpace>
      }
    >
      <BrowserRouter>
        <ErrorBoundary>
          <GooglePageTracking />
          <ModalManagerProvider>
            <NavigableRoutes />
            <ModalRoutes />
          </ModalManagerProvider>
        </ErrorBoundary>
      </BrowserRouter>
    </Suspense>
  );
}

const mediaGroupRoutes = [
  <Route key="index" index element={<MediaGroupPage />} />,
  <Route
    key="aiSettings"
    path=":view?/ai-settings"
    element={<MediaGroupPage activeTab="aiSettings" />}
  />,
  <Route
    key="browseRepository"
    path=":view?/browse-repository"
    element={<MediaGroupPage activeTab="browseRepository" />}
  />,
  <Route
    key="references"
    path=":view?/references"
    element={<MediaGroupPage activeTab="references" />}
  />,
  <Route
    key="tableOfContent"
    path=":view?/table-of-content"
    element={<MediaGroupPage activeTab="tableOfContent" />}
  />,
  <Route
    key="tableOfContent"
    path=":view?/research-chat"
    element={<MediaGroupPage activeTab="researchChat" />}
  />,
  <Route
    key="activity"
    path=":view?/activity"
    element={<MediaGroupPage activeTab="activity" />}
  />,
  <Route
    key="comment"
    path=":view?/comments"
    element={<MediaGroupPage activeTab="comment" />}
  />,
  <Route key="view" path=":view" element={<MediaGroupPage />} />,
];

const ModalRoutes = () => {
  const lastBackgroundLocation = useLastBackgroundLocation();

  if (!lastBackgroundLocation) {
    return null;
  }

  return (
    <Routes>
      <Route path="/media-groups">
        <Route path=":mediaGroupId">
          {mediaGroupRoutes.map((route, idx) => (
            <Fragment key={idx}>{route}</Fragment>
          ))}
        </Route>
      </Route>
      <Route path="/workspaces">
        <Route path=":workspaceId">
          <Route path="search" element={<SearchPage />} />
        </Route>
      </Route>
    </Routes>
  );
};

const NavigableRoutes = () => {
  const location = useLocation();
  const lastBackgroundLocation = useLastBackgroundLocation();
  const backgroundLocationIsNotModal =
    !lastBackgroundLocation?.startsWith('/media-groups/');

  return (
    <Routes
      location={
        lastBackgroundLocation && backgroundLocationIsNotModal
          ? lastBackgroundLocation
          : location
      }
    >
      <>
        <Route path="/integrations">
          <Route path="figma" element={<FigmaEntryPoint />} />
          <Route path="browser/login" element={<BrowserLoginRedirect />} />
        </Route>
        <Route element={<SupportLayout />}>
          <Route path="/help-and-support" element={<HelpAndSupportPage />} />
          <Route path="/supported-file-types" element={<SupportedFileTypesPage />} />
        </Route>
        <Route element={<AuthLayout />}>
          <Route path="/auth/profile" element={<UserProfilePage />} />
          <Route path="/auth/integrate" element={<AuthorizeIntegrationPage />} />
          <Route path="/media-groups">
            <Route path=":mediaGroupId">
              {mediaGroupRoutes.map((route, idx) => (
                <Fragment key={idx}>{route}</Fragment>
              ))}
            </Route>
          </Route>
        </Route>
        <Route index element={<Navigate to="/home" replace />} />
        <Route path="/workspaces">
          <Route index element={<Navigate to={'/settings/workspaces'} replace />} />
          <Route path=":workspaceId" element={<WorkspaceLayout />}>
            <Route index element={<Navigate to="./home" replace />} />
            <Route path="settings" element={<WorkspaceSettingsLayout />}>
              <Route index element={<Navigate to="./general" replace={true} />} />
              <Route path="general" element={<WorkspaceManagerPage />} />
              <Route path="tags" element={<TagManagerPage />} />
              <Route path="people" element={<PeopleManagerPage />} />
              <Route path="spaces" element={<SpacesManagerPage />} />
              <Route path="billing-and-plan" element={<BillingAndPlanPage />} />
              <Route path="integrations" element={<IntegrationsPage />} />
            </Route>
            <Route element={<SpacesLayout />}>
              <Route path="home" element={<WorkspacePage />} />
              <Route path="drafts" element={<DraftsPage />} />
              <Route path="trash">
                <Route index element={<Navigate to="./content" replace />} />
                <Route path="content" element={<TrashPage show="items" />} />
                <Route path="spaces" element={<TrashPage show="projects" />} />
              </Route>
              <Route path="spaces">
                <Route index element={<SpacesPage />} />
              </Route>
              <Route path="search" element={<SearchPage />} />
            </Route>
          </Route>
        </Route>
        <Route element={<SpacesLayout />}>
          <Route path="/drafts" element={<DraftsRedirectPage />} />
          <Route path="/research-chat">
            <Route index element={<ChatSessionsRedirectPage />} />
            <Route
              element={<ResearchAssistantChatPage isResearchChat={true} />}
              path=":chatId"
            />
          </Route>
          <Route path="/category">
            <Route index element={<CategoriesRedirectPage />} />
            <Route path=":categoryId" element={<CategoryPage />} />
          </Route>
          <Route path="/home">
            <Route index element={<SpacesRedirectPage />} />
          </Route>
          <Route path="/spaces">
            <Route index element={<SpacesRedirectPage />} />
            <Route path=":projectId" element={<SpaceLayout />}>
              <Route index element={<Navigate to="./library" replace />} />
              <Route path="research-assistant" element={<ResearchAssistantIndex />} />
              <Route path="categories" element={<CategoriesPage />} />
              <Route path="library" element={<LibraryPage />} />
              <Route path="progress">
                <Route index element={<Navigate to="./list" replace />} />
                <Route path="list" element={<ProgressPage active="list" />} />
                <Route path="kanban" element={<ProgressPage active="kanban" />} />
              </Route>
            </Route>
          </Route>
        </Route>
        <Route path="/settings">
          <Route index element={<Navigate to="/settings/profile" />} />
          <Route path="profile">
            <Route element={<UserSettingsLayout />}>
              <Route index element={<ProfileManagerPage />} />
            </Route>
          </Route>
          <Route path="workspaces">
            <Route element={<UserSettingsLayout />}>
              <Route index element={<WorkspacesManagerPage />} />
            </Route>
            <Route element={<StarsLayout includeBacklink={true} withAuth={true} />}>
              <Route path="new" element={<CreateWorkspacePage />} />
            </Route>
          </Route>
        </Route>
        <Route element={<StarsLayout />}>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/welcome" element={<WelcomePage />} />
          <Route path="/500" element={<ServerErrorPage />} />
          <Route path="/error" element={<ErrorPage />} />
          <Route path="*" element={<NotFoundPage />} />
        </Route>
        <Route
          path="/mobile/media-groups/:mediaGroupId"
          element={<MobileMediaGroupPage />}
        />
      </>
    </Routes>
  );
};

import { useWorkspaceStore } from '@/stores/useWorkspaceStore';
import { useEffect } from 'react';
import { Outlet, useParams } from 'react-router';

const WorkspaceLayout = () => {
  const { workspaceId } = useParams();
  const setSessionWorkspaceId = useWorkspaceStore(
    (state) => state.setSessionWorkspaceId
  );

  useEffect(() => {
    setSessionWorkspaceId(workspaceId ?? null);
  }, [workspaceId]);

  return <Outlet />;
};

export default WorkspaceLayout;

import type { LinkFieldInstance } from '@spaceduck/api';
import { Icon16, Icon24 } from '@spaceduck/icons';
import clsx from 'clsx';
import { useRef, useState } from 'react';

import { prefixUrl } from '@/utils/url';
import { useOnClickOutside } from '@hooks/useOnClickOutside';
import Button from '@ui/Button';
import type { CommonProps } from '../InfoCategories';
import Empty from './Empty';
import styles from './Url.module.scss';

const { Add } = Icon16;
const { TrashCan } = Icon24;

export default function urlField(commonProps: CommonProps) {
  const { instances, updateField } = commonProps;
  const [showCreateEntry, setShowCreateEntry] = useState(false);
  const urlInstances = instances as LinkFieldInstance[];

  const handleUpdate = async (
    instance: LinkFieldInstance | null,
    instanceId?: string
  ) => {
    if (!instanceId && instance) {
      // Add
      await updateField?.([...instances, instance]);
    } else if (instanceId && !instance) {
      // Remove
      await updateField?.(
        instances.filter(
          (existingInstance) => existingInstance?.instanceId !== instanceId
        )
      );
    } else {
      // Modified
      await updateField?.(
        instances.map((existingInstance) =>
          existingInstance?.instanceId === instanceId ? instance : existingInstance
        )
      );
    }
  };

  const addEntry = () => {
    setShowCreateEntry(true);
  };

  return (
    <div className={styles.container}>
      <Button
        className={styles.addButton}
        onClick={addEntry}
        size="xs"
        title="Add a new entry"
        variant="icon"
      >
        <Add size={10} />
      </Button>
      {urlInstances.length
        ? urlInstances.map((textInstance, idx) => {
            return (
              <UrlEntry
                key={idx}
                textInstance={textInstance}
                updateField={handleUpdate}
              />
            );
          })
        : !showCreateEntry && (
            <div className={styles.hoverBg}>
              <Empty onClick={addEntry} />
            </div>
          )}
      {showCreateEntry && (
        <UrlEntry
          onHide={() => setShowCreateEntry(false)}
          showInputOnRender={true}
          textInstance={{ url: '' }}
          updateField={handleUpdate}
        />
      )}
    </div>
  );
}

const UrlEntry = ({
  onHide,
  showInputOnRender = false,
  textInstance,
  updateField,
}: {
  onHide?: () => void;
  showInputOnRender?: boolean;
  textInstance: LinkFieldInstance;
  updateField?: (
    instance: LinkFieldInstance | null,
    instanceId?: string
  ) => Promise<void>;
}) => {
  const _localValueUrl = useRef(textInstance?.url ?? '');
  const _localValueText = useRef(textInstance?.text ?? '');
  const [localValueUrl, setLocalValueUrl] = useState(textInstance?.url ?? '');
  const [localValueText, setLocalValueText] = useState(textInstance?.text ?? '');
  const [shouldShowEditView, setShouldShowEditView] = useState(showInputOnRender);

  const updateWithCheck = async () => {
    if (
      (textInstance.url !== _localValueUrl.current ||
        textInstance.text !== _localValueText.current) &&
      !(!textInstance.instanceId && !_localValueUrl.current)
    ) {
      const value = _localValueUrl.current;
      const url = prefixUrl(value)?.toString() ?? '';
      _localValueUrl.current = url;
      setLocalValueUrl(url);

      await updateField?.(
        _localValueUrl.current
          ? {
              ...textInstance,
              url,
              text: _localValueText.current ?? '',
            }
          : null,
        textInstance.instanceId
      );
    }
    onHide?.();
    setShouldShowEditView(false);
  };

  const deleteEntry = async () => {
    if (textInstance.instanceId) {
      await updateField?.(null, textInstance.instanceId);
    }
    onHide?.();
    setShouldShowEditView(false);
  };

  const { containerRef } = useOnClickOutside<HTMLDivElement>({
    callback: updateWithCheck,
  });

  const handleKeyDown = async (ev: React.KeyboardEvent) => {
    if (ev.key === 'Escape') {
      ev.preventDefault();
      ev.stopPropagation();
      setShouldShowEditView(false);
    } else if (ev.key === 'Enter') {
      updateWithCheck();
    }
  };

  const handleUrlChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = ev.currentTarget;
    _localValueUrl.current = value;
    setLocalValueUrl(value);
  };

  const handleTextChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = ev.currentTarget;
    _localValueText.current = value;
    setLocalValueText(value);
  };

  return (
    <div className={clsx(styles.wrapper, !shouldShowEditView && styles.hoverBg)}>
      {shouldShowEditView ? (
        <div className={styles.fields} ref={containerRef}>
          <input
            autoComplete="off"
            // biome-ignore lint/a11y/noAutofocus: non-disruptive and within context
            autoFocus
            className={styles.textarea}
            onChange={handleTextChange}
            onKeyDown={handleKeyDown}
            placeholder="Text"
            type="text"
            value={localValueText}
          />
          <div className={styles.entry}>
            <input
              autoComplete="off"
              className={styles.textarea}
              onChange={handleUrlChange}
              onKeyDown={handleKeyDown}
              placeholder="URL"
              type="url"
              value={localValueUrl}
            />
            <Button
              className={styles.removeButton}
              onClick={deleteEntry}
              size="xs"
              title="Remove"
              variant="icon"
            >
              <TrashCan size={16} />
            </Button>
          </div>
        </div>
      ) : (
        <div className={styles.hoverBg}>
          <div className={styles.truncated} onClick={() => setShouldShowEditView(true)}>
            <a
              className={styles.url}
              href={localValueUrl}
              onClick={(ev) => ev.stopPropagation()}
              rel="noopener noreferrer"
              target="_blank"
            >
              {localValueText || localValueUrl}
            </a>
          </div>
        </div>
      )}
    </div>
  );
};

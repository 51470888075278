import { useCallback, useContext, useEffect, useState } from 'react';
import type { NodeProps } from '@xyflow/react';

import { useMediaGroupDetail } from '@api/mediaGroup';

import { FlowCard } from '../components/FlowCard';
import { BoardStoreContext } from '../context/boardContext';
import { useCardUtils } from '../hooks/useCardUtils';
import type { ArticleNodeType } from '../types/board';
import { BookmarkPendingMessage } from '../../media/BookmarkPlaceholder';

import PreviewCard from '@components/PreviewCard';
import styles from './ArticleNode.module.scss';

export function ArticleNode(props: NodeProps<ArticleNodeType>) {
  const [hasMedia, setHasMedia] = useState(false);
  const [hasErrored, setHasErrored] = useState(false);
  const {
    data: mediaGroupData,
    isError,
    isPending,
    refetch,
  } = useMediaGroupDetail(props.data.mediaGroupId, {
    enabled: !hasErrored,
    refetchInterval: hasMedia ? undefined : 3000,
    retry: false,
  });

  const context = useContext(BoardStoreContext);
  const { openCardInSidebar } = useCardUtils();
  const handleDoubleClick = () => {
    openCardInSidebar({
      cardMediaGroupId: props.data.mediaGroupId,
      boardMediaGroupId: context?.mediaGroupId,
    });
  };

  useEffect(() => {
    if (isError) {
      setHasErrored(true);
    }
  }, [isError]);

  const mediaGroup = mediaGroupData?.mediaGroup;

  const firstMedia = mediaGroup?.media[0];

  useEffect(() => {
    if (firstMedia) {
      setHasMedia(true);
    }
  }, [firstMedia]);

  const handleRefetch = useCallback(() => refetch(), []);

  return (
    <FlowCard
      {...props}
      isError={isError}
      isLoading={isPending}
      mediaGroup={mediaGroup}
      refetch={handleRefetch}
    >
      {mediaGroup?.isGeneratingContent && !firstMedia && (
        <div className={styles.pendingWrapper}>
          <BookmarkPendingMessage className={styles.content} mediaGroup={mediaGroup} />
        </div>
      )}
      {!!firstMedia && (
        <PreviewCard
          firstMedia={firstMedia}
          mediaGroup={mediaGroup}
          onDoubleClick={handleDoubleClick}
        />
      )}
    </FlowCard>
  );
}

import { useWindowWidth } from '@react-hook/window-size';
import clsx from 'clsx';
import { useEffect } from 'react';
import { Outlet } from 'react-router';

import AuthWrapper from '@components/AuthWrapper';
import { useHorizontalSwipeDetection } from '@hooks/useHorizontalSwipeDetection';
import { adminLayout } from '@spaceduck/icons';
import { useSidebarStateStore } from '@stores/useSidebarStateStore';
import Button from '@ui/Button';
import styles from './TwoColumn.module.scss';
import { useShallow } from 'zustand/shallow';
const { ChevronLeft, ChevronRight, Pipe } = adminLayout;

export default function AdminLayout({
  children,
  modal,
}: {
  children?: React.ReactNode;
  modal?: React.ReactNode;
}) {
  const width = useWindowWidth();
  const { closeSidebar, openSidebar, sidebarIsOpen, toggleSidebar } =
    useSidebarStateStore(
      useShallow((state) => ({
        closeSidebar: state.closeSidebar,
        openSidebar: state.openSidebar,
        sidebarIsOpen: state.sidebarIsOpen,
        toggleSidebar: state.toggleSidebar,
      }))
    );

  useEffect(() => {
    if (sidebarIsOpen === null && width) {
      width < 768 ? closeSidebar() : openSidebar();
    }
  }, [width, sidebarIsOpen]);

  const swipeProps = useHorizontalSwipeDetection(closeSidebar);

  return (
    <AuthWrapper>
      <div className={clsx(styles.container, sidebarIsOpen && styles.showMobileMenu)}>
        <Overlay />
        <aside className={styles.sideMenu} {...swipeProps}>
          {children}
          <Button
            className={styles.sideMenuToggle}
            onClick={toggleSidebar}
            isSquare
            size="sm"
            type="button"
            variant="icon"
          >
            <ChevronLeft
              className={clsx(
                styles.hoverIcon,
                sidebarIsOpen ? styles.show : styles.hide
              )}
            />
            <ChevronRight
              className={clsx(
                styles.hoverIcon,
                !sidebarIsOpen ? styles.show : styles.hide
              )}
            />
            <Pipe className={styles.baseIcon} />
          </Button>
        </aside>
        <main className={styles.main}>
          <Outlet />
        </main>
      </div>
      {modal}
    </AuthWrapper>
  );
}

const Overlay = () => {
  const closeSidebar = useSidebarStateStore((state) => state.closeSidebar);
  const swipeProps = useHorizontalSwipeDetection(closeSidebar);

  return (
    <div className={styles.sideMenuUnderlay} onClick={closeSidebar} {...swipeProps} />
  );
};

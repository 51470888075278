import { useEffect } from 'react';
import { useKnockFeed } from '@knocklabs/react';

import { Icon16 } from '@spaceduck/icons';

import useWorkspaceId from '@hooks/useWorkspaceId';
import { urlFor } from '@/urls';
import MenuLink from '../MenuLink';
import styles from './MenuMainNavSection.module.scss';

const { Delete, Drafts, Home, InboxNotifications, Project } = Icon16;

// TODO: Disabled pending feature decision @ari
const ENABLE_INBOX_MENU_LINK = false;

const KnockCounter = () => {
  const feed = useKnockFeed();
  useEffect(() => {
    feed?.feedClient.fetch();
  }, [feed?.feedClient]);
  if (!feed) {
    return 0;
  }
  return feed.useFeedStore((state) => state.metadata.unread_count);
};

const InboxMenuLink = ({ workspaceId }: { workspaceId: string | null }) => {
  if (!ENABLE_INBOX_MENU_LINK) {
    return null;
  }
  if (!workspaceId) {
    return null;
  }

  return (
    <MenuLink
      icon={<InboxNotifications />}
      url={urlFor('workspaceInbox', { workspaceId })}
    >
      <span className={styles.inboxLink}>
        Inbox
        <span className={styles.inboxCounter}>
          <KnockCounter />
        </span>{' '}
      </span>
    </MenuLink>
  );
};

export default function MenuMainNavSection() {
  const workspaceId = useWorkspaceId();

  return (
    <nav className={styles.mainNav}>
      {workspaceId && (
        <>
          <MenuLink icon={<Home />} url={urlFor('workspaceHome', { workspaceId })}>
            Home
          </MenuLink>
          <MenuLink icon={<Project />} url={urlFor('workspaceSpaces', { workspaceId })}>
            Spaces
          </MenuLink>
          <MenuLink
            icon={<Drafts />}
            url={urlFor('workspaceRepository', { workspaceId })}
          >
            Drafts
          </MenuLink>
          <MenuLink
            icon={<Delete />}
            url={urlFor('workspaceTrashItems', { workspaceId })}
          >
            Trash
          </MenuLink>
          <InboxMenuLink workspaceId={workspaceId} />
        </>
      )}
    </nav>
  );
}

import type { IconProps } from '../types/IconProps';

export default function Icon({ className, size = 16 }: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 16 16"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_6169_119748)">
        <circle
          cx="8"
          cy="8"
          r="7"
          stroke="url(#paint0_linear_6169_119748)"
          strokeWidth="2"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_6169_119748"
          x1="0"
          y1="8"
          x2="16"
          y2="8"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6F53F9" />
          <stop offset="1" stopColor="#04D8DD" />
        </linearGradient>
        <clipPath id="clip0_6169_119748">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

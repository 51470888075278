import { identity } from '@/utils/functional';
import { useCallback } from 'react';
import { type NavigateOptions, useLocation, useNavigate } from 'react-router';

export type NavigateWithStateOptions = Omit<NavigateOptions, 'state'> & {
  nextState?: (current: any) => any;
};

export const useNavigateWithState = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const navigateWithState = useCallback(
    (url: string, options?: NavigateWithStateOptions) => {
      const { nextState, ...rest } = options ?? {};
      const state = (nextState ?? identity)(location.state);
      return navigate(url, { ...rest, state });
    },
    [navigate, location]
  );
  return navigateWithState;
};

import type { MediaGroupDTO } from '@spaceduck/api';
import { Icon16, Icon24 } from '@spaceduck/icons';
import { exists } from '@spaceduck/utils';
import Tippy from '@tippyjs/react';
import { type Editor, Node } from '@tiptap/core';
import {
  type NodeViewRendererProps,
  NodeViewWrapper,
  ReactNodeViewRenderer,
  mergeAttributes,
} from '@tiptap/react';
import clsx from 'clsx';

import { urlFor } from '@/urls';
import { useMediaGroupSummary } from '@api/mediaGroup';
import { useSidebarModal } from '@components/detailsModal/sidebar/SidebarModal';
import { ContentType } from '@components/icons';
import { useIsInWebViewRoute } from '@hooks/useIsWebView';
import Menu from '@ui/Menu';
import Spinner from '@ui/Spinner';
import { copyTextToClipboard } from '@utils/copyToClipboard';
import { downloadUrl } from '@utils/download';
import styles from './OutgoingLink.module.scss';
import { DetailsModalTooltip } from '../../comments/DetailsModalTooltip';

const { Copy, Download, Link, LinkCard, Open, TrashDelete } = Icon16;
const { AlertInfo, BacklinkOutgoing } = Icon24;

export default Node.create({
  name: 'outgoing-link',
  priority: 1000,
  group: 'inline',
  inline: true,
  atom: true,

  addAttributes() {
    return {
      id: {
        default: null,
      },
      label: {
        default: '',
      },
      contentType: {
        default: '',
      },
    };
  },

  addNodeView() {
    return ReactNodeViewRenderer(Component);
  },

  parseHTML() {
    return [
      {
        tag: 'outgoing-link',
        'data-minimal-view': {
          default: true,
        },
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['outgoing-link', mergeAttributes(HTMLAttributes)];
  },
});

const Component = (props: NodeViewRendererProps & { deleteNode?: () => void }) => {
  const { data, error, isLoading } = useMediaGroupSummary(props.node.attrs.id);

  const switchToContentBlock = (displayAsEmbed: boolean) => {
    const editor: Editor = props.editor;
    if (typeof props.getPos === 'function') {
      editor
        .chain()
        .setNodeSelection(props.getPos())
        .deleteSelection()
        .insertContent({
          type: 'content-block',
          attrs: {
            id: props.node.attrs.id,
            label: props.node.attrs.label,
            contentType: props.node.attrs.contentType,
            'data-minimal-view': `${displayAsEmbed}`,
          },
        })
        .run();
    }
  };

  if (isLoading) {
    return (
      <NodeViewWrapper>
        <span>
          <Spinner />
        </span>
      </NodeViewWrapper>
    );
  }

  if (error || !data?.mediaGroup) {
    return (
      <NodeViewWrapper>
        <DetailsModalTooltip content="Content removed or access unauthorized.">
          <span className={clsx(styles.backLink, styles.noAccess)}>
            <BacklinkOutgoing />
            <span className={styles.label}>No access</span>
          </span>
        </DetailsModalTooltip>
      </NodeViewWrapper>
    );
  }

  return (
    <NodeViewWrapper className={styles.nodeViewWrapper}>
      <OutgoingLink
        isEditable={props.editor.isEditable}
        mediaGroup={data.mediaGroup}
        onDelete={() => props.deleteNode?.()}
        switchToContentBlock={switchToContentBlock}
      />
    </NodeViewWrapper>
  );
};

export const OutgoingLink = ({
  isEditable,
  mediaGroup,
  onDelete,
  switchToContentBlock,
}: {
  isEditable: boolean;
  mediaGroup: MediaGroupDTO;
  onDelete?: () => void;
  switchToContentBlock?: (displayAsEmbed: boolean) => void;
}) => {
  const isInWebViewRoute = useIsInWebViewRoute();
  const { open: openSidebarModal } = useSidebarModal();

  const assetUrl = mediaGroup.media[0]?.assetUrl ?? null;

  if (isInWebViewRoute) {
    return (
      <span className={styles.outgoingLink}>
        <ContentType
          contentType={mediaGroup.contentType}
          mediaGroup={mediaGroup}
          size={20}
        />
        <span className={styles.label}>
          {mediaGroup.contentType === 'bookmark' && !mediaGroup.media.length
            ? mediaGroup.linkUrlSource
            : mediaGroup.label}
        </span>
      </span>
    );
  }

  return (
    <Tippy
      allowHTML
      duration={[0, 500]}
      interactive={true}
      content={
        <div className={styles.menuBar}>
          <Menu
            menuItems={[
              {
                icon: <Open />,
                onClick: () =>
                  window.open(urlFor('mediaGroup', { mediaGroupId: mediaGroup.id })),
                tooltip: 'View',
              },
              {
                isSeparator: true,
              },
              {
                icon: <AlertInfo size={16} />,
                tooltip: 'Info',
                onClick: () => openSidebarModal({ mediaGroupId: mediaGroup.id }),
              },
              assetUrl
                ? {
                    icon: <Download />,
                    onClick: () => downloadUrl(assetUrl),
                    tooltip: 'Download',
                  }
                : null,
              {
                icon: <Copy />,
                tooltip: 'Copy URL',
                onClick: () => {
                  copyTextToClipboard(
                    new URL(
                      urlFor('mediaGroup', { mediaGroupId: mediaGroup.id }),
                      window.location.origin
                    ).toString(),
                    {
                      bodyText: 'Share link copied to clipboard!',
                    }
                  );
                },
              },
              isEditable
                ? {
                    icon: <TrashDelete />,
                    onClick: () => onDelete?.(),
                    tooltip: 'Delete',
                  }
                : null,
              ...(!['document'].includes(mediaGroup.contentType)
                ? [
                    {
                      isSeparator: true,
                    },
                    {
                      title: 'View',
                      tooltip: 'View',
                      icon: <Link />,
                      menuItems: [
                        {
                          disabled: true,
                          icon: <Link />,
                          label: 'Inline view',
                          showCheck: true,
                        },
                        {
                          icon: <LinkCard />,
                          label: 'Card view',
                          onClick: () => switchToContentBlock?.(false),
                        },
                      ].filter(exists),
                    },
                  ]
                : []),
            ].filter(exists)}
          />
        </div>
      }
    >
      <span className={styles.outgoingLink}>
        <ContentType
          contentType={mediaGroup.contentType}
          mediaGroup={mediaGroup}
          size={20}
        />
        <span className={styles.label}>
          {mediaGroup.contentType === 'bookmark' && !mediaGroup.media.length
            ? mediaGroup.linkUrlSource
            : mediaGroup.label}
        </span>
      </span>
    </Tippy>
  );
};

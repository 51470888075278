import clsx from 'clsx';
import type { ReactNode } from 'react';

import styles from './TwoColumnContent.module.scss';
import { create } from 'zustand';

type TwoColumnContentProps = {
  children?: ReactNode;
  className?: string;
  contentClassName?: string;
  contentToolbar: ReactNode;
  sidebar: ReactNode;
};

type DetailsModal = {
  drawerIsOpen: boolean;
  toggleDrawer: () => void;
};

export const useProjectLayoutStore = create<DetailsModal>()((set) => ({
  drawerIsOpen: true,
  toggleDrawer: () => set(({ drawerIsOpen }) => ({ drawerIsOpen: !drawerIsOpen })),
}));

export default function TwoColumnContent({
  children,
  className,
  contentClassName,
  contentToolbar,
  sidebar,
}: TwoColumnContentProps) {
  const drawerIsOpen = useProjectLayoutStore((state) => state.drawerIsOpen);
  return (
    <div
      className={clsx(styles.container, drawerIsOpen && styles.hasSidebar, className)}
    >
      <div className={clsx(styles.content, contentClassName)}>
        {!!contentToolbar && (
          <div className={styles.stickyToolbar}>{contentToolbar}</div>
        )}
        {children}
      </div>
      {!!sidebar && (
        <div className={styles.sidebar}>
          <div className={styles.stickyWrapper}>{sidebar}</div>
        </div>
      )}
    </div>
  );
}

import { useCallback, useContext, useEffect } from 'react';
import type { NodeProps } from '@xyflow/react';
import { useMediaGroupDetail } from '@api/mediaGroup';
import { EditorContent } from '@tiptap/react';

import { useIsInWebViewRoute } from '@/hooks/useIsWebView';
import { useNotesEditor } from '@hooks/useNotesEditor';
import { urlFor } from '@/urls';
import { FlowCard } from '../components/FlowCard';
import { useCardUtils } from '../hooks/useCardUtils';
import { BoardStoreContext } from '../context/boardContext';
import type { DocumentNodeType } from '../types/board';
import SingleFieldEdit from '../../SingleFieldEdit';
import styles from './DocumentNode.module.scss';

export function DocumentNode(props: NodeProps<DocumentNodeType>) {
  const mediaGroupId = props.data.mediaGroupId;

  const isInWebViewRoute = useIsInWebViewRoute();
  const {
    data: mediaGroupData,
    isError,
    isLoading,
    refetch,
  } = useMediaGroupDetail(mediaGroupId, {
    retry: false,
    refetchOnWindowFocus: isInWebViewRoute,
  });

  const context = useContext(BoardStoreContext);
  const { openCardInSidebar } = useCardUtils();

  const handleDoubleClick = useCallback(() => {
    if (isInWebViewRoute) {
      const mediaGroupUrl = urlFor('mediaGroup', { mediaGroupId });
      window.open(mediaGroupUrl);
      return;
    }
    openCardInSidebar({
      cardMediaGroupId: mediaGroupId,
      boardMediaGroupId: context?.mediaGroupId,
    });
  }, [isInWebViewRoute, openCardInSidebar, mediaGroupId, context]);

  const mediaGroup = mediaGroupData?.mediaGroup;

  const { editor } = useNotesEditor(
    mediaGroup,
    {
      attributes: { 'data-is-card-view': 'true' },
      editable: () => false,
    },
    true
  );

  useEffect(() => {
    if (mediaGroup?.document && editor) {
      queueMicrotask(() => editor.commands.setContent(mediaGroup.document));
    }
  }, [editor, mediaGroup?.document]);

  const handleRefetch = useCallback(() => refetch(), []);

  const handleKeyDown = useCallback(
    (ev: React.KeyboardEvent<HTMLTextAreaElement> | undefined) => {
      if (ev?.key === 'ArrowDown') {
        const { selectionStart, selectionEnd, value } = ev.currentTarget;

        if (selectionStart === selectionEnd && selectionEnd === value.length) {
          ev.preventDefault();
          ev.stopPropagation();
          editor?.commands.focus();
          return true;
        }
      }
      return false;
    },
    [editor]
  );

  const handleSwitchFocus = useCallback(() => editor?.commands.focus(), [editor]);

  return (
    <FlowCard
      {...props}
      isError={isError}
      isLoading={isLoading}
      mediaGroup={mediaGroupData?.mediaGroup}
      refetch={handleRefetch}
    >
      <div className={styles.scrollArea} onDoubleClick={handleDoubleClick}>
        <div className={styles.cardContent}>
          <SingleFieldEdit
            displayAs="h1"
            displayStyle={styles.h1}
            editable={false}
            enterToSubmit
            fallback="Untitled"
            fieldName="label"
            fieldLabel="title"
            mediaGroupId={mediaGroupId}
            onKeyDown={handleKeyDown}
            required={true}
            switchFocus={handleSwitchFocus}
            textareaStyle={styles.h1}
          />
          <EditorContent className={styles.editor} editor={editor} />
        </div>
      </div>
    </FlowCard>
  );
}

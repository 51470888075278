import { useCallback, useContext } from 'react';
import type { NodeProps } from '@xyflow/react';

import { useMediaGroupDetail } from '@api/mediaGroup';
import Video from '@detailsModal/media/Video';
import { FlowCard } from '../components/FlowCard';
import { BoardStoreContext } from '../context/boardContext';
import { useCardUtils } from '../hooks/useCardUtils';
import type { VideoNodeType } from '../types/board';

export function VideoNode(props: NodeProps<VideoNodeType>) {
  const { mediaGroupId } = props.data;
  const {
    data: mediaGroupData,
    isError,
    isLoading,
    refetch,
  } = useMediaGroupDetail(mediaGroupId, {
    retry: false,
  });

  const context = useContext(BoardStoreContext);
  const { openCardInSidebar } = useCardUtils();
  const handleDoubleClick = () => {
    openCardInSidebar({
      cardMediaGroupId: props.data.mediaGroupId,
      boardMediaGroupId: context?.mediaGroupId,
    });
  };

  const mediaGroup = mediaGroupData?.mediaGroup;
  const handleRefetch = useCallback(() => refetch(), []);

  return (
    <FlowCard
      {...props}
      isError={isError}
      isLoading={isLoading}
      mediaGroup={mediaGroupData?.mediaGroup}
      refetch={handleRefetch}
    >
      {!mediaGroup ? null : (
        <Video
          mediaGroup={mediaGroupData.mediaGroup}
          onDoubleClick={handleDoubleClick}
        />
      )}
    </FlowCard>
  );
}

import type {
  CategoryFieldKindSettings,
  MediaGroupCategoryDetail,
  MediaGroupSortOption,
} from '@spaceduck/api';
import { exists as filterExists } from '@spaceduck/utils';
import {
  type CellContext,
  type RowData,
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import clsx from 'clsx';
import isEqual from 'lodash/isEqual';
import { useEffect, useState } from 'react';

import type { AvailableTypes, Row } from '@/types/Category';
import {
  usePatchMediaGroupCategory,
  usePatchMediaGroupCategoryField,
} from '@api/mediaGroupCategory';
import { useCategoryCellSelection } from '@hooks/useCategoryCellSelection';
import { useCategoryHeader } from '@hooks/useCategoryHeader';
import { useCategoryRow } from '@hooks/useCategoryRow';
import { useCategoryTags } from '@hooks/useCategoryTags';
import { css } from '@lib/css';
import ScrollArea from '@ui/ScrollArea';
import Spinner from '@ui/Spinner';
import styles from './Category.module.scss';
import CellContent from './CellContent';
import AddPropertyButton from './table/AddPropertyButton';
import CreateRowButton from './table/CreateRowButton';
import EmptyTableButton from './table/EmptyCategoryButton';
import FirstColumnTableCell from './table/FirstColumnTableCell';
import FirstColumnTableHeader from './table/FirstColumnTableHeader';
import SelectionToolbar from './table/SelectionToolbar';
import Table from './table/Table';
import TableColumnHeader from './table/TableColumnHeader';

declare module '@tanstack/react-table' {
  export interface TableMeta<TData extends RowData> {
    updateData: (rowIndex: number, columnId: string, value: AvailableTypes) => void;
  }
}

type CategoryTableProps = {
  category: MediaGroupCategoryDetail;
  searchQuery: string;
  sortBy: MediaGroupSortOption;
  loadingPlaceholder?: React.ReactNode;
};

const columnHelper = createColumnHelper<Row>();

export default function CategoryTable({
  searchQuery,
  sortBy,
  category,
  loadingPlaceholder,
}: CategoryTableProps) {
  const canEdit =
    category.capabilities?.find(({ capability }) => capability === 'edit')?.capable ??
    false;

  const { mutateAsync: patchMediaGroupCategory } = usePatchMediaGroupCategory();
  const { mutateAsync: patchMediaGroupCategoryField } = usePatchMediaGroupCategoryField(
    { categoryId: category.id }
  );

  const {
    categoryProperties,
    createNewProperty,
    deleteProperty,
    dispatchCategoryProperties,
    editPropertyAlias,
    editPropertyName,
    swapProperty,
  } = useCategoryHeader({
    category,
    patchFunction: patchMediaGroupCategoryField,
  });

  const {
    data,
    filteredRows,
    handleCellDelete,
    handleCellUpdate,
    handleCopyLink,
    handleRenameItem,
    handleRemoveItem,
    handleToggleAll,
    handleUnToggleAll,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
    isRefetching,
    mediaGroups,
    openDeleteConfirmModal,
    selectAllRows,
    selectedRows,
    setData,
    sortedColumns,
    toggleSelectedRow,
  } = useCategoryRow({
    categoryProperties,
    categoryId: category.id,
    searchQuery,
    sortBy,
  });

  const { addTagOption, editTagOption, openDeleteTagModal } = useCategoryTags({
    data,
    categoryProperties,
    handleCellUpdate,
    dispatchCategoryProperties,
    patchFunction: patchMediaGroupCategoryField,
    setData,
  });

  const { selectedCell, setSelectedCell } = useCategoryCellSelection({
    sortedColumns,
    mediaGroups,
  });

  const clearSelectedCell = () => setSelectedCell({ type: 'CLEAR' });

  const columns = [
    columnHelper.accessor('_mediaGroup', {
      header: () => (
        <FirstColumnTableHeader
          canEdit={canEdit}
          category={category}
          categoryProperties={categoryProperties}
          editPropertyAlias={editPropertyAlias}
          handleToggleAll={handleToggleAll}
          selectAllRows={selectAllRows}
        />
      ),
      cell: (info) => {
        const coordinates = {
          mediaGroupId: info.row.original._id,
          propertyId: info.column.id,
        };

        const isFocused = !!selectedCell && isEqual(coordinates, selectedCell);

        return (
          <FirstColumnTableCell
            canEdit={canEdit}
            clearSelectedCell={clearSelectedCell}
            info={info}
            selectedRows={selectedRows}
            setSelectedCell={setSelectedCell}
            showPreview={isFocused}
            toggleSelectedRow={toggleSelectedRow}
          />
        );
      },
      size: categoryProperties.find((meta) => meta.id === '_id')?.width || undefined,
    }),
    ...sortedColumns.map((col) =>
      columnHelper.accessor((row) => row._data?.[col.id], {
        id: col.id,
        header: () => (
          <TableColumnHeader
            canEdit={canEdit}
            categoryProperties={categoryProperties}
            clearSelectedCell={clearSelectedCell}
            handleDelete={() => deleteProperty(col.id)}
            handleEdit={(data) => editPropertyName({ data, id: col.id })}
            id={col.id}
            name={col.label}
            swapLeft={() => swapProperty({ direction: 'left', propertyId: col.id })}
            swapRight={() => swapProperty({ direction: 'right', propertyId: col.id })}
          />
        ),
        cell: (info: CellContext<Row, AvailableTypes | undefined>) => {
          const coordinates = {
            mediaGroupId: info.row.original._id,
            propertyId: info.column.id,
          };

          const isFocused = !!selectedCell && isEqual(coordinates, selectedCell);

          const property = categoryProperties.find(
            (property) => property.id === col.id
          );
          const value = info.getValue();
          if (!property) return value;
          const kindAndSettings = {
            kind: property.kind,
            settings: property.settings,
          } as CategoryFieldKindSettings;

          return (
            <CellContent
              addTagOption={addTagOption}
              canEdit={canEdit}
              clearSelectedCell={clearSelectedCell}
              columnData={col}
              deleteTagOption={async ({ propertyId, option }) =>
                openDeleteTagModal({ propertyId, option })
              }
              editTagOption={editTagOption}
              handleDelete={handleCellDelete}
              handleUpdate={handleCellUpdate}
              info={info}
              kindAndSettings={kindAndSettings}
              setSelectedCell={setSelectedCell}
              showPreview={isFocused}
              value={value ?? []}
            />
          );
        },
        size: categoryProperties.find((meta) => meta.id === col.id)?.width || undefined,
      })
    ),
    canEdit
      ? columnHelper.display({
          id: '_addColumn',
          header: () => (
            <AddPropertyButton
              onSubmit={(data) => createNewProperty(data)}
              category={category}
            />
          ),
          enableResizing: false,
          size: 72,
        })
      : undefined,
    columnHelper.display({
      id: '_gap',
      enableResizing: false,
    }),
  ].filter(filterExists);

  const table = useReactTable({
    data: filteredRows,
    columns,
    defaultColumn: {
      minSize: 60,
      maxSize: 800,
    },
    columnResizeMode: 'onChange',
    getCoreRowModel: getCoreRowModel(),
    meta: {
      updateData: (rowIndex: number, propertyId: string, value: AvailableTypes) => {
        const mediaGroupId = filteredRows?.[rowIndex]?._id;
        const dataIndex = data.findIndex((row) => row._mediaGroup?.id === mediaGroupId);
        setData({
          type: 'UPDATE_CELL',
          payload: { rowIndex: dataIndex, propertyId, value },
        });
      },
    },
  });

  const [currentWidths, setCurrentWidths] = useState(table.getState().columnSizing);

  useEffect(() => {
    const updateColumnWidths = () => {
      const { columnSizing } = table.getState();

      if (
        columnSizing &&
        table.getState().columnSizingInfo?.isResizingColumn === false
      ) {
        if ('_mediaGroup' in columnSizing && canEdit) {
          const width = columnSizing._mediaGroup;
          if (currentWidths._mediaGroup !== columnSizing._mediaGroup) {
            patchMediaGroupCategory({
              id: category.id,
              patch: { labelWidth: width },
            });
          }
        }

        for (const key in columnSizing) {
          const column = columns.find((column) => !!column.id && column.id === key);
          if (column?.id && canEdit) {
            const width = columnSizing[key];

            if (currentWidths[key] !== width) {
              patchMediaGroupCategoryField({
                mediaGroupCategoryFieldId: column.id,
                patch: { width },
              });
            }
          }
        }

        setCurrentWidths(columnSizing);
      }
    };

    updateColumnWidths();
  }, [table.getState().columnSizing, table.getState().columnSizingInfo]);

  if (hasNextPage || isFetchingNextPage || isRefetching || isLoading) {
    return loadingPlaceholder ?? <Spinner className={styles.spinner} />;
  }

  return (
    <>
      <SelectionToolbar
        canEdit={canEdit}
        handleCopyLink={handleCopyLink}
        handleDelete={() => openDeleteConfirmModal()}
        handleRemoveItem={handleRemoveItem}
        handleRenameItem={handleRenameItem}
        handleUnToggleAll={handleUnToggleAll}
        selectedRows={selectedRows}
      />
      <ScrollArea
        className={clsx(
          styles.tableWrapper,
          selectedRows.length > 0 && styles.hasToolbar
        )}
        rootClassName={styles.scrollAreaRoot}
        style={css({
          '--width': '100%',
          zIndex: 0,
        })}
      >
        <Table
          categoryProperties={categoryProperties}
          clearSelectedCell={clearSelectedCell}
          handleCopyLink={handleCopyLink}
          handleDelete={() => openDeleteConfirmModal()}
          handleRenameItem={handleRemoveItem}
          selectedCell={selectedCell}
          setSelectedCell={setSelectedCell}
          selectedRows={selectedRows}
          table={table}
        />
        <EmptyTableButton
          categoryId={category.id}
          shouldShow={canEdit && data.length === 0}
        />
        <CreateRowButton
          canEdit={canEdit}
          projectId={category.project.id}
          categoryId={category.id}
        />
      </ScrollArea>
    </>
  );
}

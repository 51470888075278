import { clsx } from 'clsx';
import { useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router';
import { z } from 'zod';

import ErrorDuck from '@assets/img/ErrorDuck';
import Head from '@components/Head';
import Button from '@ui/Button';
import { getDuckFact } from '@utils/getDuckFact';
import { safeParse } from '@utils/json';
import { openMailTo } from '@utils/openMailTo';
import styles from './ErrorPage.module.scss';

const errorSchema = z.object({
  code: z.string(),
  title: z.string(),
  body: z.string().default(''),
  info: z.any(),
});
type ErrorInfo = z.infer<typeof errorSchema>;

const getFallbackError = (): ErrorInfo => {
  const fact = getDuckFact();
  return {
    code: 'unknown',
    title: 'Server Failed Successfully...',
    body: `“${fact}.”`,
    info: null,
  };
};

export default function ErrorPage() {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const err = searchParams.get('err') ?? '{}';
  const method = searchParams.get('method') ?? '-';
  const url = searchParams.get('url') ?? '-';

  const error = useMemo<ErrorInfo>(() => {
    const deserializeResult = safeParse(err);
    if (!deserializeResult.success) {
      console.error('Failed to deserialize error json', deserializeResult.error);
      return getFallbackError();
    }
    const result = errorSchema.safeParse(deserializeResult.data);
    if (!result.success) {
      console.error('Failed to parse error data', err, result.error);
      return getFallbackError();
    }
    return result.data;
  }, [err]);

  const errorSummary = `${error.code} @ ${method} ${url}`;

  return (
    <>
      <Head title={error.title} />
      <div className={clsx('body5', styles.container)}>
        <ErrorDuck />
        <h1 className="h6">{error.title}</h1>
        <p>{error.body}</p>
        <div className={styles.buttonGroup}>
          <Button onClick={() => navigate('/')}>Go to the Spaceduck Homepage</Button>
          <Button
            variant="secondary"
            onClick={() => openMailTo(`[SUPPORT] ${errorSummary}`, '')}
          >
            Contact Support
          </Button>
        </div>
        <p className={styles.errorCode}>{errorSummary}</p>
      </div>
    </>
  );
}

import { useWorkspace } from '@api/workspace';
import useWorkspaceId from '@hooks/useWorkspaceId';
import Spinner from '@ui/Spinner';
import { knownErrors } from '@/const';
import Homepage from './workspace/Homepage';

export default function WorkspacePage() {
  const workspaceId = useWorkspaceId();
  const { data, error: workspaceError } = useWorkspace(workspaceId);

  if (workspaceError) {
    throw new Error(knownErrors.workspaceError, { cause: workspaceError });
  }

  if (!data?.workspace) {
    return <Spinner />;
  }

  return <Homepage workspace={data.workspace} />;
}

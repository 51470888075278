import { Link } from 'react-router';
import { sleep } from '@spaceduck/utils';
import type { BillingInterval } from '@spaceduck/api';

import { useGetSubscriptionInfo, useUpgradeSubscriptionLink } from '@api/billing';
import Spinner from '@components/Spinner';
import { useModalManager } from '@context/ModalManagerContext';
import useWorkspaceId from '@hooks/useWorkspaceId';
import Button from '@ui/Button';
import Dialog from '@ui/Dialog';
import { PlanUsageChart } from './plans/PlanUsageChart';
import { FeatureColumns } from '@/pages/settings/workspace/BillingAndPlan/FeaturesSummary';
import {
  getFeatures,
  getPlanDisplayName,
  PRICING_PRO,
} from '@/pages/settings/workspace/BillingAndPlan/billingData';
import RadioGroup, { RadioGroupItem } from './ui/RadioGroup';
import { useCallback, useState } from 'react';
import Badge from './ui/Badge';
import { Capitalized } from './Capitalized';
import createToast from '@/utils/createToast';
import { toastApiErrorOr } from '@/api/util';
import styles from './UsageModal.module.scss';

export const UsageModal = ({ closeModal }: { closeModal: () => void }) => {
  const workspaceId = useWorkspaceId();
  const { data, error } = useGetSubscriptionInfo(workspaceId);
  const { mutateAsync: upgradeSubscriptionLink, status } = useUpgradeSubscriptionLink();
  const [interval, setInterval] = useState<BillingInterval>('year');

  const handleChangeInterval = useCallback((value: BillingInterval) => {
    setInterval(value);
  }, []);

  const handleUpgrade = useCallback(async () => {
    if (!workspaceId) {
      console.error('Failed to get upgrade link due to missing workspaceId');
      createToast({
        titleText: 'Upgrade error',
        bodyText: 'Failed to prepare for upgrade. Please try again later.',
        iconVariant: 'warning',
      });
      return;
    }
    let response: Awaited<ReturnType<typeof upgradeSubscriptionLink>>;
    try {
      response = await upgradeSubscriptionLink({
        workspaceId,
        plan: 'pro',
        interval,
      });
    } catch (error) {
      return toastApiErrorOr(error, 'Failed to create upgrade subscription link', {
        titleText: 'Upgrade error',
        bodyText: 'Failed to prepare for upgrade. Please try again later.',
        iconVariant: 'warning',
      });
    }
    const { redirectUrl } = response;
    window.location.href = redirectUrl;
    // Give some time for the browser to process navigation while still showing
    // the loading indicator
    await sleep(1000);
  }, [workspaceId, interval]);

  if (!data) return <Spinner />;

  if (error) return null;

  const features = getFeatures('pro') ?? [];
  const planName = getPlanDisplayName(data.plan);

  return (
    <Dialog closeModal={closeModal} isOpen={true} maxWidth="860px" showHeader={false}>
      <div className={styles.usageModal}>
        <div className={styles.limits}>
          <div className={styles.title}>
            <Capitalized>{planName}</Capitalized> account limits
          </div>
          {workspaceId && <PlanUsageChart workspaceId={workspaceId} />}
          <div className={styles.moreInfo}>
            <Link to="https://www.spaceduck.com/pricing" target="_blank">
              Learn about our pricing
            </Link>
          </div>
        </div>
        <div className={styles.upgrade}>
          <div className={styles.upgradePoints}>
            <div className={styles.header}>
              <div className={styles.title}>Upgrade to unlimited</div>
              <div className={styles.body}>
                Upgrade to remove limits and access exclusive features.
              </div>
            </div>
            <FeatureColumns short features={features} />
          </div>

          <div className={styles.upgradeWidget}>
            <div className={styles.launchBadge}>
              🚀 Launch offer - 25% off all plans for the first 12-months.
            </div>
            <div className={styles.buttons}>
              <RadioGroup
                className={styles.intervalSelector}
                layout="banner"
                defaultValue={interval}
                onValueChange={handleChangeInterval}
              >
                <RadioGroupItem className={styles.intervalItem} value="year">
                  <div className={styles.interval}>
                    <div className={styles.label}>
                      <div className={styles.name}>Annual</div>
                      <Badge className={styles.discount}>Save 50%</Badge>
                    </div>
                    <div className={styles.price}>
                      <span className={styles.full}>${PRICING_PRO.yearly.normal}</span>
                      <span className={styles.value}>
                        ${PRICING_PRO.yearly.launchDiscounted}
                      </span>
                      <span className={styles.per}>/ mo</span>
                    </div>
                  </div>
                </RadioGroupItem>
                <RadioGroupItem className={styles.intervalItem} value="month">
                  <div className={styles.interval}>
                    <div className={styles.label}>
                      <div className={styles.name}>Monthly</div>
                    </div>
                    <div className={styles.price}>
                      <span className={styles.full}>${PRICING_PRO.monthly.normal}</span>
                      <span className={styles.value}>
                        ${PRICING_PRO.monthly.launchDiscounted}
                      </span>
                      <span className={styles.per}>/ mo</span>
                    </div>
                  </div>
                </RadioGroupItem>
              </RadioGroup>
              <Button
                className={styles.upgradeNow}
                variant="primary"
                size="md"
                onClick={handleUpgrade}
              >
                {status === 'pending' ? <Spinner /> : 'Upgrade now'}
              </Button>
            </div>
            <div className={styles.joinMessage}>
              Join thousands of researchers today...
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export const useUsageModal = () => {
  const { openModal, closeModal } = useModalManager();

  return {
    open: () =>
      openModal({
        component: <UsageModal closeModal={closeModal} />,
      }),
    close: closeModal,
  };
};

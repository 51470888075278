import { useCallback, useEffect, useState } from 'react';
import { isEqual } from 'lodash';

import type {
  AiModel,
  Chat,
  ChatMediaGroupSource,
  ChatTemporaryFileSource,
  ChatTemporaryLinkSource,
  MediaGroupDTO,
} from '@spaceduck/api';
import { exists } from '@spaceduck/utils';
import { Icon24 } from '@spaceduck/icons';

import { useCreateChatSession, useGetChatSession, useQueryChatSession } from '@api/ai';
import { toastApiErrorOr } from '@api/util';
import ChatMessageInput from '@components/ai/ChatMessageInput';
import { ChatHistory } from '@components/ai/ChatHistory';
import Spinner from '@components/Spinner';
import { ChatContent, SourcesPanel } from '@pages/ai/ResearchAssistant';
import Button from '@ui/Button';
import styles from './ResearchChat.module.scss';

const { ResearchChatAI, ChatHistory: ChatHistoryIcon, Close } = Icon24;

export const ResearchChat = ({
  addToCurrentDocument,
  mediaGroup,
}: {
  addToCurrentDocument: (text: string) => void;
  mediaGroup: MediaGroupDTO;
}) => {
  const projectId = mediaGroup.project?.id;
  const { mutateAsync: queryChatSession } = useQueryChatSession();
  const { mutateAsync: createChatSession, reset } = useCreateChatSession();
  const [chatId, setChatId] = useState<string | null>(null);
  const { data: chatSessionData, isLoading } = useGetChatSession(chatId);
  const [showChatHistory, setShowChatHistory] = useState(false);
  const [showSources, setShowSources] = useState(false);
  const [currentChatSession, setCurrentChatSession] = useState<Chat | null>(null);

  useEffect(() => {
    if (chatSessionData) {
      if (!isEqual(chatSessionData.chat, currentChatSession)) {
        setCurrentChatSession(chatSessionData.chat);
      }

      return;
    }

    setCurrentChatSession(null);
  }, [chatSessionData]);

  const submitChatSession = async (
    query: string,
    mediaGroups: ChatMediaGroupSource[],
    links: ChatTemporaryLinkSource[],
    temporaryFiles: ChatTemporaryFileSource[],
    model: AiModel
  ) => {
    if (!projectId) {
      return false;
    }
    setCurrentChatSession({
      projectId,
      id: 'temp',
      label: query,
      mediaGroups,
      messages: [
        {
          id: '',
          pending: true,
          query,
          response: {
            parts: [],
          },
        },
      ],
      model,
    });
    let response: Awaited<ReturnType<typeof createChatSession>>;
    try {
      response = await createChatSession({
        projectId,
        model,
        mediaGroupIds: mediaGroups.map((mediaGroup) => mediaGroup.id),
        links: links.map((link) => link.id).filter(exists),
        temporaryFiles: temporaryFiles.map((file) => file.id),
        initialQuery: query,
      });
    } catch (error) {
      toastApiErrorOr(error, 'Failed to create chat session', {
        iconVariant: 'warning',
        titleText: 'Chat Session Error',
        bodyText:
          'An unknown error occurred while creating chat session. Please try again later',
      });
      setCurrentChatSession(null);
      reset();
      return false;
    }
    setChatId(response.chat.id);
    return true;
  };

  const queryChatSessionCallback = useCallback(
    async (query: string) => {
      if (currentChatSession) {
        setCurrentChatSession({
          ...currentChatSession,
          messages: [
            ...currentChatSession.messages,
            {
              id: '',
              pending: true,
              query,
              response: {
                parts: [],
              },
            },
          ],
        });
        try {
          await queryChatSession({
            chatId: currentChatSession.id,
            query,
          });
        } catch (error) {
          toastApiErrorOr(error, 'Error creating chat session', {
            iconVariant: 'warning',
            titleText: 'Failed to add message',
            bodyText:
              'Unknown error occurred while adding your message. Please try again later.',
          });
        }
      }
      return false;
    },
    [currentChatSession]
  );

  if (!projectId) {
    return;
  }

  if (isLoading) {
    return <Spinner />;
  }
  if (showChatHistory) {
    return (
      <div className={styles.chatHistory}>
        <div className={styles.header}>
          <span>Chat history</span>
          <Button
            size="sm"
            padX="2"
            variant="outlined"
            onClick={() => setShowChatHistory(false)}
          >
            <Close size={16} />
          </Button>
        </div>
        <ChatHistory
          projectId={projectId}
          onSelectChatSession={(chatId) => {
            setChatId(chatId);
            setShowChatHistory(false);
          }}
        />
      </div>
    );
  }

  if (showSources && currentChatSession) {
    return (
      <SourcesPanel
        onClose={() => setShowSources(false)}
        sources={currentChatSession.mediaGroups || []}
        title={currentChatSession.label}
      />
    );
  }

  return (
    <div className={styles.researchChat}>
      <div className={styles.header}>
        <span>Research assistant</span>
        <div className={styles.actions}>
          <Button
            variant="outlined"
            size="sm"
            onClick={() => {
              setCurrentChatSession(null);
              setChatId(null);
            }}
          >
            New chat
          </Button>
          <Button
            variant="outlined"
            padX="2"
            size="sm"
            onClick={() => setShowChatHistory(true)}
          >
            <ChatHistoryIcon size={20} />
          </Button>
        </div>
      </div>
      <div className={styles.chatContent}>
        {currentChatSession && (
          <ChatContent
            addResponseAction={addToCurrentDocument}
            chatId={currentChatSession.id}
            expandSources={() => setShowSources(true)}
            hasPreviousMessages={!!currentChatSession}
            messages={currentChatSession.messages}
            showSourcesCount={2}
            sources={currentChatSession.mediaGroups}
          />
        )}
        {!currentChatSession && (
          <div className={styles.emptyChat}>
            <ResearchChatAI />
            <p>
              Hello! I am your AI research assistant. I have access to your library and
              the web. Try asking me some questions...
            </p>
          </div>
        )}
      </div>
      <div className={styles.chatMessageInput}>
        <ChatMessageInput
          currentChatSession={currentChatSession}
          initialSources={
            !currentChatSession
              ? [
                  {
                    id: mediaGroup.id,
                    label: mediaGroup.label,
                    type: 'library',
                  },
                ]
              : []
          }
          projectId={projectId}
          readOnly={!!currentChatSession}
          onSubmit={(query, ...rest) => {
            return currentChatSession
              ? queryChatSessionCallback(query)
              : submitChatSession(query, ...rest);
          }}
          showTools={!currentChatSession}
        />
      </div>
    </div>
  );
};

import {
  createMediaGroupCategory,
  createMediaGroupCategoryField,
  deleteMediaGroupCategory,
  deleteMediaGroupCategoryField,
  patchMediaGroupCategory,
  patchMediaGroupCategoryField,
  reorderMediaGroupCategoryFields,
  setMediaGroupCategoryFieldInstances,
} from '@spaceduck/api';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { mediaGroupKeys } from './mediaGroup';
import { projectKeys } from './project';

export const useCreateMediaGroupCategory = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: createMediaGroupCategory,
    onSuccess: async (_data, variables) => {
      await queryClient.invalidateQueries({
        queryKey: projectKeys.one(variables.projectId),
      });
    },
  });
  return mutation;
};

export const useCreateMediaGroupCategoryField = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: createMediaGroupCategoryField,
    onSuccess: async (_data, variables) => {
      await queryClient.invalidateQueries({
        queryKey: projectKeys.mediaGroupCategory(variables.categoryId),
      });
    },
  });
  return mutation;
};

export const useDeleteMediaGroupCategoryField = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: deleteMediaGroupCategoryField,
    onSuccess: async (data) => {
      await queryClient.invalidateQueries({
        queryKey: projectKeys.mediaGroupCategory(data.field.categoryId),
      });
    },
  });
  return mutation;
};

export const usePatchMediaGroupCategoryField = ({
  categoryId,
}: {
  categoryId: string;
}) => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: patchMediaGroupCategoryField,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: projectKeys.mediaGroupCategory(categoryId),
      });
    },
  });
  return mutation;
};

export const useSetMediaGroupCategoryFieldInstances = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: setMediaGroupCategoryFieldInstances,
    onSuccess: (_data, variables) => {
      queryClient.invalidateQueries({
        queryKey: mediaGroupKeys.detail(variables.mediaGroupId),
      });
    },
  });
  return mutation;
};

export const usePatchMediaGroupCategory = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: patchMediaGroupCategory,
    onSuccess: async (data) => {
      await queryClient.invalidateQueries({
        queryKey: projectKeys.one(data.category.project.id),
      });

      await queryClient.invalidateQueries({
        queryKey: projectKeys.mediaGroupCategory(data.category.id),
      });
    },
  });
  return mutation;
};

export const useReorderMediaGroupCategoryFields = ({
  categoryId,
}: {
  categoryId: string;
}) => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: ({ id, fields }: { id: string; fields: string[] }) =>
      reorderMediaGroupCategoryFields(id, { fields }),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: projectKeys.mediaGroupCategory(categoryId),
      });
    },
  });
  return mutation;
};

export const useDeleteMediaGroupCategory = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: deleteMediaGroupCategory,
    onSuccess: async (data) => {
      await queryClient.invalidateQueries({
        queryKey: projectKeys.one(data.category.projectId),
      });
    },
  });
  return mutation;
};

import { z } from 'zod';

import { ApiClient } from '../client';
import { successfulResponseSchema } from '../schema';
import { apiUrl } from '../util';
import { type BillingInterval, type BillingPlan, billingPlanSchema } from './schemas';

export const getManageSubscriptionLink = async (workspaceId: string) =>
  ApiClient.call({
    endpoint: apiUrl`/auth/billing/portal/`,
    method: 'GET',
    params: {
      workspaceId,
    },
    responseSchema: successfulResponseSchema.extend({
      redirectUrl: z.string(),
    }),
  });

const usageCategoriesSchema = z.enum([
  'projectCount',
  'mediaGroupCount',
  'fileStorage',
  'aiWritingWordsPerDay',
  'aiAssistantInteractionsPerDay',
  'aiSummaryCount',
]);

export type UsageCategories = z.infer<typeof usageCategoriesSchema>;

export const workspaceUsageSchema = z.object({
  planLimit: z.number().nullable(),
  currentUsage: z.number(),
  limitName: usageCategoriesSchema.nullable().catch(null),
});

export type WorkspaceUsage = z.infer<typeof workspaceUsageSchema>;

export const getSubscriptionInfo = async (workspaceId: string) =>
  ApiClient.call({
    endpoint: apiUrl`/auth/billing/info/`,
    method: 'GET',
    params: {
      workspaceId,
    },
    responseSchema: successfulResponseSchema.extend({
      interval: z.nullable(z.string()),
      plan: billingPlanSchema,
      seats: z.number(),
      viewers: z.number().nullable(),
      assignedViewers: z.number(),
      assignedSeats: z.number(),
      usage: workspaceUsageSchema.array(),
    }),
  });

export const getUpgradeSubscriptionLink = async (
  workspaceId: string,
  plan: BillingPlan,
  interval: BillingInterval
) =>
  ApiClient.call({
    endpoint: apiUrl`/auth/billing/checkout/`,
    body: {
      workspaceId,
      plan,
      interval,
    },
    method: 'POST',
    responseSchema: successfulResponseSchema.extend({
      redirectUrl: z.string(),
    }),
  });

import clsx from 'clsx';
import {
  type ChangeEvent,
  type KeyboardEvent,
  useEffect,
  useRef,
  useState,
} from 'react';

import { Icon24 } from '@spaceduck/icons';

import styles from './MenuSearch.module.scss';

const { Search } = Icon24;

export type MenuSearchProps = {
  onChange: (ev: ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: (ev: KeyboardEvent) => void;
  placeholder?: string;
  value: string;
};

export default function MenuSearch({
  onChange,
  onKeyDown,
  placeholder = 'Search',
  value,
}: MenuSearchProps) {
  const wrapperRef = useRef<HTMLLabelElement>(null);
  const [isOverlapping, setIsOverlapping] = useState(false);
  // Scroll overlap detection: https://css-tricks.com/how-to-detect-when-a-sticky-element-gets-pinned/
  const observer = new IntersectionObserver(
    ([e]) => {
      e && setIsOverlapping(e.intersectionRatio < 0.995);
    },
    { threshold: [1] }
  );

  useEffect(() => {
    if (wrapperRef.current) {
      observer.observe(wrapperRef.current);
    }

    return () => observer.disconnect();
  }, [wrapperRef.current]);

  return (
    <label
      className={clsx(styles.searchbox, isOverlapping && styles.addBlurFilter)}
      ref={wrapperRef}
    >
      <Search size={20} />
      <input
        onChange={onChange}
        onKeyDown={onKeyDown}
        placeholder={placeholder}
        type="search"
        value={value}
      />
    </label>
  );
}

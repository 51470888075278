import type { MediaGroupDTO } from '@spaceduck/api';
import { Node } from '@tiptap/core';
import {
  type NodeViewRendererProps,
  NodeViewWrapper,
  ReactNodeViewRenderer,
  mergeAttributes,
} from '@tiptap/react';
import clsx from 'clsx';

import { useMediaGroupSummary } from '@api/mediaGroup';
import styles from './Reference.module.scss';
import { urlFor } from '@/urls';
import { DetailsModalTooltip } from '../../comments/DetailsModalTooltip';

export default Node.create({
  name: 'reference',
  priority: 1000,
  group: 'inline',
  inline: true,
  atom: true,

  addAttributes() {
    return {
      id: {
        default: null,
      },
    };
  },

  addNodeView() {
    return ReactNodeViewRenderer(Component);
  },

  parseHTML() {
    return [
      {
        tag: 'reference',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['reference', mergeAttributes(HTMLAttributes)];
  },
});

const Component = (props: NodeViewRendererProps & { deleteNode?: () => void }) => {
  const { data, error, isLoading } = useMediaGroupSummary(props.node.attrs.id);

  if (isLoading) {
    return (
      <NodeViewWrapper className={styles.nodeViewWrapper}>
        <span className={styles.reference}>(Loading...)</span>
      </NodeViewWrapper>
    );
  }

  if (error || !data?.mediaGroup) {
    return (
      <NodeViewWrapper className={styles.nodeViewWrapper}>
        <DetailsModalTooltip content="Content removed or access unauthorized.">
          <span className={clsx(styles.reference, styles.noAccess)}>(No access)</span>
        </DetailsModalTooltip>
      </NodeViewWrapper>
    );
  }

  return (
    <NodeViewWrapper className={styles.nodeViewWrapper}>
      <Reference mediaGroup={data.mediaGroup} />
    </NodeViewWrapper>
  );
};

const Reference = ({
  mediaGroup,
}: {
  mediaGroup: MediaGroupDTO;
}) => {
  return (
    <span
      className={styles.reference}
      onClick={() =>
        window.open(
          urlFor('mediaGroup', {
            mediaGroupId: mediaGroup.id,
          })
        )
      }
    >
      ({mediaGroup.label})
    </span>
  );
};

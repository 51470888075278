import { useMutation, useMutationState } from '@tanstack/react-query';

import { getManageSubscriptionLink } from '@spaceduck/api';
import { sleep } from '@spaceduck/utils';

import { toastApiErrorOr } from '@/api/util';
import { identity } from '@/utils/functional';

const navigateToManageSubscriptionLink = async (workspaceId: string) => {
  let subscriptionLink: Awaited<ReturnType<typeof getManageSubscriptionLink>>;
  try {
    subscriptionLink = await getManageSubscriptionLink(workspaceId);
  } catch (error) {
    return toastApiErrorOr(error, 'Failed to create subscription link', {
      iconVariant: 'warning',
      titleText: 'Subscription Management Error',
      bodyText:
        'An unknown error occurred opening subscription management. Please try again later',
    });
  }
  window.location.href = subscriptionLink.redirectUrl;
  // Give some buffer for the redirect to actually happen
  await sleep(1000);
};

export const useManageSubscription = () => {
  const mutationKey = ['navigateToManageSubscriptionLink'];
  const isPending = useMutationState({
    filters: { mutationKey },
    select: (mutation) => mutation.state.status === 'pending',
  });
  const isAnyPending = isPending.find(identity) ?? false;
  const mutation = useMutation({
    mutationKey,
    mutationFn: async (workspaceId: string) => {
      await navigateToManageSubscriptionLink(workspaceId);
    },
  });
  return { isAnyPending, ...mutation };
};

import type { IconProps } from '../types/IconProps';

export default function Icon({
  className,
  color = 'currentcolor',
  size = 16,
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 16 16"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.79289 15.2071C9.60536 15.3946 9.351 15.5 9.08579 15.5H2.5C1.94772 15.5 1.5 15.0523 1.5 14.5V1.5C1.5 0.947715 1.94772 0.5 2.5 0.5H13.5C14.0523 0.5 14.5 0.947715 14.5 1.5V10.0858C14.5 10.351 14.3946 10.6054 14.2071 10.7929L9.79289 15.2071Z"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.5 15.5V11.5C9.5 10.9477 9.94772 10.5 10.5 10.5H14.5"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.5 4.5H11.5"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.5 7.5H11.5"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.5 10.5H6.5"
        stroke={color}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

import {
  useChatTemporaryFile,
  useChatTemporaryLink,
  useTriggerMediaGroupProcessing,
} from '@/api/ai';
import { useMediaGroupSummary } from '@/api/mediaGroup';
import { asMilliseconds } from '@spaceduck/utils';
import { type ReactNode, useEffect, useState } from 'react';
import { ContentType } from '../icons';
import Spinner from '../ui/Spinner';
import styles from './InlineSource.module.scss';
import { Icon24 } from '../../../packages/icons/src';
const { Link } = Icon24;
import { useSourcesStore, type SelectedSourceType } from './useSourcesStore';
import Tag from '../ui/Tag';
import { useShallow } from 'zustand/shallow';

export const SelectedSource = (
  props: SelectedSourceType & {
    onDelete: () => void;
  }
) => {
  const { addIncludedSource, removeIncludedSource } = useSourcesStore(
    useShallow((state) => ({
      addIncludedSource: state.addIncludedSource,
      removeIncludedSource: state.removeIncludedSource,
    }))
  );
  const [icon, setIcon] = useState<ReactNode>();
  const [resolved, setResolved] = useState(false);
  const [label, setLabel] = useState(props.label);
  const { data: mediaGroupData } = useMediaGroupSummary(
    (props.type === 'library' && props.id) || null,
    asMilliseconds({ seconds: 3 })
  );
  const { data: linkData } = useChatTemporaryLink(
    (props.type === 'link' && props.id) || null,
    asMilliseconds({ seconds: 3 })
  );
  const { data: fileData } = useChatTemporaryFile(
    (props.type === 'file' && props.id) || null,
    asMilliseconds({ seconds: 3 })
  );
  useEffect(() => {
    return () => {
      removeIncludedSource(props);
    };
  }, []);
  const {
    mutateAsync: triggerMediaGroupProcessing,
    status: triggerProcessingStatus,
    reset: resetProcessingStatus,
  } = useTriggerMediaGroupProcessing();
  useEffect(() => {
    if (props.type === 'link' || props.type === 'file') {
      if (linkData?.link.state === 'E' || fileData?.file.state === 'E') {
        setResolved(true);
        if (linkData) {
          setIcon(<Link size={20} />);
          addIncludedSource({
            type: 'link',
            pending: false,
            url: props.label,
            id: linkData.link.id,
          });
        }
        if (fileData) {
          setIcon(<ContentType contentType={fileData.file.contentType} size={20} />);
          addIncludedSource({
            type: 'file',
            pending: false,
            id: fileData.file.id,
            contentType: fileData.file.contentType,
            label: fileData.file.filename,
          });
        }
      }
      if (
        linkData?.link.state === 'F' ||
        fileData?.file.state === 'F' ||
        linkData?.link.state === 'R' ||
        fileData?.file.state === 'R'
      ) {
        props.onDelete();
      }
    }

    if (props.type === 'library' && mediaGroupData) {
      setLabel(mediaGroupData.mediaGroup.label);
    }

    if (
      props.type === 'library' &&
      mediaGroupData?.mediaGroup.isResearchAssistantReady
    ) {
      //  TODO: failed state for media group chunking
      addIncludedSource({
        type: 'library',
        label: mediaGroupData.mediaGroup.label,
        description: '',
        container: mediaGroupData.mediaGroup.project?.label || 'Drafts',
        contentType: mediaGroupData.mediaGroup.contentType,
        id: mediaGroupData.mediaGroup.id,
        kind: mediaGroupData.mediaGroup.kind,
        linkUrl: mediaGroupData.mediaGroup.linkUrl,
        linkUrlSource: mediaGroupData.mediaGroup.linkUrlSource,
        pending: !mediaGroupData.mediaGroup.isResearchAssistantReady,
      });
      setIcon(<ContentType contentType={mediaGroupData.mediaGroup.contentType} />);
      setResolved(true);
      resetProcessingStatus();
    }

    if (
      props.type === 'library' &&
      mediaGroupData?.mediaGroup.isResearchAssistantReady === false &&
      triggerProcessingStatus === 'idle'
    ) {
      triggerMediaGroupProcessing(mediaGroupData.mediaGroup.id);
      removeIncludedSource(props);
      setResolved(false);
    }
  }, [linkData, mediaGroupData, fileData, addIncludedSource]);
  if (!resolved) {
    return (
      <Tag
        className={styles.selectedSource}
        onRemoveClick={props.onDelete}
        removeIconIsHidden
        size="lg"
        variant="quaternary"
      >
        <Spinner size={20} />
        <span className={styles.label}>{label}</span>
      </Tag>
    );
  }
  return (
    <Tag
      className={styles.selectedSource}
      onRemoveClick={props.onDelete}
      removeIconIsHidden
      size="lg"
      variant="quaternary"
    >
      {' '}
      {icon}
      <span className={styles.label}>{label}</span>
    </Tag>
  );
};

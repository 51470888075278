import { useTags } from '@/api/tags';
import { useDebouncedValue } from '@hooks/useDebouncedValue';
import { useEffect, useMemo, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import useWorkspaceId from './useWorkspaceId';
import { stringContains } from '@/utils/string';

export const useTagSuggestions = () => {
  const workspaceId = useWorkspaceId();
  const [tagInput, setTagInput] = useState('');
  const debouncedSearchQuery = useDebouncedValue(tagInput);
  const { ref, inView } = useInView();

  const {
    data: paginator,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useTags(workspaceId, debouncedSearchQuery);

  useEffect(() => {
    if (inView && hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [inView, hasNextPage, fetchNextPage, isFetchingNextPage]);

  const workspaceTags = paginator?.pages.flatMap((page) => page.tags);
  const allTags = workspaceTags?.map((tag) => tag.label) ?? [];

  const results = useMemo(
    () => allTags.filter((tag) => stringContains(tag, tagInput, { ignoreCase: true })),
    [tagInput, allTags]
  );

  return {
    inView,
    ref,
    results,
    setTagInput,
    tagInput,
  };
};

import { useCallback, useContext } from 'react';
import type { NodeProps } from '@xyflow/react';
import { useMediaGroupDetail } from '@api/mediaGroup';

import { ExtractBlockComponent } from '@components/ExtractBlock';
import { FlowCard } from '../components/FlowCard';
import { BoardStoreContext } from '../context/boardContext';
import { useCardUtils } from '../hooks/useCardUtils';
import type { HighlightNodeType } from '../types/board';
import styles from './HighlightNode.module.scss';

export function HighlightNode(props: NodeProps<HighlightNodeType>) {
  const {
    data: mediaGroupData,
    isError,
    isLoading,
    refetch,
  } = useMediaGroupDetail(props.data.mediaGroupId, {
    retry: false,
  });

  const context = useContext(BoardStoreContext);
  const { openCardInSidebar } = useCardUtils();
  const handleDoubleClick = () => {
    openCardInSidebar({
      cardMediaGroupId: props.data.mediaGroupId,
      boardMediaGroupId: context?.mediaGroupId,
    });
  };

  const mediaGroup = mediaGroupData?.mediaGroup;
  const handleRefetch = useCallback(() => refetch(), []);

  return (
    <FlowCard
      {...props}
      isError={isError}
      isLoading={isLoading}
      mediaGroup={mediaGroup}
      refetch={handleRefetch}
    >
      <div className={styles.scrollArea}>
        <ExtractBlockComponent
          className={styles.extract}
          isQuote={mediaGroup?.contentType === 'quote'}
          isVerticallyCentered
          onDoubleClick={handleDoubleClick}
          palette="surface3"
          text={mediaGroup?.text ?? ''}
        />
      </div>
    </FlowCard>
  );
}

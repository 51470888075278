import { useMemo } from 'react';
import { useLocation } from 'react-router';

const PATTERN = /WebView|(iPhone|iPod|iPad)(?!.*Safari)/i;

export const useIsWebView = () => {
  const userAgent = window.navigator?.userAgent ?? '';
  return useMemo(() => PATTERN.test(userAgent), [userAgent]);
};

export const useIsInWebViewRoute = () => {
  const { pathname } = useLocation();

  return useMemo(() => pathname.startsWith('/mobile/'), [pathname]);
};

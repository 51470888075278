import {
  type ReactEventHandler,
  useCallback,
  useContext,
  useState,
  type MouseEvent as ReactMouseEvent,
  useMemo,
} from 'react';
import clsx from 'clsx';
import type { NodeProps } from '@xyflow/react';
import { useMediaGroupDetail } from '@api/mediaGroup';
import { Icon24 } from '@spaceduck/icons';

import LoadingPlaceholder from '@components/LoadingPlaceholder';
import { PdfDocument, PdfPage } from '@components/PdfDocument';
import Button from '@ui/Button';
import { PdfPlaceholder } from '../../media/PdfView';
import { FlowCard } from '../components/FlowCard';
import { BoardStoreContext } from '../context/boardContext';
import { useCardUtils } from '../hooks/useCardUtils';
import type { PdfNodeType } from '../types/board';
import styles from './PdfNode.module.scss';
import { DetailsModalTooltip } from '../../comments/DetailsModalTooltip';

const { Left, Right } = Icon24;

export function PdfNode(props: NodeProps<PdfNodeType>) {
  const {
    data: mediaGroupData,
    isError,
    isLoading,
    refetch,
  } = useMediaGroupDetail(props.data.mediaGroupId, {
    retry: false,
  });

  const handleRefetch = useCallback(() => {
    refetch();
  }, [refetch]);

  const context = useContext(BoardStoreContext);
  const { openCardInSidebar } = useCardUtils();

  const cardMediaGroupId = props.data.mediaGroupId;
  const boardMediaGroupId = context?.mediaGroupId;

  const handleDoubleClick = useCallback(() => {
    openCardInSidebar({ cardMediaGroupId, boardMediaGroupId });
  }, [openCardInSidebar, cardMediaGroupId, boardMediaGroupId]);

  const firstMedia = mediaGroupData?.mediaGroup.media[0];

  const preview = useMemo(() => {
    if (!firstMedia) {
      return null;
    }
    return (
      <PdfPreview
        name={firstMedia.assetName}
        onDoubleClick={handleDoubleClick}
        url={firstMedia.assetUrl}
      />
    );
  }, [firstMedia]);

  return (
    <FlowCard
      {...props}
      isError={isError}
      isLoading={isLoading}
      mediaGroup={mediaGroupData?.mediaGroup}
      refetch={handleRefetch}
    >
      {preview}
    </FlowCard>
  );
}

const discard: ReactEventHandler = (event) => {
  event.preventDefault();
  event.stopPropagation();
};

const PdfPreview = ({
  name,
  onDoubleClick,
  url,
}: {
  name: string;
  onDoubleClick?: () => void;
  url: string;
}) => {
  const [numPages, setNumPages] = useState(0);
  const handleLoadSuccess = useCallback(({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
  }, []);
  const [currentPage, setCurrentPage] = useState(0);

  const page = Math.max(0, Math.min(currentPage, numPages));

  const handleNext = useCallback(
    (event: ReactMouseEvent) => {
      event.stopPropagation();
      event.preventDefault();
      setCurrentPage((currentPage) => {
        const newPage = currentPage + 1;
        return Math.min(numPages, newPage);
      });
    },
    [numPages]
  );

  const handlePrevious = useCallback((event: ReactMouseEvent) => {
    event.stopPropagation();
    event.preventDefault();
    setCurrentPage((currentPage) => {
      const newPage = currentPage - 1;
      return Math.max(0, newPage);
    });
  }, []);

  // We'll use these to render the adjacent pages hidden, so they are hopefully
  // ready to display when the user pressed the next or previous button.
  const previousPage = page - 1 >= 0 ? page - 1 : null;
  const nextPage = page + 1 <= numPages ? page + 1 : null;

  return (
    <div className={styles.pdfPreview} onDoubleClick={onDoubleClick}>
      <div className={styles.scaleContainer}>
        <div className={clsx(styles.scrollArea, numPages === 0 && styles.noScroll)}>
          <div className={styles.pdfDocument}>
            <PdfDocument
              file={url}
              onLoadSuccess={handleLoadSuccess}
              loading={<LoadingPlaceholder />}
            >
              <div className={styles.pdfPage} data-index={page + 1}>
                {previousPage !== null && (
                  <PdfPage
                    key={previousPage}
                    containerClassName={styles.adjacentPage}
                    pageIndex={previousPage}
                    scale={1}
                  />
                )}
                <PdfPage key={page} pageIndex={page} scale={1} />
                {nextPage !== null && (
                  <PdfPage
                    key={nextPage}
                    containerClassName={styles.adjacentPage}
                    pageIndex={nextPage}
                    scale={1}
                  />
                )}
              </div>
              {numPages === 0 && <PdfPlaceholder name={name} url={url} />}
            </PdfDocument>
          </div>
        </div>
      </div>
      {numPages > 1 && (
        <div className={styles.pageControls}>
          <DetailsModalTooltip content="Previous Page" side="top">
            <Button
              disabled={previousPage === null}
              variant="outlined"
              onClick={handlePrevious}
              onDoubleClick={discard}
            >
              <Left />
            </Button>
          </DetailsModalTooltip>
          <DetailsModalTooltip content="Next Page" side="top">
            <Button
              disabled={nextPage === null}
              variant="outlined"
              onClick={handleNext}
              onDoubleClick={discard}
            >
              <Right />
            </Button>
          </DetailsModalTooltip>
        </div>
      )}
    </div>
  );
};
